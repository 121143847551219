<template>
  <div>
    <!-- 上半部 -->
    <div id="MerchandiseTopArea" class="row mb-1">
      <!-- 按鈕 -->
      <div class="col-md-6 col-12 mb-1 d-flex flex-wrap align-items-center">
        <!-- 新增商品 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="MerchandiseCreate"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            新增商品
          </button>
          <ul class="dropdown-menu" aria-labelledby="MerchandiseCreate">
            <li>
              <a
                @click="showModal('createMerchandise')"
                class="pointer dropdown-item"
                ><span class="fw-bolder text-primary">單筆</span> 建立商品</a
              >
            </li>
            <li>
              <a
                @click="
                  openNewWindow(
                    `#/seller/store/${storeId}/batchCreateMerchandise`
                  )
                "
                class="pointer dropdown-item"
                ><span class="fw-bolder text-danger">批次</span> 建立商品</a
              >
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('syncFbMerchIntoStore')"
              >
                同步FB商品進賣場並建立
              </button>
            </li>
          </ul>
        </div>
        <!-- 批次操作 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="MerchandiseBatch"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            批次操作
          </button>
          <ul class="dropdown-menu" aria-labelledby="MerchandiseBatch">
            <li>
              <button class="dropdown-item" @click="showModal('cloneToStore')">
                複製商品
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('updateMerchStatus', 'delete')"
              >
                刪除商品
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('updateMerchStatus', 'recover')"
              >
                恢復商品
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('setAllAtribute')"
              >
                商品細節
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('setMerchClassification')"
              >
                商品分類
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('batchCheckOut')">
                批次結單
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('setD4Supplier')">
                預設供應商
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('batchAddDiscounts')"
              >
                新增優惠價格
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('setUnHandleMessage')"
              >
                未處理留言設定
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('setAutoCreateOrderFromFb')"
              >
                FB留言同步時是否自動成立訂單
              </button>
            </li>
          </ul>
        </div>
        <!-- 執行 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="MerchandiseAction"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            執行
          </button>
          <ul class="dropdown-menu" aria-labelledby="MerchandiseAction">
            <li>
              <button class="dropdown-item" @click="showModal('exportExcel')">
                匯出
              </button>
            </li>
          </ul>
        </div>
        <!-- 發布 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="MerchandiseRelease"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            發布
          </button>
          <ul class="dropdown-menu" aria-labelledby="MerchandiseRelease">
            <li>
              <button
                class="dropdown-item"
                @click="showModal('postToFb', 'group')"
              >
                發布至FB社團
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('postToFb', 'page')"
              >
                發布至FB粉專
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('createFbPostLinks')"
              >
                產生FB貼文連結
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('toLinePush')">
                加入商品至Line推播列表
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('postToLineNotify')"
              >
                由Line Notify發布商品
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('postTheArrivalToNotify')"
              >
                由Line Notify發布到貨通知
              </button>
            </li>
            <!-- <li>
              <button class="dropdown-item" @click="showModal('toLivePush')">
                加入商品至Line<span class="text-danger fw-bolder">直播</span
                >列表
              </button>
            </li> -->
          </ul>
        </div>
        <!-- 同步 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="MerchandiseSync"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            同步
          </button>
          <ul class="dropdown-menu" aria-labelledby="MerchandiseSync">
            <li>
              <button
                class="dropdown-item"
                @click="showModal('syncFbMessages', 'group')"
              >
                同步FB社團
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('syncFbMessages', 'page')"
              >
                同步FB粉專
              </button>
            </li>
          </ul>
        </div>
        <!-- 常用搜尋 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="FrequentlySearched"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            常用搜尋
          </button>
          <ul class="dropdown-menu" aria-labelledby="FrequentlySearched">
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('可預購')"
              >
                可預購
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('僅供現貨')"
              >
                僅供現貨
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="frequentlySearched('限量')">
                限量
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('已刪除')"
              >
                已刪除
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('待入庫')"
              >
                待入庫
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="frequentlySearched('需訂')">
                需訂
              </button>
            </li>
          </ul>
        </div>
        <!-- 進階搜尋 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
        <!-- 批次處理 FB 留言 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('batchHandleFbMessages')"
        >
          批次處理 FB 未處理留言
        </button>
        <!-- 下載擴充 -->
        <!-- <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('downloadExtention')"
        >
          下載擴充
        </button> -->
        <!-- 前往新版商品管理 -->
        <router-link
          class="me-1 mb-1 tw-btn tw-btn-success tw-btn-hover"
          :to="`/seller/store/${storeId}/merchandise`"
          target="_blank"
        >
          前往新版商品管理
        </router-link>
      </div>
      <!-- 訊息 -->
      <div class="col-md-6 col-12 mb-1 fw-bolder">
        <p>
          搜尋條件:
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.merchandiseName"
          >
            商品名稱: {{ recordAdvancedSearchData.merchandiseName }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.vip"
          >
            VIP: {{ recordAdvancedSearchData.vip.name }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.supplier"
          >
            供應商: {{ recordAdvancedSearchData.supplier.name }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.startSaleQuantity"
          >
            成團數: {{ recordAdvancedSearchData.startSaleQuantity }}人
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.merchClassifications.length > 0"
          >
            多選分類
          </span>
          <span class="badge bg-primary me-1 mb-1">
            刪除狀態:
            {{ recordAdvancedSearchData.singleSelect.deletedStatus }}</span
          >
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.sellStateStatus !== '不選擇'
            "
          >
            販售狀態:
            {{ recordAdvancedSearchData.singleSelect.sellStateStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.orderGoodStatus !== '不選擇'
            "
          >
            叫貨狀態:
            {{ recordAdvancedSearchData.singleSelect.orderGoodStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.fbLinkStatus !== '不選擇'
            "
          >
            FB關聯:
            {{ recordAdvancedSearchData.singleSelect.fbLinkStatus }}
          </span>
        </p>
        <p>
          <span v-if="recordAdvancedSearchData.releaseTimeRange.switch">
            上架區間:
            <span
              v-if="
                recordAdvancedSearchData.releaseTimeRange.startTime &&
                recordAdvancedSearchData.releaseTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.releaseTimeRange.startTime }}~{{
                recordAdvancedSearchData.releaseTimeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.releaseTimeRange.startTime &&
                !recordAdvancedSearchData.releaseTimeRange.endTime
              "
              >{{
                recordAdvancedSearchData.releaseTimeRange.startTime
              }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.releaseTimeRange.startTime &&
                recordAdvancedSearchData.releaseTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.releaseTimeRange.endTime }}以前</span
            >&ensp;
          </span>
          <span v-if="recordAdvancedSearchData.timeRange.switch">
            搜尋區間:
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
                recordAdvancedSearchData.timeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                !recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
            >&ensp;
          </span>
          <span v-if="recordAdvancedSearchData.deadLineTimeRange.switch">
            收單區間:
            <span
              v-if="
                recordAdvancedSearchData.deadLineTimeRange.startTime &&
                recordAdvancedSearchData.deadLineTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.deadLineTimeRange.startTime }}~{{
                recordAdvancedSearchData.deadLineTimeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.deadLineTimeRange.startTime &&
                !recordAdvancedSearchData.deadLineTimeRange.endTime
              "
              >{{
                recordAdvancedSearchData.deadLineTimeRange.startTime
              }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.deadLineTimeRange.startTime &&
                recordAdvancedSearchData.deadLineTimeRange.endTime
              "
              >{{
                recordAdvancedSearchData.deadLineTimeRange.endTime
              }}以前</span
            >&ensp;
          </span>
          <span v-if="recordAdvancedSearchData.arrivalTimeRange.switch">
            到貨區間:
            <span
              v-if="
                recordAdvancedSearchData.arrivalTimeRange.startTime &&
                recordAdvancedSearchData.arrivalTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.arrivalTimeRange.startTime }}~{{
                recordAdvancedSearchData.arrivalTimeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.arrivalTimeRange.startTime &&
                !recordAdvancedSearchData.arrivalTimeRange.endTime
              "
              >{{
                recordAdvancedSearchData.arrivalTimeRange.startTime
              }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.arrivalTimeRange.startTime &&
                recordAdvancedSearchData.arrivalTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.arrivalTimeRange.endTime }}以前</span
            >
          </span>
        </p>
      </div>
    </div>
    <!-- 同步 FB 留言進度條 -->
    <BackgroundHandleSyncFbMessages></BackgroundHandleSyncFbMessages>
    <!-- 商品管理進度條 -->
    <ProgressBar :progressData="progressData"></ProgressBar>
    <!-- dataTable -->
    <div class="tw-container">
      <div class="management-datatable">
        <DataTable
          class="p-datatable-sm"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          :value="merchandiseList"
          dataKey="id"
          :loading="dataTabelLoading"
          :rowHover="true"
          :paginator="true"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rows="D4Row"
          :rowsPerPageOptions="[20, 50, 100]"
          currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
          v-model:filters="searchGlobal"
          filterDisplay="menue"
          stateStorage="local"
          stateKey="dt-state-merchandise-local"
          sortField="releaseTime"
          :sortOrder="-1"
          v-model:selection="selectItems"
        >
          <template #header>
            <div class="d-flex align-items-center">
              <FieldFilter
                whitchOneTable="merchandise"
                :D4FieldFilter="fieldFilter"
              ></FieldFilter>
              <div class="w-100">
                <p>表格搜尋</p>
                <input
                  class="form-control me-2"
                  v-model="searchGlobal['global'].value"
                  placeholder="表格搜尋: 商品名稱，留言數，訂單數，未配數，叫貨，庫存，供應商，建立時間"
                  style="max-width: 99%"
                />
              </div>
            </div>
          </template>
          <!-- 多選 -->
          <Column
            v-if="fieldFilter[0].selected"
            selectionMode="multiple"
            style="min-width: 50px; max-width: 50px"
          ></Column>
          <!-- 連結 -->
          <Column
            v-if="fieldFilter[1].selected"
            field="hasFbFeedLink"
            header="連結"
            style="min-width: 100px; max-width: 100px"
            sortable
          >
            <template #body="{ data }">
              <!-- FB社團 -->
              <div
                class="pointer"
                v-if="data.merchFbFeedObject.FbGroupLink.id"
                title="FB社團"
              >
                <a
                  @click="openNewWindow(data.merchFbFeedObject.FbGroupLink.url)"
                >
                  <img
                    class="icon"
                    src="@/assets/icon/users2-alt.png"
                    alt=""
                  />:<span
                    :class="{
                      'text-danger':
                        data.merchFbFeedObject.FbGroupLink.days > 90,
                    }"
                    >{{ data.merchFbFeedObject.FbGroupLink.days }}天</span
                  >
                  <span
                    class="badge bg-primary p-1"
                    v-if="
                      data.merchFbFeedObject.FbGroupLink
                        .autoCreateMerchOrderFromFb
                    "
                    >auto</span
                  >
                </a>
              </div>
              <!-- FB粉專 -->
              <div
                class="pointer"
                v-if="data.merchFbFeedObject.FbPageLink.id"
                title="FB粉專"
              >
                <a
                  @click="openNewWindow(data.merchFbFeedObject.FbPageLink.url)"
                >
                  <img
                    class="icon"
                    src="@/assets/icon/flag2.png"
                    alt=""
                  />:<span
                    :class="{
                      'text-danger':
                        data.merchFbFeedObject.FbPageLink.days > 90,
                    }"
                    >{{ data.merchFbFeedObject.FbPageLink.days }}天</span
                  >
                  <span
                    class="badge bg-primary p-1"
                    v-if="
                      data.merchFbFeedObject.FbPageLink
                        .autoCreateMerchOrderFromFb
                    "
                    >auto</span
                  >
                </a>
              </div>
            </template>
          </Column>
          <!-- 圖示 -->
          <Column
            v-if="fieldFilter[2].selected"
            field=""
            header="圖示"
            style="min-width: 55px; max-width: 55px"
          >
            <template #body="{ data }">
              <template
                v-for="img in data.merchandisePictureLinks"
                :key="img.id"
              >
                <img
                  class="table-img"
                  v-if="img.front"
                  :src="`https://i.imgur.com/${img.imageHash}s.jpg`"
                  alt=""
                />
              </template>
              <img
                class="table-img"
                v-if="data.merchandisePictureLinks.length === 0"
                src="@/assets/other-images/noImg.png"
                alt=""
              />
            </template>
          </Column>
          <!-- 商品 -->
          <Column
            v-if="fieldFilter[3].selected"
            field="name"
            header="商品"
            sortable
            style="min-width: 200px; max-width: 200px"
          >
            <template #body="{ data }">
              <img
                class="icon"
                v-if="data.note"
                src="@/assets/icon/exclamation.png"
                alt=""
                :title="`商品備註: ${data.note}`"
              />
              <router-link
                :title="`商品備註: ${data.note}`"
                :to="`/seller/store/${storeId}/merchandiseInfo/detail?merchandiseId=${data.id}`"
                class="fw-bolder text-break pointer"
                :class="{ 'text-danger': data.deleted }"
                target="_blank"
                >{{ data.name }}</router-link
              >
              <div
                class="pointer can-click"
                title="商品標籤"
                v-if="data.label"
                @click="showModal('setLabel', data)"
              >
                <img class="icon" src="@/assets/icon/clip2.png" alt="" />
                <span>{{ data.label }}</span>
              </div>
              <div
                class="pointer can-click"
                title="商品編號"
                v-if="data.number"
                @click="showModal('setNumber', data)"
              >
                <img class="icon" src="@/assets/icon/bookmark.png" alt="" />
                <span>{{ data.number }}</span>
              </div>
            </template>
          </Column>
          <!-- 分類 -->
          <Column
            v-if="fieldFilter[4].selected"
            field=""
            header="分類"
            style="min-width: 200px; max-width: 200px"
          >
            <template #body="{ data }">
              <div v-if="data.merchClassifications.length > 0">
                <span
                  v-for="category in data.merchClassifications"
                  :key="category.id"
                >
                  #{{ category.name }}
                </span>
              </div>
              <p v-else>-</p>
            </template>
          </Column>
          <!-- 操作 -->
          <Column
            v-if="fieldFilter[5].selected"
            field=""
            header="操作"
            style="min-width: 60px"
          >
            <template #body="{ data }">
              <div class="dropdown dropend pointer">
                <img
                  src="@/assets/icon/settings.png"
                  alt=""
                  id="Setting"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="width: 25px; height: 25px"
                />
                <ul class="dropdown-menu" aria-labelledby="Setting">
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/seller/store/${storeId}/editMerchandise?id=${data.id}`"
                      >編輯</router-link
                    >
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="showModal('addOrderBySeller', data)"
                    >
                      代客加單
                    </button>
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/seller/store/${storeId}/warehousing?id=${data.id}`"
                      >現貨入庫與限量</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/seller/store/${storeId}/createPurchaseOrder?id=${data.id}`"
                      >建立叫貨單</router-link
                    >
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="showModal('uploadPictures', data)"
                    >
                      編輯商品圖片
                    </button>
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/seller/store/${storeId}/merchOrder?merchId=${data.id}`"
                      >訂單管理</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/customer/store/${storeId}/orderPage?merchandiseId=${data.id}&sellState=${data.sellState}`"
                      >下單頁面</router-link
                    >
                  </li>
                </ul>
              </div>
            </template>
          </Column>
          <!-- 狀態 -->
          <Column
            v-if="fieldFilter[6].selected"
            field="ready"
            header="狀態"
            sortable
            style="min-width: 180px"
          >
            <template #body="{ data }">
              <div class="row">
                <!-- 販售狀態 -->
                <div
                  class="col-6 p-1 pointer can-click"
                  @click="updateSellState(data)"
                >
                  <p
                    class="badge bg-success m-0"
                    v-if="data.sellState === 'CANPREORDER'"
                  >
                    可預購
                  </p>
                  <p
                    class="badge bg-primary m-0"
                    v-else-if="data.sellState === 'STOCKONLY'"
                  >
                    僅供現貨
                  </p>
                  <p class="badge bg-danger m-0" v-else>限量</p>
                </div>
                <!-- 上下架 -->
                <div
                  class="col-6 p-1 pointer can-click"
                  @click="updateReady(data)"
                >
                  <p class="badge bg-success" v-if="data.ready">上架</p>
                  <p class="badge bg-danger" v-else>下架</p>
                </div>
              </div>
              <div class="row">
                <!-- 結單 -->
                <div
                  class="col-6 p-1 pointer can-click"
                  title="是否開放顧客結單"
                  @click="updateCanCheckOut(data)"
                  v-if="storeInfo.canCheckOut"
                >
                  <p v-if="data.checkOut">
                    <img
                      class="icon"
                      src="@/assets/icon/customer.png"
                      alt=""
                    /><span>結單</span>
                  </p>
                  <p v-else>
                    <img
                      class="icon"
                      src="@/assets/icon/delete-user2.png"
                      alt=""
                    /><span>結單</span>
                  </p>
                </div>
                <!-- VIP -->
                <div
                  class="col-6 p-1 pointer can-click"
                  v-if="data.storeVipId"
                  title="商品VIP"
                  @click="showModal('setVip', data)"
                >
                  <img class="icon" src="@/assets/icon/crown1.png" alt="" />
                  <span>{{ data.storeVipName }}</span>
                </div>
                <div
                  class="col-6 p-1 pointer can-click"
                  v-else
                  title="商品VIP"
                  @click="showModal('setVip', data)"
                >
                  <img class="icon" src="@/assets/icon/crown1.png" alt="" />
                  <span>無設定</span>
                </div>
              </div>
            </template>
          </Column>
          <!-- 留言 -->
          <Column
            v-if="fieldFilter[7].selected"
            field="unhandledFbCommentQuantity"
            header="留言"
            sortable
          >
            <template #body="{ data }">
              <p
                :class="{ 'text-danger': data.unhandledFbCommentQuantity > 0 }"
              >
                {{ data.unhandledFbCommentQuantity }}
              </p>
            </template>
          </Column>
          <!-- 訂單 -->
          <Column
            v-if="fieldFilter[8].selected"
            field="merchOrderQuantity"
            header="訂單"
            sortable
          >
            <template #body="{ data }">
              <p>{{ data.merchOrderQuantity }}</p>
              <div
                class="pointer can-click"
                v-if="data.startSaleQuantity"
                title="成團數"
                @click="showModal('setStartSaleQuantity', data)"
              >
                <img class="icon" src="@/assets/icon/users.png" alt="" />
                <span>{{ data.startSaleQuantity }}</span>
              </div>
            </template>
          </Column>
          <!-- 未配 -->
          <Column
            v-if="fieldFilter[9].selected"
            field="unAllocatedQuantity"
            header="未配"
            sortable
          ></Column>
          <!-- 叫貨 -->
          <Column
            v-if="fieldFilter[10].selected"
            field="purchaseOrderState"
            header="叫貨"
            sortable
            style="min-width: 100px; max-width: 100px"
          >
            <template #body="{ data }">
              <p class="fw-bolder" v-if="data.purchaseOrderState === '-'">-</p>
              <router-link
                class="fw-bolder text-danger can-click"
                :to="`/seller/store/${storeId}/createPurchaseOrder?id=${data.id}`"
                v-else-if="data.purchaseOrderState === '需訂'"
                >需訂</router-link
              >
              <p
                class="fw-bolder text-warning"
                v-else-if="data.purchaseOrderState === '待入庫'"
              >
                待入庫
              </p>
              <p
                class="fw-bolder text-success"
                v-else-if="data.purchaseOrderState === '已處理'"
              >
                已處理
              </p>
              <p
                class="fw-bolder"
                v-else-if="data.purchaseOrderState === '已處理(!)'"
                title="叫貨單尚有未入庫"
              >
                <span class="text-success">已處理</span>
                <span class="text-warning">(!)</span>
              </p>
            </template>
          </Column>
          <!-- 庫存 -->
          <Column
            v-if="fieldFilter[11].selected"
            field="stockQuantity"
            header="庫存"
            sortable
            style="min-width: 100px; max-width: 100px"
          >
            <template #body="{ data }">
              <p>{{ data.stockQuantity }}</p>
              <div
                class="pointer can-click"
                v-if="data.storageLocation"
                title="倉儲位置"
                @click="showModal('setStorageLocation', data)"
              >
                <img
                  class="icon"
                  src="@/assets/icon/home-location-alt.png"
                  alt=""
                />
                <span class="text-break">{{ data.storageLocation }}</span>
              </div>
            </template>
          </Column>
          <!-- 供應商 -->
          <Column
            v-if="fieldFilter[12].selected"
            field="supplierD4Link"
            sortable
            header="供應商"
            style="min-width: 100px"
          >
          </Column>
          <!-- 時間 -->
          <Column
            v-if="fieldFilter[13].selected"
            field="releaseTime"
            header="時間"
            sortable
            style="min-width: 230px"
            :sortOrder="-1"
          >
            <template #body="{ data }">
              <p>上架: {{ data.releaseTime }}</p>
              <p>建立: {{ data.createTime }}</p>
              <p
                class="pointer can-click"
                v-if="data.deadLineTime"
                @click="showModal('setDeadLineTime', data)"
              >
                收單: {{ data.deadLineTime }}
              </p>
              <p
                class="pointer can-click"
                v-if="data.arrivalTime"
                @click="showModal('setArrivalTime', data)"
              >
                到貨: {{ data.arrivalTime }}
              </p>
            </template>
          </Column>
          <template #empty>
            <p class="text-center fw-bolder text-primary">
              目前尚未有任何資料 0..0
            </p>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 上架區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_ReleaseTimeRange"
                v-model="advancedSearchData.releaseTimeRange.switch"
              />
              <label class="form-check-label" for="AS_ReleaseTimeRange"
                >上架區間</label
              >
            </div>
            <!-- 時間 -->
            <div
              class="row mb-3"
              v-if="advancedSearchData.releaseTimeRange.switch"
            >
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_ReleaseTimeRangeStartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.releaseTimeRange.startTime"
                  />
                  <label for="AS_ReleaseTimeRangeStartTime" class="mb-2"
                    >起始時間</label
                  >
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_ReleaseTimeRangeEndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.releaseTimeRange.endTime"
                  />
                  <label for="AS_ReleaseTimeRangeEndTime" class="mb-2"
                    >結束時間</label
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 收單時間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_DeadLineTimeSwitch"
                v-model="advancedSearchData.deadLineTimeRange.switch"
              />
              <label class="form-check-label" for="AS_DeadLineTimeSwitch"
                >收單區間</label
              >
            </div>
            <!-- 時間 -->
            <div
              class="row mb-3"
              v-if="advancedSearchData.deadLineTimeRange.switch"
            >
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_DeadLineTimeStartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.deadLineTimeRange.startTime"
                  />
                  <label for="AS_DeadLineTimeStartTime" class="mb-2"
                    >起始時間</label
                  >
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_DeadLineTimeEndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.deadLineTimeRange.endTime"
                  />
                  <label for="AS_DeadLineTimeEndTime" class="mb-2"
                    >結束時間</label
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 到貨區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_ArrivalTimeTimeSwitch"
                v-model="advancedSearchData.arrivalTimeRange.switch"
              />
              <label class="form-check-label" for="AS_ArrivalTimeTimeSwitch"
                >到貨區間</label
              >
            </div>
            <!-- 時間 -->
            <div
              class="row mb-3"
              v-if="advancedSearchData.arrivalTimeRange.switch"
            >
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_ArrivalTimeStartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.arrivalTimeRange.startTime"
                  />
                  <label for="AS_ArrivalTimeStartTime" class="mb-2"
                    >起始時間</label
                  >
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_ArrivalTimeEndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.arrivalTimeRange.endTime"
                  />
                  <label for="AS_ArrivalTimeEndTime" class="mb-2"
                    >結束時間</label
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 商品名稱 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="AS_Name"
              placeholder="商品名稱(有包含即可)(100字)"
              v-model="advancedSearchData.merchandiseName"
              maxlength="100"
            />
            <label for="AS_Name">商品名稱(有包含即可)(100字)</label>
          </div>
          <!-- 商品標籤 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="AS_Lable"
              placeholder="商品標籤(有包含即可)(15字)"
              v-model="advancedSearchData.merchandiseLabel"
              maxlength="15"
            />
            <label for="AS_Lable">商品標籤(有包含即可)(100字)</label>
          </div>
          <!-- VIP -->
          <div class="form-floating mb-3" v-if="!storeVips.loading">
            <select
              class="form-select"
              id="AS_Vip"
              v-model="advancedSearchData.vip"
            >
              <option :value="null">不選擇</option>
              <option
                :value="data"
                v-for="(data, index) in storeVips.data"
                :key="data.id"
              >
                LV{{ index + 1 }}: {{ data.name }}
              </option>
            </select>
            <label for="AS_Vip">VIP</label>
          </div>
          <AreaLoading v-else></AreaLoading>
          <!-- 供應商 -->
          <div class="form-floating mb-3" v-if="!suppliers.loading">
            <select
              class="form-select"
              id="AS_Supplier"
              v-model="advancedSearchData.supplier"
            >
              <option :value="null">不選擇</option>
              <option
                :value="data"
                v-for="data in suppliers.data"
                :key="data.id"
              >
                {{ data.name }}
              </option>
            </select>
            <label for="AS_Supplier">供應商</label>
          </div>
          <AreaLoading v-else></AreaLoading>
          <!-- 成團數 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              min="0"
              class="form-control"
              id="AS_StartSaleQuantity"
              placeholder="成團數"
              v-model="advancedSearchData.startSaleQuantity"
              pattern="[0-9]*"
              oninput="this.value=this.value.replace(/\D/g,'')"
            />
            <label for="AS_StartSaleQuantity">成團數</label>
          </div>
          <!-- 分類 -->
          <div
            class="mb-3 p-3 border"
            style="height: 200px; overflow-y: scroll"
          >
            <p class="tw-border-start fw-bolder">選擇分類(有包含即可)</p>
            <hr />
            <div v-if="!merchClassifications.loading">
              <div
                class="select-checkbox my-2"
                v-for="data in merchClassifications.data"
                :key="data.id"
              >
                <label>
                  <input
                    type="checkbox"
                    name="rows"
                    :value="data.id"
                    v-model="advancedSearchData.merchClassifications"
                  />
                  <span class="checkbox-style">{{ data.name }}</span>
                </label>
              </div>
            </div>
            <AreaLoading v-else></AreaLoading>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 新增商品的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="createMerchandiseModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">新增商品</h5>
          <!-- 下面這個 btn 是為了解決 styles 按 enter 如果有跳 alert 它就會 focus 在 X 上導致你連續按兩次 enter modal 就會關閉 -->
          <button style="opacity: 0">1</button>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 商品名稱 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateName"
              placeholder="*商品名稱(100字)"
              maxlength="100"
              v-model="createMerchandiseData.name"
            />
            <label for="CreateName" class="text-danger">*商品名稱(100字)</label>
          </div>
          <hr />
          <!-- 上下架 -->
          <div class="form-check form-switch p-0">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="CreateReady"
              v-model="createMerchandiseData.ready"
            />
            <label class="form-check-label" for="CreateReady">上架</label>
          </div>
          <!-- 顧客自行結單 -->
          <div class="form-check form-switch p-0" v-if="storeInfo.canCheckOut">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="CreateCanCheckOut"
              v-model="createMerchandiseData.checkOut"
            />
            <label class="form-check-label" for="CreateCanCheckOut"
              >顧客可自行結單</label
            >
          </div>
          <hr />
          <!-- 商品分類 -->
          <div>
            <p class="tw-border-start fw-bolder mb-2">商品分類</p>
            <div
              style="height: 100px; overflow-y: scroll"
              v-if="!merchClassifications.loading"
            >
              <template v-if="merchClassifications.data.length > 0">
                <div class="row">
                  <template
                    v-for="data in merchClassifications.data"
                    :key="data.id"
                  >
                    <div class="form-check p-0 col-6" v-if="data.id !== 0">
                      <input
                        class="form-check-input m-0 me-2"
                        type="checkbox"
                        :value="data.id"
                        :id="`CreateCategory${data.id}`"
                        v-model="createMerchandiseData.merchClassificationIds"
                      />
                      <label
                        class="form-check-label"
                        :for="`CreateCategory${data.id}`"
                      >
                        {{ data.name }}
                      </label>
                    </div>
                  </template>
                </div>
              </template>
              <p class="text-danger fw-bolder" v-else>
                您目前尚未有任何商品分類!
              </p>
            </div>
            <AreaLoading v-else></AreaLoading>
          </div>
          <hr />
          <!-- 商品描述 -->
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="商品描述"
              id="CreateDescription"
              style="height: 300px"
              maxlength="2000"
              v-model="createMerchandiseData.description"
            ></textarea>
            <label for="CreateDescription">商品描述(2000字)</label>
          </div>
          <hr />
          <!-- 商品備註 -->
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="商品備註"
              id="CreateNote"
              style="height: 100px"
              maxlength="300"
              v-model="createMerchandiseData.note"
            ></textarea>
            <label for="CreateNote">商品備註(僅現賣家看到)(300字)</label>
          </div>
          <hr />
          <!-- 商品細節 -->
          <p class="tw-border-start fw-bolder mb-2">商品細節</p>
          <!-- VIP/販售狀態 -->
          <div class="row mb-3">
            <!-- VIP -->
            <div class="col-sm-6 p-0 pe-sm-1 mb-2 mb-sm-0">
              <template v-if="!storeVips.loading">
                <select
                  class="form-control"
                  name="merchandiseVip"
                  v-model="createMerchandiseData.storeVipId"
                >
                  <option value="">不設定 VIP</option>
                  <template
                    v-for="(vip, index) in storeVips.data"
                    :key="vip.id"
                  >
                    <option :value="vip.id" v-if="vip.id <= storeVip">
                      LV{{ index + 1 }}: {{ vip.name }}
                    </option>
                  </template>
                </select>
              </template>
              <AreaLoading v-else></AreaLoading>
            </div>
            <!-- 販售狀態 -->
            <div class="col-sm-6 p-0 ps-sm-1">
              <select
                class="form-control"
                name="merchandiseSellState"
                v-model="createMerchandiseData.sellState"
              >
                <option value="CANPREORDER">可預購</option>
                <option value="STOCKONLY">僅供現貨</option>
                <option value="LIMITED">限量</option>
              </select>
            </div>
          </div>
          <!-- 自定義編號 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateNumber"
              placeholder="自定義編號(20字)"
              maxlength="20"
              v-model="createMerchandiseData.number"
            />
            <label for="CreateNumber">自定義編號(20字)</label>
          </div>
          <!-- 自定義標籤 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateLabel"
              placeholder="自定義標籤(15字)"
              maxlength="15"
              v-model="createMerchandiseData.label"
            />
            <label for="CreateLabel">自定義標籤(15字)</label>
          </div>
          <!-- 倉儲位置 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateStorage"
              placeholder="倉儲位置(10字)"
              maxlength="10"
              v-model="createMerchandiseData.storageLocation"
            />
            <label for="CreateStorage">倉儲位置(10字)</label>
          </div>
          <!-- 成團數 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="CreateStartSaleQuantity"
              placeholder="成團數"
              min="0"
              v-model="createMerchandiseData.startSaleQuantity"
              pattern="[0-9]*"
              oninput="this.value=this.value.replace(/\D/g,'')"
            />
            <label for="CreateStartSaleQuantity">成團數</label>
          </div>
          <!-- 收單/到貨時間 -->
          <div class="row">
            <!-- 收單時間 -->
            <div class="col-md-6 mb-3 mb-md-0 p-0">
              <div class="form-check form-switch p-0 mb-2">
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  role="switch"
                  id="CreateSwitchDeadLineTime"
                  v-model="createMerchandiseData.checkDeadLineTime"
                />
                <label class="form-check-label" for="CreateSwitchDeadLineTime"
                  >開啟收單時間</label
                >
              </div>
              <div
                class="form-floating"
                v-if="createMerchandiseData.checkDeadLineTime"
              >
                <input
                  class="form-control"
                  type="datetime-local"
                  id="CreateDeadLineTime"
                  placeholder="收單時間"
                  v-model="createMerchandiseData.deadLineTime"
                />
                <label for="CreateDeadLineTime" class="mb-2">收單時間</label>
              </div>
            </div>
            <!-- 到貨時間 -->
            <div class="col-md-6 p-0">
              <div class="form-check form-switch p-0 mb-2">
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  role="switch"
                  id="CreateSwitchArrivalTime"
                  v-model="createMerchandiseData.checkArrivalTime"
                />
                <label class="form-check-label" for="CreateSwitchArrivalTime"
                  >開啟到貨時間</label
                >
              </div>
              <div
                class="form-floating"
                v-if="createMerchandiseData.checkArrivalTime"
              >
                <input
                  class="form-control"
                  type="datetime-local"
                  id="CreateArrivalTime"
                  placeholder="到貨時間"
                  v-model="createMerchandiseData.arrivalTime"
                />
                <label for="CreateArrivalTime" class="mb-2">到貨時間</label>
              </div>
            </div>
          </div>
          <hr />
          <!-- 供應商 -->
          <div>
            <p class="tw-border-start fw-bolder mb-2">供應商</p>
            <div style="">
              <div class="row mb-2">
                <!-- 不選擇供應商 -->
                <div class="form-check p-0 col">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectSupplier"
                    id="NoSupplier"
                    value="不選擇供應商"
                    v-model="createMerchandiseData.supplierStatus"
                  />
                  <label class="form-check-label" for="NoSupplier">
                    不選擇供應商
                  </label>
                </div>
                <!-- 選擇現有供應商 -->
                <div class="form-check p-0 col">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectSupplier"
                    id="SelectDefaltSupplier"
                    value="選擇現有供應商"
                    v-model="createMerchandiseData.supplierStatus"
                  />
                  <label class="form-check-label" for="SelectDefaltSupplier">
                    選擇現有供應商
                  </label>
                </div>
                <!-- 自行輸入供應商 -->
                <div
                  class="form-check p-0 col"
                  v-if="
                    (currentParticipantPermission &&
                      currentParticipantPermission.accessSupplier) ||
                    !currentParticipantPermission
                  "
                >
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectSupplier"
                    id="SetOwnSupplier"
                    value="自行輸入供應商"
                    v-model="createMerchandiseData.supplierStatus"
                  />
                  <label class="form-check-label" for="SetOwnSupplier">
                    自行輸入供應商
                  </label>
                </div>
              </div>
              <!-- 選擇現有供應商畫面 -->
              <div
                v-if="createMerchandiseData.supplierStatus === '選擇現有供應商'"
              >
                <div class="mb-2" v-if="!suppliers.loading">
                  <template v-if="suppliers.data.length > 0">
                    <select
                      class="form-control"
                      v-model="createMerchandiseData.D4Supllier.name"
                    >
                      <option value="" disabled>請選擇供應商</option>
                      <option
                        :value="data.name"
                        v-for="data in suppliers.data"
                        :key="data.id"
                      >
                        {{ data.name }}
                      </option>
                    </select>
                  </template>
                  <p class="text-danger fw-bolder" v-else>
                    您目前尚未有任何供應商!
                  </p>
                </div>
                <AreaLoading v-else></AreaLoading>
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    id="CreateD4SupplierCost"
                    placeholder="成本(會自行帶入款式成本)"
                    min="0"
                    v-model="createMerchandiseData.D4Supllier.cost"
                    @input="
                      createMerchandiseData.D4Supllier.cost =
                        $methods.numberToFixed(
                          createMerchandiseData.D4Supllier.cost
                        )
                    "
                  />
                  <label for="CreateD4SupplierCost"
                    >成本(會自行帶入款式成本)</label
                  >
                </div>
              </div>
              <!-- 自行輸入供應商畫面 -->
              <div
                v-else-if="
                  createMerchandiseData.supplierStatus === '自行輸入供應商'
                "
              >
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="CreateOwnSupplier"
                    placeholder="供應商(20字)"
                    maxlength="20"
                    v-model="createMerchandiseData.setOwnSupplier.name"
                  />
                  <label for="CreateOwnSupplier">供應商(20字)</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    id="CreateOwnSupplierCost"
                    placeholder="成本(會自行帶入款式成本)"
                    min="0"
                    v-model="createMerchandiseData.setOwnSupplier.cost"
                    @input="
                      createMerchandiseData.setOwnSupplier.cost =
                        $methods.numberToFixed(
                          createMerchandiseData.setOwnSupplier.cost
                        )
                    "
                  />
                  <label for="CreateOwnSupplierCost"
                    >成本(會自行帶入款式成本)</label
                  >
                </div>
              </div>
            </div>
          </div>
          <hr />
          <!-- 款式 -->
          <div>
            <p class="tw-border-start fw-bolder mb-2">款式</p>
            <!-- 主要款式 -->
            <div class="mb-3 tw-bg-main mb-3 pt-2">
              <p class="fw-bolder ms-2 mb-2">主要款式</p>
              <div class="row">
                <div class="col-12 mb-2 d-flex">
                  <input
                    id="MainStyle"
                    class="form-control w-50"
                    type="text"
                    placeholder="用，分隔可做批次新增的動作"
                    v-model="createMerchandiseData.setmainStyls"
                    @keyup.space="addComma('MainStyle', 'setmainStyls')"
                    @keyup.enter="createStyleInput('mainStyls')"
                  />
                  <input
                    onwheel="this.blur()"
                    id="MainStylePrice"
                    class="form-control w-25"
                    type="number"
                    placeholder="單價"
                    v-model="createMerchandiseData.setMainStylsPrice"
                    @input="
                      createMerchandiseData.setMainStylsPrice =
                        $methods.numberToFixed(
                          createMerchandiseData.setMainStylsPrice
                        )
                    "
                    @keyup.enter="createStyleInput('mainStyls')"
                  />
                  <input
                    onwheel="this.blur()"
                    id="MainStyleCost"
                    class="form-control w-25"
                    type="number"
                    placeholder="成本"
                    v-model="createMerchandiseData.setMainStylesCost"
                    @input="
                      createMerchandiseData.setMainStylesCost =
                        $methods.numberToFixed(
                          createMerchandiseData.setMainStylesCost
                        )
                    "
                    @keyup.enter="createStyleInput('mainStyls')"
                  />
                </div>
                <div class="d-flex col-12">
                  <button
                    class="tw-btn tw-btn-secondary fw-bolder me-2"
                    @click="addComma('MainStyle', 'setmainStyls')"
                  >
                    <img
                      title="逗號"
                      class="icon"
                      src="@/assets/icon/comma.png"
                      alt="，"
                    />
                  </button>
                  <button
                    class="tw-btn tw-btn-success me-2"
                    @click="createStyleInput('mainStyls')"
                    v-if="showAddStyleBtn"
                  >
                    新增
                  </button>
                  <button
                    class="tw-btn tw-btn-success"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#BatchAddStylesCanvasTop"
                    aria-controls="offcanvasTop"
                    @click="showBatchStyle('mainStyles')"
                  >
                    批次新增
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th style="width: 50px">移動</th>
                      <th style="min-width: 100px">主要款式</th>
                      <th style="min-width: 100px">單價</th>
                      <th style="min-width: 100px">成本</th>
                      <th style="min-width: 100px">操作</th>
                    </tr>
                  </thead>
                  <Draggable
                    v-model="createMerchandiseData.mainStyles"
                    class="draggable"
                    @start="drag = true"
                    @end="drag = false"
                    item-key="id"
                    tag="tbody"
                    handle=".handle"
                  >
                    <template #item="{ element }">
                      <tr>
                        <td
                          class="text-center pointer handle"
                          style="line-height: 35px"
                        >
                          <i class="bi bi-arrows-move"></i>
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="主要款式(20字)"
                            maxlength="20"
                            v-model="element.name"
                          />
                        </td>
                        <td>
                          <input
                            onwheel="this.blur()"
                            type="number"
                            class="form-control"
                            placeholder="單價"
                            min="0"
                            v-model="element.price"
                            @input="
                              element.price = $methods.numberToFixed(
                                element.price
                              )
                            "
                          />
                        </td>
                        <td>
                          <input
                            onwheel="this.blur()"
                            type="number"
                            class="form-control"
                            placeholder="成本"
                            min="0"
                            v-model="element.cost"
                            @input="
                              element.cost = $methods.numberToFixed(
                                element.cost
                              )
                            "
                          />
                        </td>
                        <td>
                          <button
                            class="tw-btn tw-btn-danger"
                            @click="delStyleInput('main', element.id)"
                          >
                            刪除
                          </button>
                        </td>
                      </tr>
                    </template>
                  </Draggable>
                </table>
              </div>
            </div>
            <!-- 細項一 -->
            <div class="mb-3 tw-bg-main mb-3 pt-2">
              <p class="fw-bolder ms-2 mb-2">細項一</p>
              <div class="row">
                <div class="col-12 mb-2">
                  <input
                    id="OneStyle"
                    class="form-control"
                    type="text"
                    placeholder="用，分隔可做批次新增的動作"
                    v-model="createMerchandiseData.setoneStyles"
                    @keyup.space="addComma('OneStyle', 'setoneStyles')"
                    @keyup.enter="createStyleInput('oneStyles')"
                  />
                </div>
                <div class="d-flex col-12">
                  <button
                    class="tw-btn tw-btn-secondary fw-bolder me-2"
                    @click="addComma('OneStyle', 'setoneStyles')"
                  >
                    <img
                      title="逗號"
                      class="icon"
                      src="@/assets/icon/comma.png"
                      alt="，"
                    />
                  </button>
                  <button
                    class="tw-btn tw-btn-success me-2"
                    @click="createStyleInput('oneStyles')"
                    v-if="showAddStyleBtn"
                  >
                    新增
                  </button>
                  <button
                    class="tw-btn tw-btn-success"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#BatchAddStylesCanvasTop"
                    aria-controls="offcanvasTop"
                    @click="showBatchStyle('oneStyles')"
                  >
                    批次新增
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th style="width: 50px">移動</th>
                      <th style="min-width: 100px">細項一</th>
                      <th style="min-width: 100px">操作</th>
                    </tr>
                  </thead>
                  <Draggable
                    v-model="createMerchandiseData.oneStyles"
                    class="draggable"
                    @start="drag = true"
                    @end="drag = false"
                    item-key="id"
                    tag="tbody"
                    handle=".handle"
                  >
                    <template #item="{ element }">
                      <tr>
                        <td
                          class="text-center pointer handle"
                          style="line-height: 35px"
                        >
                          <i class="bi bi-arrows-move"></i>
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="細項一(20字)"
                            maxlength="20"
                            v-model="element.name"
                          />
                        </td>
                        <td>
                          <button
                            class="tw-btn tw-btn-danger"
                            @click="delStyleInput('one', element.id)"
                          >
                            刪除
                          </button>
                        </td>
                      </tr>
                    </template>
                  </Draggable>
                </table>
              </div>
            </div>
            <!-- 細項二 -->
            <div
              class="mb-3 tw-bg-main mb-3 pt-2"
              v-if="createMerchandiseData.oneStyles.length > 0"
            >
              <p class="fw-bolder ms-2 mb-2">細項二</p>
              <div class="row">
                <div class="col-12 mb-2">
                  <input
                    id="TwoStyle"
                    class="form-control"
                    type="text"
                    placeholder="用，分隔可做批次新增的動作"
                    v-model="createMerchandiseData.settwoStyles"
                    @keyup.space="addComma('TwoStyle', 'settwoStyles')"
                    @keyup.enter="createStyleInput('twoStyles')"
                  />
                </div>
                <div class="d-flex col-12">
                  <button
                    class="tw-btn tw-btn-secondary fw-bolder me-2"
                    @click="addComma('TwoStyle', 'settwoStyles')"
                  >
                    <img
                      title="逗號"
                      class="icon"
                      src="@/assets/icon/comma.png"
                      alt="，"
                    />
                  </button>
                  <button
                    class="tw-btn tw-btn-success me-2"
                    @click="createStyleInput('twoStyles')"
                    v-if="showAddStyleBtn"
                  >
                    新增
                  </button>
                  <button
                    class="tw-btn tw-btn-success"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#BatchAddStylesCanvasTop"
                    aria-controls="offcanvasTop"
                    @click="showBatchStyle('twoStyles')"
                  >
                    批次新增
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th style="width: 50px">移動</th>
                      <th style="min-width: 100px">細項二</th>
                      <th style="min-width: 100px">操作</th>
                    </tr>
                  </thead>
                  <Draggable
                    v-model="createMerchandiseData.twoStyles"
                    class="draggable"
                    @start="drag = true"
                    @end="drag = false"
                    item-key="id"
                    tag="tbody"
                    handle=".handle"
                  >
                    <template #item="{ element }">
                      <tr>
                        <td
                          class="text-center pointer handle"
                          style="line-height: 35px"
                        >
                          <i class="bi bi-arrows-move"></i>
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="細項二(20字)"
                            maxlength="20"
                            v-model="element.name"
                          />
                        </td>
                        <td>
                          <button
                            class="tw-btn tw-btn-danger"
                            @click="delStyleInput('two', element.id)"
                          >
                            刪除
                          </button>
                        </td>
                      </tr>
                    </template>
                  </Draggable>
                </table>
              </div>
            </div>
            <!-- 細項三 -->
            <div
              class="mb-3 tw-bg-main mb-3 pt-2"
              v-if="createMerchandiseData.twoStyles.length > 0"
            >
              <p class="fw-bolder ms-2 mb-2">細項三</p>
              <div class="row">
                <div class="col-12 mb-2">
                  <input
                    id="ThreeStyle"
                    class="form-control"
                    type="text"
                    placeholder="用，分隔可做批次新增的動作"
                    v-model="createMerchandiseData.setthreeStyles"
                    @keyup.space="addComma('ThreeStyle', 'setthreeStyles')"
                    @keyup.enter="createStyleInput('threeStyles')"
                  />
                </div>
                <div class="d-flex col-12">
                  <button
                    class="tw-btn tw-btn-secondary fw-bolder me-2"
                    @click="addComma('ThreeStyle', 'setthreeStyles')"
                  >
                    <img
                      title="逗號"
                      class="icon"
                      src="@/assets/icon/comma.png"
                      alt="，"
                    />
                  </button>
                  <button
                    class="tw-btn tw-btn-success me-2"
                    @click="createStyleInput('threeStyles')"
                    v-if="showAddStyleBtn"
                  >
                    新增
                  </button>
                  <button
                    class="tw-btn tw-btn-success"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#BatchAddStylesCanvasTop"
                    aria-controls="offcanvasTop"
                    @click="showBatchStyle('threeStyles')"
                  >
                    批次新增
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th style="width: 50px">移動</th>
                      <th style="min-width: 100px">細項三</th>
                      <th style="min-width: 100px">操作</th>
                    </tr>
                  </thead>
                  <Draggable
                    v-model="createMerchandiseData.threeStyles"
                    class="draggable"
                    @start="drag = true"
                    @end="drag = false"
                    item-key="id"
                    tag="tbody"
                    handle=".handle"
                  >
                    <template #item="{ element }">
                      <tr>
                        <td
                          class="text-center pointer handle"
                          style="line-height: 35px"
                        >
                          <i class="bi bi-arrows-move"></i>
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="細項三(20字)"
                            maxlength="20"
                            v-model="element.name"
                          />
                        </td>
                        <td>
                          <button
                            class="tw-btn tw-btn-danger"
                            @click="delStyleInput('three', element.id)"
                          >
                            刪除
                          </button>
                        </td>
                      </tr>
                    </template>
                  </Draggable>
                </table>
              </div>
            </div>
            <!-- 細項四 -->
            <div
              class="mb-3 tw-bg-main mb-3 pt-2"
              v-if="createMerchandiseData.threeStyles.length > 0"
            >
              <p class="fw-bolder ms-2 mb-2">細項四</p>
              <div class="row">
                <div class="col-12 mb-2">
                  <input
                    id="FourStyle"
                    class="form-control"
                    type="text"
                    placeholder="用，分隔可做批次新增的動作"
                    v-model="createMerchandiseData.setfourStyles"
                    @keyup.space="addComma('FourStyle', 'setfourStyles')"
                    @keyup.enter="createStyleInput('fourStyles')"
                  />
                </div>
                <div class="d-flex col-12">
                  <button
                    class="tw-btn tw-btn-secondary fw-bolder me-2"
                    @click="addComma('FourStyle', 'setfourStyles')"
                  >
                    <img
                      title="逗號"
                      class="icon"
                      src="@/assets/icon/comma.png"
                      alt="，"
                    />
                  </button>
                  <button
                    class="tw-btn tw-btn-success me-2"
                    @click="createStyleInput('fourStyles')"
                    v-if="showAddStyleBtn"
                  >
                    新增
                  </button>
                  <button
                    class="tw-btn tw-btn-success"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#BatchAddStylesCanvasTop"
                    aria-controls="offcanvasTop"
                    @click="showBatchStyle('fourStyles')"
                  >
                    批次新增
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th style="width: 50px">移動</th>
                      <th style="min-width: 100px">細項四</th>
                      <th style="min-width: 100px">操作</th>
                    </tr>
                  </thead>
                  <Draggable
                    v-model="createMerchandiseData.fourStyles"
                    class="draggable"
                    @start="drag = true"
                    @end="drag = false"
                    item-key="id"
                    tag="tbody"
                    handle=".handle"
                  >
                    <template #item="{ element }">
                      <tr>
                        <td
                          class="text-center pointer handle"
                          style="line-height: 35px"
                        >
                          <i class="bi bi-arrows-move"></i>
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="細項四(20字)"
                            maxlength="20"
                            v-model="element.name"
                          />
                        </td>
                        <td>
                          <button
                            class="tw-btn tw-btn-danger"
                            @click="delStyleInput('four', element.id)"
                          >
                            刪除
                          </button>
                        </td>
                      </tr>
                    </template>
                  </Draggable>
                </table>
              </div>
            </div>
            <!-- 批次新增的 canvas -->
            <div
              class="offcanvas offcanvas-top"
              tabindex="-1"
              id="BatchAddStylesCanvasTop"
              aria-labelledby="offcanvasTopLabel"
              ref="batchAddStylesCanvas"
              style="height: 60%"
            >
              <div class="offcanvas-header">
                <p class="tw-border-start tw-text-size18 fw-bolder">
                  批次新增細項
                </p>
                <button
                  class="tw-btn tw-btn-success"
                  @click="createBatchStyles"
                >
                  確定新增
                </button>
              </div>
              <div class="offcanvas-body">
                <ul class="nav nav-tabs" role="tablist" id="selectStyleTab">
                  <li class="nav-item">
                    <a
                      @click="setBatchStyleStatus('color')"
                      ref="colorTab"
                      class="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#colorTab"
                      role="tab"
                      aria-selected="true"
                      style="cursor: pointer"
                    >
                      顏色
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      @click="setBatchStyleStatus('size')"
                      ref="sizeTab"
                      class="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#sizeTab"
                      role="tab"
                      aria-selected="false"
                      style="cursor: pointer"
                    >
                      衣服尺寸
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      @click="setBatchStyleStatus('number')"
                      ref="numberTab"
                      class="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#numberTab"
                      role="tab"
                      aria-selected="false"
                      style="cursor: pointer"
                    >
                      依照數字設定
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      @click="setBatchStyleStatus('commonStyles')"
                      ref="commonStylesTab"
                      class="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#commonStylesTab"
                      role="tab"
                      aria-selected="false"
                      style="cursor: pointer"
                    >
                      常用款式
                    </a>
                  </li>
                </ul>
                <div class="tab-content">
                  <!-- color -->
                  <div class="tab-pane fade" id="colorTab">
                    <div class="pt-3">
                      <div class="checkbox-group ms-3">
                        <div
                          class="mb-2"
                          v-for="color in defaultStyles.colors"
                          :key="color.index"
                        >
                          <div class="fancy-checkbox">
                            <label
                              ><input
                                type="checkbox"
                                :value="color"
                                name="color"
                                v-model="D4SystemStyles.selectColors"
                              /><span>{{ color.value }}</span></label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- size -->
                  <div class="tab-pane fade" id="sizeTab">
                    <div class="pt-3">
                      <div class="checkbox-group ms-3">
                        <div
                          class="mb-2"
                          v-for="size in defaultStyles.sizes"
                          :key="size.index"
                        >
                          <div class="fancy-checkbox">
                            <label
                              ><input
                                type="checkbox"
                                :value="size"
                                name="size"
                                v-model="D4SystemStyles.selectSizes"
                              /><span>{{ size.value }}</span></label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- number -->
                  <div class="tab-pane fade" id="numberTab">
                    <div class="pt-3">
                      <!-- 上排 -->
                      <div class="row">
                        <div class="col-4">
                          <div class="form-floating mb-3">
                            <input
                              onwheel="this.blur()"
                              type="number"
                              class="form-control"
                              id="firstNumber"
                              placeholder="僅限輸入數字"
                              v-model="batchCreateByNumber.startNumber"
                              @input="
                                batchCreateByNumber.startNumber =
                                  $methods.numberToFixed(
                                    batchCreateByNumber.startNumber
                                  )
                              "
                            />
                            <label for="firstNumber">起始數字</label>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-floating mb-3">
                            <input
                              onwheel="this.blur()"
                              type="number"
                              class="form-control"
                              id="endNumber"
                              placeholder="僅限輸入數字"
                              v-model="batchCreateByNumber.endNumber"
                              @input="
                                batchCreateByNumber.endNumber =
                                  $methods.numberToFixed(
                                    batchCreateByNumber.endNumber
                                  )
                              "
                            />
                            <label for="endNumber">結束數字</label>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-floating mb-3">
                            <input
                              onwheel="this.blur()"
                              type="number"
                              class="form-control"
                              id="betweenNumber"
                              placeholder="僅限輸入數字"
                              min="1"
                              v-model="batchCreateByNumber.betweenNumber"
                              @input="
                                batchCreateByNumber.betweenNumber =
                                  $methods.numberToFixed(
                                    batchCreateByNumber.betweenNumber
                                  )
                              "
                            />
                            <label for="betweenNumber">數字間距</label>
                          </div>
                        </div>
                      </div>
                      <!-- 下排 -->
                      <div class="row">
                        <div class="col-4">
                          <div class="form-floating mb-3">
                            <input
                              type="text"
                              class="form-control"
                              id="insertFirstText"
                              placeholder="插入字首"
                              v-model="batchCreateByNumber.insertFirstText"
                            />
                            <label for="insertFirstText">插入字首</label>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-floating mb-3">
                            <input
                              type="text"
                              class="form-control"
                              id="insertEndText"
                              placeholder="插入字尾"
                              v-model="batchCreateByNumber.insertEndText"
                            />
                            <label for="insertEndText">插入字尾</label>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-floating mb-3">
                            <input
                              onwheel="this.blur()"
                              type="number"
                              class="form-control"
                              id="paddingDigits"
                              placeholder="補齊位數"
                              min="0"
                              v-model="batchCreateByNumber.paddingDigits"
                              pattern="[0-9]*"
                              oninput="this.value=this.value.replace(/\D/g,'')"
                            />
                            <label for="paddingDigits">補齊位數</label>
                          </div>
                        </div>
                      </div>
                      <!-- 顯示結果 -->
                      <div class="mt-3">
                        <p class="fw-bold mb-3">預覽結果</p>
                        <span
                          v-for="(data, index) in batchCreateByNumberResult"
                          :key="index"
                        >
                          {{ data }}&ensp;
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- commonStyles -->
                  <div class="tab-pane fade" id="commonStylesTab">
                    <div class="pt-3">
                      <div v-if="!p_loading">
                        <template v-if="commonStyles.length > 0">
                          <template v-for="data in commonStyles" :key="data.id">
                            <div class="form-check" v-if="data.selected">
                              <input
                                class="form-check-input m-0 me-2"
                                type="radio"
                                name="flexRadioDefault"
                                :value="data.value"
                                :id="`commonStyle${data.id}`"
                                v-model="commonStyle"
                              />
                              <label
                                class="form-check-label"
                                :for="`commonStyle${data.id}`"
                              >
                                {{ data.value }}
                              </label>
                            </div>
                          </template>
                        </template>
                        <p class="fw-bolder text-danger" v-else>
                          您尚未有任何常用款式 !
                        </p>
                      </div>
                      <AreaLoading v-else></AreaLoading>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createMerchandise"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 上傳圖片的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="uploadPicturesModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">上傳圖片</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeUploadPicture"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="tw-border-start mb-3 fw-bolder">上傳圖片</p>
          <FileUpload
            name="upLoadPicture"
            :multiple="true"
            accept="image/jpeg, image/png"
            maxFileSize="10000000"
            :customUpload="true"
            @uploader="uploadPictures"
            chooseLabel="選擇圖片"
            uploadLabel="上傳圖片"
            cancelLabel="全部刪除"
          >
            <template #empty>
              <p class="fw-bolder text-success">可拖曳圖片進來喔~</p>
            </template>
          </FileUpload>
          <!-- 上傳結果 -->
          <div class="mb-3 mt-5">
            <span class="tw-border-start fw-bolder me-3">上傳結果</span>
            <button
              class="tw-btn me-2"
              :class="{
                'tw-btn-success': !sellectAllPicturesSwitch,
                'tw-btn-secondary': sellectAllPicturesSwitch,
              }"
              v-if="upLoadPicturesData.length > 0"
              @click="sellectAllPictures"
            >
              <span v-if="!sellectAllPicturesSwitch">全選</span>
              <span v-else>全取消</span>
            </button>
            <button
              class="tw-btn tw-btn-danger"
              v-if="upLoadPicturesData.length > 0"
              @click="delPictures('multiple')"
            >
              批次刪除
            </button>
          </div>
          <div
            class="row border border-1 p-0 m-0 mt-3"
            v-if="upLoadPicturesData.length > 0"
          >
            <Draggable
              v-model="upLoadPicturesData"
              class="draggable p-0"
              @start="drag = true"
              @end="drag = false"
              item-key="sequence"
            >
              <template #item="{ element }">
                <div class="border border-1 d-inline-block">
                  <input
                    class="d-block w-100"
                    type="checkbox"
                    name="selectPictures"
                    :value="element"
                    v-model="selectPictures"
                    :id="`Picture${element.id}`"
                  />
                  <label class="pointer" :for="`Picture${element.id}`">
                    <img
                      :src="`https://i.imgur.com/${element.imageHash}s.jpg`"
                      alt=""
                    />
                    <div class="row">
                      <button
                        class="col-6 tw-btn tw-btn-danger"
                        @click="delPictures('single', element)"
                      >
                        刪除
                      </button>
                      <button
                        class="col-6 tw-btn tw-btn-success"
                        v-if="!element.front"
                        @click="setFrontPicture(element)"
                      >
                        首圖
                      </button>
                      <p
                        v-else
                        class="col-6 p-0 text-center text-success fw-bolder"
                        style="line-height: 25px"
                      >
                        首圖
                      </p>
                    </div>
                  </label>
                </div>
              </template>
            </Draggable>
          </div>
          <p class="fw-bolder text-danger" v-else>目前尚未有任何圖片!</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="closeUploadPicture"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="finishUploadPicture"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 同步FB商品進系統的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="syncFbMerchIntoStoreModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">
            同步FB商品進賣場並建立
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-primary mb-3">
            <ul
              class="ms-2 fw-bolder"
              style="list-style-type: decimal; list-style-position: outside"
            >
              <li class="mb-2">一次只能先同步社團或粉專</li>
              <li class="mb-2">
                由於 FB
                規則，所以同步文章僅限在「加入系統應用程式」後的文章才能取回
              </li>
              <li class="mb-2">承上，最多只能同步以今天往前推90天的文章</li>
              <li class="mb-2">
                如選擇時間區間範圍過大會同步資料的時間可能較久，所以建議您一次同步區間都以
                7 天為一次同步
              </li>
            </ul>
          </div>
          <!-- 選擇同步區間 -->
          <div class="mb-3">
            <p class="tw-border-start fw-bolder mb-3">選擇同步區間</p>
            <div class="row">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="TimeScope_StartTime"
                    placeholder="起始時間"
                    v-model="timeScope.startTime"
                  />
                  <label for="TimeScope_StartTime" class="mb-2 text-danger"
                    >*起始時間</label
                  >
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="TimeScope_EndTime"
                    placeholder="結束時間"
                    v-model="timeScope.endTime"
                  />
                  <label for="TimeScope_EndTime" class="mb-2 text-danger"
                    >*結束時間</label
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 選擇FB同步位置 -->
          <div class="mb-2">
            <p class="tw-border-start fw-bolder mb-3">選擇FB同步位置</p>
            <div class="row">
              <div class="form-check p-0 col">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  name="syncFbGroupMerchInto"
                  id="SyncFbGroupMerchIntoStore"
                  value="syncFbGroupMerchIntoStore"
                  v-model="whitchFbTypeToSync"
                  :disabled="!storeFb.group"
                />
                <label class="form-check-label" for="SyncFbGroupMerchIntoStore">
                  同步FB社團
                </label>
              </div>
              <div class="form-check p-0 col">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  name="syncFbGroupMerchInto"
                  id="SyncFbPageMerchIntoStore"
                  value="syncFbPageMerchIntoStore"
                  v-model="whitchFbTypeToSync"
                  :disabled="!storeFb.page"
                />
                <label class="form-check-label" for="SyncFbPageMerchIntoStore">
                  同步FB粉專
                </label>
              </div>
            </div>
          </div>
          <!-- 是否過濾只有自己PO的文章 -->
          <div class="form-check form-switch p-0">
            <input
              class="form-check-input 0 m-0 me-2"
              type="checkbox"
              role="switch"
              id="FilterOwner"
              v-model="filterOwner"
            />
            <label class="form-check-label" for="FilterOwner"
              >只取得社團/粉專擁有者 PO 的文章</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="syncFbMerchIntoStore"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 設定商品 VIP 的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setVipModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">設定商品 VIP</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <select
            class="form-control"
            name="merchandiseVip"
            v-if="!storeVips.loading"
            v-model="setAttributeData.setVipId"
          >
            <option value="">不設定 VIP</option>
            <template v-for="(vip, index) in storeVips.data" :key="vip.id">
              <option :value="vip.id" v-if="vip.id <= storeVip">
                LV{{ index + 1 }}: {{ vip.name }}
              </option>
            </template>
          </select>
          <AreaLoading v-else></AreaLoading>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateMerchandiseVip"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 設定商品標籤的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setLabelModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">設定商品標籤</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="SetLabel"
              placeholder="標籤(15字)"
              maxlength="15"
              v-model="setAttributeData.setLabel"
            />
            <label for="SetLabel">標籤(15字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateLabel"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 設定商品編號的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setNumberModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">設定商品編號</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="SetNumber"
              placeholder="編號(20字)"
              maxlength="20"
              v-model="setAttributeData.setNumber"
            />
            <label for="SetNumber">編號(20字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateNumber"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 設定商品成團數的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setStartSaleQuantityModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">設定成團數</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="StartSaleQuantity"
              placeholder="成團數"
              min="0"
              v-model="setAttributeData.setStartSaleQuantity"
              pattern="[0-9]*"
              oninput="this.value=this.value.replace(/\D/g,'')"
            />
            <label for="StartSaleQuantity">成團數</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateStartSaleQuantity"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 設定倉儲位置的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setStorageLocationModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">設定倉儲位置</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="StorageLocation"
              placeholder="倉儲位置"
              maxlength="10"
              v-model="setAttributeData.setStorageLocation"
            />
            <label for="StorageLocation">倉儲位置</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateStorageLocation"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 設定收單時間的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setDeadLineTimeModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">設定收單時間</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="form-check form-switch p-0 mb-2">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="SwitchDeadLineTime"
              v-model="setAttributeData.switchDeadLineTime"
            />
            <label class="form-check-label" for="SwitchDeadLineTime"
              >開啟收單時間</label
            >
          </div>
          <div class="form-floating" v-if="setAttributeData.switchDeadLineTime">
            <input
              class="form-control"
              type="datetime-local"
              id="SetDeadLineTimeForCalendar"
              placeholder="收單時間"
              v-model="setAttributeData.setDeadLineTime"
            />
            <label for="SetDeadLineTimeForCalendar" class="mb-2"
              >收單時間</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateDeadLineTime"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 設定到貨時間的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setArrivalTimeModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">設定到貨時間</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="form-check form-switch p-0 mb-2">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="SwitchArrivalTime"
              v-model="setAttributeData.switchArrivalTime"
            />
            <label class="form-check-label" for="SwitchArrivalTime"
              >開啟到貨時間</label
            >
          </div>
          <div class="form-floating" v-if="setAttributeData.switchArrivalTime">
            <input
              class="form-control"
              type="datetime-local"
              id="SetArrivalTimeForCalendar_S"
              placeholder="到貨時間"
              v-model="setAttributeData.setArrivalTime"
            />
            <label for="SetArrivalTimeForCalendar_S" class="mb-2"
              >到貨時間</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateArrivalTimeTime"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 設定商品分類的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setMerchClassificationModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">設定商品分類</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="row" v-if="!merchClassifications.loading">
            <template v-for="data in merchClassifications.data" :key="data.id">
              <div class="form-check p-0 col-6" v-if="data.id !== 0">
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  :value="data.id"
                  :id="`SetCategory${data.id}`"
                  v-model="createMerchandiseData.merchClassificationIds"
                />
                <label class="form-check-label" :for="`SetCategory${data.id}`">
                  {{ data.name }}
                </label>
              </div>
            </template>
          </div>
          <AreaLoading v-else></AreaLoading>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateMerchClassifications"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 設定商品細節的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setAllAttributeModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">設定商品細節</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <ul>
            <!-- 上下架 -->
            <li class="row">
              <div class="col-1">
                <input
                  class="form-check-input m-0 p-0"
                  v-model="setAttributeData.checkSetReady"
                  type="checkbox"
                />
              </div>
              <div class="form-check form-switch m-0 p-0 col-11">
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  role="switch"
                  id="CheckIsReady"
                  v-model="setAttributeData.setReady"
                />
                <label class="form-check-label" for="CheckIsReady"
                  >是否上架</label
                >
              </div>
              <hr />
            </li>
            <!-- 禁止顧客自行結單 -->
            <li class="row">
              <div class="col-1">
                <input
                  class="form-check-input m-0 p-0"
                  v-model="setAttributeData.checkCanCheckOut"
                  type="checkbox"
                />
              </div>
              <div class="form-check form-switch m-0 p-0 col-11">
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  role="switch"
                  id="CheckIsCanCheckOut"
                  v-model="setAttributeData.setCheckOut"
                />
                <label class="form-check-label" for="CheckIsCanCheckOut"
                  >顧客自行結單</label
                >
              </div>
              <hr />
            </li>
            <!-- 商品販售狀態 -->
            <li class="row">
              <div class="col-1">
                <input
                  class="form-check-input m-0 p-0"
                  type="checkbox"
                  v-model="setAttributeData.checkSellState"
                />
              </div>
              <div class="m-0 mb-3 p-0 col-11">
                <select
                  class="form-select m-0"
                  aria-label="Default select example"
                  v-model="setAttributeData.setSellState"
                >
                  <option value="CANPREORDER" selected>可預購</option>
                  <option value="STOCKONLY" selected>僅供現貨</option>
                  <option value="LIMITED" selected>限量</option>
                </select>
              </div>
              <hr />
            </li>
            <!-- 設定 VIP -->
            <li class="row">
              <div class="col-1">
                <input
                  class="form-check-input m-0 p-0"
                  type="checkbox"
                  v-model="setAttributeData.checkSetVipId"
                />
              </div>
              <div class="m-0 mb-3 p-0 col-11">
                <select
                  class="form-select m-0"
                  aria-label="Default select example"
                  v-if="!storeVips.loading"
                  v-model="setAttributeData.setVipId"
                >
                  <option value="" selected>不選擇 VIP</option>
                  <template
                    v-for="(data, index) in storeVips.data"
                    :key="data.id"
                  >
                    <option :value="data.id" v-if="data.id <= storeVip">
                      LV{{ index + 1 }}: {{ data.name }}
                    </option>
                  </template>
                </select>
                <AreaLoading v-else></AreaLoading>
              </div>
              <hr />
            </li>
            <!-- 自定義編號 -->
            <li class="row">
              <div class="col-1">
                <input
                  class="form-check-input m-0 p-0"
                  type="checkbox"
                  v-model="setAttributeData.checkSetNumber"
                />
              </div>
              <div class="form-floating p-0 m-0 mb-3 col-11">
                <input
                  type="text"
                  class="form-control"
                  id="CheckLabel"
                  placeholder="編號(20字)"
                  maxlength="20"
                  v-model="setAttributeData.setNumber"
                />
                <label for="CheckLabel">編號(20字)</label>
              </div>
              <hr />
            </li>
            <!-- 自定義標籤 -->
            <li class="row">
              <div class="col-1">
                <input
                  class="form-check-input m-0 p-0"
                  type="checkbox"
                  v-model="setAttributeData.checkSetLabel"
                />
              </div>
              <div class="form-floating p-0 m-0 mb-3 col-11">
                <input
                  type="text"
                  class="form-control"
                  id="CheckNumber"
                  placeholder="標籤(15字)"
                  maxlength="15"
                  v-model="setAttributeData.setLabel"
                />
                <label for="CheckNumber">標籤(15字)</label>
              </div>
              <hr />
            </li>
            <!-- 倉儲位置 -->
            <li class="row">
              <div class="col-1">
                <input
                  class="form-check-input m-0 p-0"
                  type="checkbox"
                  v-model="setAttributeData.checkSetStorageLocation"
                />
              </div>
              <div class="form-floating p-0 m-0 mb-3 col-11">
                <input
                  type="text"
                  class="form-control"
                  id="CheckStoreage"
                  placeholder="倉儲(10字)"
                  maxlength="10"
                  v-model="setAttributeData.setStorageLocation"
                />
                <label for="CheckStoreage">倉儲(10字)</label>
              </div>
              <hr />
            </li>
            <!-- 成團數 -->
            <li class="row">
              <div class="col-1">
                <input
                  class="form-check-input m-0 p-0"
                  type="checkbox"
                  v-model="setAttributeData.checkSetStartSaleQuantity"
                />
              </div>
              <div class="form-floating p-0 m-0 mb-3 col-11">
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  id="CheckStartSale"
                  placeholder="成團數"
                  v-model="setAttributeData.setStartSaleQuantity"
                  pattern="[0-9]*"
                  oninput="this.value=this.value.replace(/\D/g,'')"
                />
                <label for="CheckStartSale">成團數</label>
              </div>
              <hr />
            </li>
            <!-- 收單時間 -->
            <li>
              <div class="row">
                <div class="col-6">
                  <input
                    class="form-check-input m-0 p-0"
                    type="checkbox"
                    v-model="setAttributeData.checkDeadLineTime"
                  />
                </div>
                <div class="form-check form-switch p-0 mb-2 col-6">
                  <input
                    class="form-check-input m-0 me-2"
                    type="checkbox"
                    role="switch"
                    id="SwitchDeadLineTime"
                    v-model="setAttributeData.switchDeadLineTime"
                  />
                  <label class="form-check-label" for="SwitchDeadLineTime"
                    >開啟收單時間</label
                  >
                </div>
              </div>
              <div
                class="form-floating"
                v-if="setAttributeData.switchDeadLineTime"
              >
                <input
                  class="form-control"
                  type="datetime-local"
                  id="SetDeadLineTimeForCalendar"
                  placeholder="收單時間"
                  v-model="setAttributeData.setDeadLineTime"
                />
                <label for="SetDeadLineTimeForCalendar" class="mb-2"
                  >收單時間</label
                >
              </div>
              <hr />
            </li>
            <!-- 到貨時間 -->
            <li>
              <div class="row">
                <div class="col-6">
                  <input
                    class="form-check-input m-0 p-0"
                    type="checkbox"
                    v-model="setAttributeData.checkArrivalTime"
                  />
                </div>
                <div class="form-check form-switch p-0 mb-2 col-6">
                  <input
                    class="form-check-input m-0 me-2"
                    type="checkbox"
                    role="switch"
                    id="SwitchArrivalTime"
                    v-model="setAttributeData.switchArrivalTime"
                  />
                  <label class="form-check-label" for="SwitchArrivalTime"
                    >開啟到貨時間</label
                  >
                </div>
              </div>
              <div
                class="form-floating"
                v-if="setAttributeData.switchArrivalTime"
              >
                <input
                  class="form-control"
                  type="datetime-local"
                  id="SetDetailArrivalTime"
                  placeholder="到貨時間"
                  v-model="setAttributeData.setArrivalTime"
                />
                <label for="SetDetailArrivalTime" class="mb-2">到貨時間</label>
              </div>
              <hr />
            </li>
          </ul>
          <p class="alert-danger fw-bolder p-3" v-if="errorData.length > 0">
            目前所選的商品：
            <span v-for="(data, index) in errorData" :key="data.id">
              {{ data.name }}&ensp; </span
            >，收單時間已到期，故系統會自動將您的上架設定取消，除非您本次的設定已將收單時間關閉或延後
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateAttributes"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 複製的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="cloneToStoreModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">複製</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="alert alert-primary mb-3" role="alert">
            <p class="mb-2">1. 一次僅能複製 5個商品 至 一個賣場</p>
          </div>
          <ul>
            <li v-for="data in ownerStores.data" :key="data.storeId">
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  name="selectCloneStore"
                  :id="`SelectCloneStore${data.storeId}`"
                  :value="data.storeId"
                  v-model="cloneToStore"
                />
                <label
                  class="form-check-label"
                  :for="`SelectCloneStore${data.storeId}`"
                >
                  {{ data.store.name }}
                  <span
                    class="text-danger fw-bolder"
                    v-if="storeId == data.storeId"
                    >(商品所在賣場)</span
                  >
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="cloneMerchandiseToStore"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除/恢復商品的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="updateMerchStatusModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div
          class="modal-header alert alert-danger"
          v-if="merchStatus === 'delete'"
        >
          <h5
            class="modal-title border-start ps-2 border-5 border-danger text-danger fw-bold"
          >
            刪除商品
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-header alert alert-success" v-else>
          <h5
            class="modal-title border-start ps-2 border-5 border-success text-success fw-bold"
          >
            恢復商品
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>
            確定要
            <span v-if="merchStatus === 'delete'">刪除</span>
            <span v-else>恢復</span>
            這些商品 ?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateMerchStatus"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 批次結單的 modal -->
  <BatchCheckOut
    :propsSelectInfos="propsSelectInfos"
    :showBatchCheckOutModal="showBatchCheckOutModal"
    @notifyParentFromBatchCheckOut="notifyParentFromBatchCheckOut"
  ></BatchCheckOut>
  <!-- 預設供應商的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setD4SupplierModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">預設供應商</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="mb-2" v-if="!suppliers.loading">
            <template v-if="suppliers.data.length > 0">
              <select class="form-control" v-model="setD4SupplierId">
                <option value="" disabled>不設定供應商</option>
                <option
                  :value="data.id"
                  v-for="data in suppliers.data"
                  :key="data.id"
                >
                  {{ data.name }}
                </option>
              </select>
            </template>
            <p class="text-danger fw-bolder" v-else>您目前尚未有任何供應商!</p>
          </div>
          <AreaLoading v-else></AreaLoading>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateD4Supplier"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 批次新增商品優惠的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="batchAddDiscountsModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">批次新增商品優惠</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 設定款式 VIP -->
          <div class="mb-3">
            <p class="tw-border-start mb-2 fw-bolder">VIP等級</p>
            <select
              class="form-control"
              name="merchandiseVip"
              v-if="!storeVips.loading"
              v-model="batchDiscountsData.storeVipId"
            >
              <option value="">不設定 VIP</option>
              <template v-for="(vip, index) in storeVips.data" :key="vip.id">
                <option :value="vip.id" v-if="vip.id <= storeVip">
                  LV{{ index + 1 }}: {{ vip.name }}
                </option>
              </template>
            </select>
            <AreaLoading v-else></AreaLoading>
          </div>
          <!-- 購買數量 -->
          <div class="mb-3">
            <p class="tw-border-start mb-2 fw-bolder">購買數量</p>
            <div class="row">
              <div class="col-6 p-0 pe-1">
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    placeholder="最小購買數量"
                    min="0"
                    v-model="batchDiscountsData.minQuantity"
                    pattern="[0-9]*"
                    oninput="this.value=this.value.replace(/\D/g,'')"
                  />
                  <label>最小購買數量</label>
                </div>
              </div>
              <div class="col-6 p-0 ps-1">
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    placeholder="最大購買數量"
                    min="0"
                    v-model="batchDiscountsData.maxQuantity"
                    pattern="[0-9]*"
                    oninput="this.value=this.value.replace(/\D/g,'')"
                  />
                  <label>最大購買數量</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 價格 -->
          <div class="mb-3">
            <p class="tw-border-start mb-2 fw-bolder">優惠價格</p>
            <!-- 選擇優惠方式 -->
            <div class="row mb-3">
              <!-- 套用原先價格 -->
              <div class="col-lg-4 col-12">
                <div class="form-check p-0 col">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectDiscountType"
                    id="SetOriginPrice"
                    value="setOriginPrice"
                    v-model="batchDiscountsData.selectDiscountType"
                  />
                  <label class="form-check-label" for="SetOriginPrice">
                    套用原先價格
                  </label>
                </div>
              </div>
              <!-- 自行輸入價格 -->
              <div class="col-lg-4 col-12">
                <div class="form-check p-0 col">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectDiscountType"
                    id="SetOwnPrice"
                    value="setOwnPrice"
                    v-model="batchDiscountsData.selectDiscountType"
                  />
                  <label class="form-check-label" for="SetOwnPrice">
                    自行輸入價格
                  </label>
                </div>
              </div>
              <!-- 減少 % 數 -->
              <div class="col-lg-4 col-12">
                <div class="form-check p-0 col">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectDiscountType"
                    id="ReducePercent"
                    value="reducePercent"
                    v-model="batchDiscountsData.selectDiscountType"
                  />
                  <label class="form-check-label" for="ReducePercent">
                    減少 % 數
                  </label>
                </div>
              </div>
              <!-- 減少金額 -->
              <div class="col-lg-4 col-12">
                <div class="form-check p-0 col">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectDiscountType"
                    id="ReducePrice"
                    value="reducePrice"
                    v-model="batchDiscountsData.selectDiscountType"
                  />
                  <label class="form-check-label" for="ReducePrice">
                    減少金額
                  </label>
                </div>
              </div>
              <!-- 減少 % 數與金額，結果取最"高" -->
              <div class="col-lg-4 col-12">
                <div class="form-check p-0 col">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectDiscountType"
                    id="BothGetHigher"
                    value="bothGetHigher"
                    v-model="batchDiscountsData.selectDiscountType"
                  />
                  <label class="form-check-label" for="BothGetHigher">
                    減少 % 數與金額，結果取最"高"
                  </label>
                </div>
              </div>
              <!-- 減少 % 數與金額，結果取最"低" -->
              <div class="col-lg-4 col-12">
                <div class="form-check p-0 col">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectDiscountType"
                    id="BothGetLower"
                    value="bothGetLower"
                    v-model="batchDiscountsData.selectDiscountType"
                  />
                  <label class="form-check-label" for="BothGetLower">
                    減少 % 數與金額，結果取最"低"
                  </label>
                </div>
              </div>
            </div>
            <!-- 對應欄位 -->
            <div>
              <!-- 自行輸入價格 -->
              <div
                class="form-floating mb-3"
                v-if="batchDiscountsData.selectDiscountType === 'setOwnPrice'"
              >
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  placeholder="自行輸入金額"
                  v-model="batchDiscountsData.setOwnPrice"
                  @input="
                    batchDiscountsData.setOwnPrice = $methods.numberToFixed(
                      batchDiscountsData.setOwnPrice
                    )
                  "
                />
                <label>自行輸入金額</label>
              </div>
              <!-- 減少%數 -->
              <div
                class="form-floating mb-3"
                v-if="
                  batchDiscountsData.selectDiscountType === 'reducePercent' ||
                  batchDiscountsData.selectDiscountType === 'bothGetHigher' ||
                  batchDiscountsData.selectDiscountType === 'bothGetLower'
                "
              >
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  placeholder="減少%數"
                  v-model="batchDiscountsData.percent"
                  @input="
                    batchDiscountsData.percent = $methods.numberToFixed(
                      batchDiscountsData.percent
                    )
                  "
                />
                <label>減少%數</label>
              </div>
              <!-- 減少金額 -->
              <div
                class="form-floating mb-3"
                v-if="
                  batchDiscountsData.selectDiscountType === 'reducePrice' ||
                  batchDiscountsData.selectDiscountType === 'bothGetHigher' ||
                  batchDiscountsData.selectDiscountType === 'bothGetLower'
                "
              >
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  placeholder="減少金額"
                  v-model="batchDiscountsData.reducePrice"
                  @input="
                    batchDiscountsData.reducePrice = $methods.numberToFixed(
                      batchDiscountsData.reducePrice
                    )
                  "
                />
                <label>減少金額</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="batchAddDiscounts(true)"
          >
            持續新增
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="batchAddDiscounts(false)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 設定 fb 留言自動建立訂單的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setAutoCreateOrderFromFbModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">
            設定同步FB留言自動建立訂單
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- FB 社團 -->
          <div class="form-check form-switch p-0">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="FBGroupAuto"
              v-model="FBAutoCreateOrder.group"
            />
            <label class="form-check-label" for="FBGroupAuto"
              >FB<span class="text-primary">社團</span>自動成立訂單</label
            >
          </div>
          <!-- FB 粉專 -->
          <div class="form-check form-switch p-0">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="FBPageAuto"
              v-model="FBAutoCreateOrder.page"
            />
            <label class="form-check-label" for="FBPageAuto"
              >FB<span class="text-success">粉專</span>自動成立訂單</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="setAutoCreateOrderFromFb"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 匯出的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="exportExcelModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">匯出 Excel</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>確定要將這些商品匯出成 Excel 格式嗎 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="exportExcel"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 發布至 FB 社團/粉專的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="postToFbModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">
            發布至FB
            <span v-if="postToFbForWhere === 'group'">社團</span>
            <span v-else>粉專</span>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 警語 -->
          <div class="mb-3">
            <div class="alert alert-danger" role="alert">
              注意！請勿大量、反覆得發送相同內容，此舉會造成FB官方判斷貼文為垃圾貼文。另外也請注意照片版權問題，FB官方會根據版權問題將貼文封鎖。
            </div>
          </div>
          <!-- 商品圖片與描述 -->
          <div class="row mb-3">
            <div class="col-lg-6 mb-3 mb-lg-0 p-0">
              <p class="tw-border-start fw-bolder mb-2">商品圖片</p>
              <div class="alert alert-warning" role="alert">
                由於FB限制，您只能上傳一張照片
              </div>
              <img
                :src="postToFbData.img"
                class="w-100"
                style="height: 250px"
                v-if="postToFbData.img"
              />
              <img
                src="@/assets/other-images/noImg.png"
                class="w-100"
                style="height: 250px"
                v-else
              />
            </div>
            <div class="col-lg-6 p-0">
              <p class="tw-border-start fw-bolder mb-2">商品描述(1000字)</p>
              <textarea
                class="form-control"
                rows="13"
                v-model="postToFbData.description"
                maxlength="1000"
              ></textarea>
            </div>
          </div>
          <hr />
          <!-- 款式規則 -->
          <div class="mb-3">
            <div class="form-check form-switch p-0">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AddRules"
                v-model="postToFbData.checkRules"
              />
              <label class="form-check-label" for="AddRules"
                >加入款式規則</label
              >
            </div>
            <textarea
              class="form-control"
              cols="30"
              rows="10"
              v-model="postToFbData.rules"
            ></textarea>
          </div>
          <hr />
          <!-- 自動成立訂單 -->
          <div>
            <div class="alert alert-success" role="alert">
              您將發布此商品至 FB社團/粉專並且配合自動判斷留言成立訂單功能
              ，之後客人在貼文下方按照款式規則留言，並可同步於此管理。
            </div>
            <div class="form-check form-switch p-0">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AutoCreateOrderFromFb"
                v-model="postToFbData.checkAutoCreateOrder"
              />
              <label class="form-check-label" for="AutoCreateOrderFromFb"
                >允許同步社群時，自動判斷留言成立訂單(auto)</label
              >
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="postToFb">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 產生 FB 貼文連結的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="createFbPostLinksModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">產生FB貼文連結</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 選擇發布至哪裡 -->
          <p class="tw-border-start fw-bolder mb-3">選擇發布至哪裡</p>
          <div class="row">
            <!-- 社團 -->
            <div class="col-6">
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  name="selectPostToWhere"
                  id="FbGroup"
                  value="fbGroup"
                  v-model="filterFbLink.fbType"
                  :disabled="!storeFb.group"
                />
                <label class="form-check-label" for="FbGroup">社團</label>
              </div>
            </div>
            <!-- 粉專 -->
            <div class="col-6 p-0">
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  name="selectPostToWhere"
                  id="FbPage"
                  value="fbPage"
                  v-model="filterFbLink.fbType"
                  :disabled="!storeFb.page"
                />
                <label class="form-check-label" for="FbPage">粉專</label>
              </div>
            </div>
          </div>
          <hr />
          <!-- 選擇呈現方式 -->
          <p class="tw-border-start fw-bolder mb-3">選擇呈現方式</p>
          <div class="row mb-3">
            <!-- 僅顯示貼文連結 -->
            <div class="col-lg-4 col-12">
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  name="selectLinksType"
                  id="Link"
                  value="link"
                  v-model="filterFbLink.showType"
                />
                <label class="form-check-label" for="Link">
                  僅顯示貼文連結
                </label>
              </div>
            </div>
            <!-- 熱賣商品 -->
            <div class="col-lg-4 col-12">
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  name="selectLinksType"
                  id="Hot"
                  value="hot"
                  v-model="filterFbLink.showType"
                />
                <label class="form-check-label" for="Hot"> 熱賣商品 </label>
              </div>
            </div>
            <!-- 即將收單 -->
            <div class="col-lg-4 col-12">
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  name="selectLinksType"
                  id="DeadLine"
                  value="deadLine"
                  v-model="filterFbLink.showType"
                />
                <label class="form-check-label" for="DeadLine">
                  即將收單
                </label>
              </div>
            </div>
          </div>
          <hr />
          <!-- 列表 -->
          <div>
            <textarea
              class="form-control"
              cols="30"
              rows="10"
              v-model="filterFbLink.finalString"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="postLinkToFb"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 加入 line 待推播列表 -->
  <ToLinePush
    :propsSelectInfos="propsSelectInfos"
    :lineBotData="lineBotData"
    :showLinePushModal="showLinePushModal"
    :defaultText="linePushD4Text"
    @notifyParentFromLinePush="notifyParentFromLinePush"
  ></ToLinePush>
  <!-- 發布至Line Notify -->
  <PostToLineNotify
    :storeLineNotify="storeLineNotify"
    :propsSelectInfos="propsSelectInfos"
    :showLineNotifyModal="showLineNotifyModal"
    @notifyParentFromPostToLineNotify="notifyParentFromPostToLineNotify"
  ></PostToLineNotify>
  <!-- 發佈到貨通知的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="postTheArrivalToNotifyModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">發佈到貨通知</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 選擇定義 -->
          <div class="mb-3">
            <p class="tw-border-start fw-bolder mb-3">選擇定義</p>
            <!-- 篩選條件 -->
            <div class="border mb-3 p-3">
              <p class="tw-border-start fw-bolder">篩選條件</p>
              <hr />
              <ul class="list">
                <li
                  class="row border-bottom"
                  v-for="data in selectPostStatus"
                  :key="data.id"
                >
                  <div class="col-3 my-2">{{ data.key }}</div>
                  <div class="col-9">
                    <div
                      class="select-radio my-2"
                      v-for="select in data.value"
                      :key="select.id"
                    >
                      <label>
                        <input
                          type="radio"
                          :name="data.id"
                          :value="select.value"
                          v-model="postArrivalToNotify[data.id]"
                        />
                        <span class="radio-style">{{ select.value }}</span>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 選擇發送對象 -->
          <div class="mb-3">
            <p class="tw-border-start fw-bolder mb-3">選擇發送對象</p>
            <!-- 一對一 -->
            <div class="form-check p-0">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                id="OneByOne"
                value="oneByOne"
                v-model="postArrivalToNotify.postTo"
              />
              <label class="form-check-label" for="OneByOne"
                >一對一發送(發送給訂單購買者)</label
              >
            </div>
            <!-- 群組 -->
            <template v-if="storeLineNotify.bindStore.length > 0">
              <div
                class="form-check p-0"
                v-for="data in storeLineNotify.bindStore"
                :key="data.id"
              >
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  :id="`ForGroup${data.id}`"
                  :value="data.id"
                  v-model="postArrivalToNotify.postTo"
                />
                <label class="form-check-label" :for="`ForGroup${data.id}`">{{
                  data.groupName
                }}</label>
              </div>
            </template>
            <p class="fw-bolder text-danger" v-else>
              您尚未在賣場綁定Line Notify群組!
            </p>
          </div>
          <!-- 自定義訊息 -->
          <div>
            <p class="tw-border-start fw-bolder mb-3">自定義訊息(1000字)</p>
            <textarea
              cols="30"
              rows="10"
              class="form-control"
              v-model="postArrivalToNotify.sellerMessage"
              maxlength="1000"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="postTheArrivalToNotify"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 未處理留言設定的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setUnHandleMessageModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">未處理留言設定</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="alert alert-danger">
            您預計將選擇的商品裡所有未處理留言設為「非訂單」狀態，注意此動作不能回復!
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="setUnHandleMessage"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 同步 FB 社團/粉專留言的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="syncFbMessagesModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">
            同步FB{{ postToFbForWhere === "group" ? "社團" : "粉專" }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <div class="alert alert-primary mb-3">
            <ul
              class="ms-2 fw-bolder"
              style="list-style-type: decimal; list-style-position: outside"
            >
              <li class="mb-2">
                您將在FB{{
                  postToFbForWhere === "group" ? "社團" : "粉專"
                }}同步留言回系統。
              </li>
              <li class="mb-2">
                您可以選擇"只同步您選擇的商品"，也可以同步"所有商品"，但請注意若同步的商品數量太多，也是需要耗費較多的時間喔^^
              </li>
              <li class="mb-2">
                同步的商品必須要是在FB發文90天內的文章才會同步回來
              </li>
              <li>選取商品只會取您所選擇的前 20 筆商品來進行同步</li>
            </ul>
          </div>
          <!-- 選擇同步方式 -->
          <div>
            <p class="tw-border-start fw-bolder mb-3">選擇同步方式</p>
            <div class="row">
              <!-- 只同步所選商品 -->
              <div class="form-check p-0 col">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  name="selectSyncType"
                  id="SyncOwnSelect"
                  value="syncOwnSelect"
                  v-model="syncStype"
                />
                <label class="form-check-label" for="SyncOwnSelect"
                  >只同步所選商品</label
                >
              </div>
              <!-- 同步所有商品 -->
              <div class="form-check p-0 col">
                <input
                  class="form-check-input m-0 me-2"
                  type="radio"
                  name="selectSyncType"
                  id="SyncAll"
                  value="syncAll"
                  v-model="syncStype"
                />
                <label class="form-check-label" for="SyncAll"
                  >同步所有商品</label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="syncFbMessages"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 代客加單 -->
  <AddOrderBySeller
    :participants="participants"
    :propsSelectInfos="propsSelectInfos"
    :showAddOrderBySeller="showAddOrderBySeller"
    @notifyParentFromAddOrderBySeller="notifyParentFromAddOrderBySeller"
  ></AddOrderBySeller>
  <!-- 表格可點選區介紹 modal -->
  <div
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="introductionModal"
  >
    <div class="modal-dialog" :style="scrollWidth">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">表格可點選區域</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <img
              src="@/assets/images/table-introduction/merchandise.jpg"
              style="max-width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 加入商品至直播列表的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="toLivePushModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">至 Line 直播列表</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 插播至最優先 -->
          <div class="row align-items-center mb-3">
            <div class="col-lg-3">
              <input
                class="me-2"
                type="checkbox"
                name="linePush"
                id="LiveJump"
                v-model="livePushData.jump"
              />
              <label for="LiveJump" class="mb-2">插播至最優先</label>
            </div>
          </div>
          <!-- 選擇 Line 群組 -->
          <div>
            <h5 class="tw-border-start fw-bolder mb-3">
              選擇 Line 群組
              <template class="ms-1" v-if="!lineBotData.loading">
                <button
                  class="tw-btn tw-btn-danger"
                  v-if="
                    livePushData.groupLinkIds.length === lineBotData.data.length
                  "
                  @click="allCheck"
                >
                  取消全選
                </button>
                <button v-else class="tw-btn tw-btn-success" @click="allCheck">
                  全選
                </button>
              </template>
            </h5>
            <AreaLoading v-if="lineBotData.loading"></AreaLoading>
            <div v-else class="ms-3">
              <template v-if="lineBotData.data.length > 0">
                <div
                  class="mb-2"
                  v-for="data in lineBotData.data"
                  :key="data.linkId"
                >
                  <input
                    type="checkbox"
                    class="me-2"
                    :value="data.linkId"
                    :id="`Live${data.linkId}`"
                    name="selectLineGroup"
                    v-model="livePushData.groupLinkIds"
                  />
                  <label :for="`Live${data.linkId}`">{{
                    data.groupName
                  }}</label>
                </div>
              </template>
              <p v-else class="text-danger">目前尚未有任何群組!</p>
            </div>
          </div>
          <hr />
          <!-- 平整化款式 -->
          <div>
            <p class="alert-success fw-bolder p-3 mb-2">
              若您有特別的款式組合不想讓顧客下單，您可以將那組款式的「關鍵字欄位清空」即可
            </p>
            <p class="tw-border-start fw-bolder mb-2">關鍵字設定</p>
            <div class="table-responsive mb-3">
              <table
                class="table table-hover"
                v-if="livePushData.queues.length > 0"
              >
                <thead>
                  <tr>
                    <th style="min-width: 200px">款式</th>
                    <th style="width: 150px">關鍵字</th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(data, index) in livePushData.queues"
                    :key="index"
                  >
                    <tr
                      class="text-start fw-bolder text-success tw-text-size18"
                    >
                      <td class="text-break" colspan="2">
                        {{ data.merchandiseName }}
                      </td>
                    </tr>
                    <tr
                      v-for="(style, index) in data.styleKeywords"
                      :key="index"
                    >
                      <td class="text-start ps-5 text-break">
                        {{ style.styles }}
                      </td>
                      <td>
                        <input type="text" v-model="style.keyword" />
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="addMerchToLiveQueue"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 批次處理 FB 未處理留言 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="batchHandleFbMessagesModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">
            批次處理 FB 未處理留言
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="alert alert-warning fw-bolder p-3">
            請注意! 此次搜尋出來的
            <span class="text-danger">"未處理留言商品"</span> 是以
            <span class="text-primary">目前表格上所看得到的商品做篩選過濾</span>
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="openBatchHandleFbMessages"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 下載擴充 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="downloadExtentionModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">擴充教學</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>擴充教學圖</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <a
            class="tw-btn tw-btn-success"
            href="/TwingoExtention.zip"
            download="etg-extention.zip"
            >下載擴充</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapState } from 'vuex'
// mixin
import { HandleImages } from '../../../methods/mixins/handleImages'
import { MerchandiseStylesMap } from '../../../methods/mixins/getSystemDefaultStyles'
import { SplitGraph } from '../../../methods/mixins/splitGraph'
import { serialArray } from '../../../methods/mixins/serialArray'
import { convertStyleTo2D } from '../../../methods/mixins/arrayTo2D'
import { Permission } from '@/methods/mixins/permission';
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// draggable
import Draggable from "vuedraggable";
// primevue
import FileUpload from 'primevue/fileupload';
// components
import ToLinePush from '../../../components/modal/ToLinePush.vue'
import PostToLineNotify from '../../../components/modal/PostToNotify.vue'
import AddOrderBySeller from '../../../components/modal/AddOrderBySeller.vue'
import BatchCheckOut from '../../../components/modal/BatchCheckOut.vue'
import ProgressBar from '../../../components/primeVue/ProgressBar.vue'
import FieldFilter from '../../../components/tools/FieldFilter.vue'
import BackgroundHandleSyncFbMessages from '@/components/tools/BackgroundHandleSyncFbMessages.vue'

export default {
  components: {
    DataTable, Column, ColumnGroup, Row, 
    Draggable, FileUpload, ToLinePush, PostToLineNotify,
    AddOrderBySeller,BatchCheckOut, ProgressBar, FieldFilter, BackgroundHandleSyncFbMessages,
  },
  data() {
    return {
      // modal
      advancedSearchModal: {},
      createMerchandiseModal: {},
      syncFbMerchIntoStoreModal: {},
      setVipModal: {},
      setLabelModal: {},
      setNumberModal: {},
      setStartSaleQuantityModal: {},
      setDeadLineTimeModal: {},
      setArrivalTimeModal: {},
      setMerchClassificationModal: {},
      setAllAttributeModal: {},
      updateMerchStatusModal: {},
      setD4SupplierModal: {},
      batchAddDiscountsModal: {},
      uploadPicturesModal: {},
      setUnHandleMessageModal: {},
      setAutoCreateOrderFromFbModal: {},
      exportExcelModal: {},
      postToFbModal: {},
      createFbPostLinksModal: {},
      postTheArrivalToNotifyModal: {},
      syncFbMessagesModal: {},
      introductionModal: {},
      toLivePushModal: {},
      batchHandleFbMessagesModal: {},
      downloadExtentionModal: {},
      // canvas
      batchAddStylesCanvas: {},
      // tab
      sizeTab: {},
      colorTab: {},
      numberTab: {},
      commonStylesTab: {},
      // data
      serverToken: '',
      storeId: 0,
      // 開啟批次結單
      showBatchCheckOutModal: false,
      // 開啟發布至 Line Notify
      showLineNotifyModal: false,
      // 開啟 line 推播列表
      showLinePushModal: false,
      // 開啟代客加單
      showAddOrderBySeller: false,
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      merchandiseList: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 商品表格進度條資料
      progressData: {
        target: 'merchandise',
        percent: 0
      },
      // 通知背景處理元件的資料
      noticeBackgroundHandleData: {
        active: false
      },
      // 選擇到的物件
      selectItems: [],
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'name',
        data: []
      },
      // 賣場資訊
      storeInfo: {},
      // storeVips
      storeVip: 0,
      storeVips: {
        loading: false,
        data: []
      },
      // 商品分類
      merchClassifications: {
        loading: false,
        data: []
      },
      // 供應商
      suppliers: {
        loading: false,
        data: []
      },
      // 紀錄賣場是否有綁定 FB 社團/粉專
      storeFb: {
        loading: false,
        group: null,
        page: null,
      },
      // 紀錄賣場是否有綁定 line notify和本身是否有綁定自己的
      storeLineNotify: {
        loading: false,
        bindStore: [],
        bindMyself: null
      },
      // 紀錄賣場是否綁定 line bot
      lineBotData: {
        loading: true,
        data: []
      },
      // 賣場所有顧客
      participants: {
        loading: false,
        data: []
      },
      // create merchandise
      showAddStyleBtn: true,
      createMerchandiseData: {
        storeId: 0,
        name: '',
        description: '',
        storeVipId: '',
        ready: true,
        sellState: 'CANPREORDER',
        number: '',
        label: '',
        startSaleQuantity: 0,
        checkDeadLineTime: false,
        deadLineTime: '',
        storageLocation: '',
        checkOut: true,
        note: '',
        checkArrivalTime: false,
        arrivalTime: '',
        merchClassificationIds: [],
        supplierStatus: '不選擇供應商',
        D4Supllier: {
          name: '',
          cost: 0,
        },
        setOwnSupplier: {
          name: '',
          cost: 0,
        },
        setmainStyls: '',
        setMainStylsPrice: '',
        setMainStylesCost: '',
        setoneStyles: '',
        settwoStyles: '',
        setthreeStyles: '',
        setfourStyles: '',
        mainStyles: [],
        oneStyles: [],
        twoStyles: [],
        threeStyles: [],
        fourStyles: []
      },
      D4SystemStyles: {
        selectColors: [],
        selectSizes: [],
      },
      batchStyleCurrentStatus: 'color', // 點選批次新增系統預設款式時，應該是要加入哪一個預設款式
      batchStyleForWhitchStyle: 'oneStyles',  // 點選批次新增系統預設款式時，要拿來判斷加入哪一個細項
      // 批次新增依照數字
      batchCreateByNumber: {
        startNumber: 1,
        endNumber: 10,
        betweenNumber: 1,
        insertFirstText: "",
        insertEndText: "",
        paddingDigits: 0,
      },
      batchCreateByNumberResult: [],
      // 常用款式
      commonStyle: '',
      // 已上傳圖片完的結果
      upLoadPicturesData: [],
      selectPictures: [],
      sellectAllPicturesSwitch: false, // 全選圖片開關
      // 同步社團/粉專的商品進賣場
      whitchFbTypeToSync: 'syncFbGroupMerchIntoStore',
      timeScope: {
        startTime: 0,
        endTime: 0,
      },
      filterOwner: true,
      // setAttribute
      setAttributeData: {
        checkSetReady: false,
        setReady: false,
        checkCanCheckOut: false,
        setCheckOut: false,
        checkSellState: false,
        setSellState: 'CANPREORDER',
        checkSetVipId: false,
        setVipId: '',
        checkSetLabel: false,
        setLabel: '',
        checkSetNumber: false,
        setNumber: '',
        checkSetStartSaleQuantity: false,
        setStartSaleQuantity: 0,
        checkSetStorageLocation: false,
        setStorageLocation: '',
        checkDeadLineTime: false,
        switchDeadLineTime: false,
        setDeadLineTime: '',
        checkArrivalTime: false,
        switchArrivalTime: false,
        setArrivalTime: '',
      },
      errorData: [],
      // 複製到的賣場
      cloneToStore: '',
      // 刪除/恢復商品
      merchStatus: 'delete',
      // 設定預設供應商
      setD4SupplierId: '',
      // 批次新增商品優惠
      batchDiscountsData: {
        storeVipId: '',
        minQuantity: 0,
        maxQuantity: 0,
        selectDiscountType: 'setOriginPrice',
        setOwnPrice: 0,
        percent: 0,
        reducePrice: 0
      },
      // FB 自動同步訂單設定
      FBAutoCreateOrder: {
        group: false,
        page: false
      },
      // 發布至 FB 社團/粉專
      postToFbForWhere: 'group',
      postToFbData: {
        img: '',
        description: '',
        rules: '',
        checkRules: true,
        checkAutoCreateOrder: true,
      },
      // 過濾 FB 貼文連結的資料
      filterFbLink: {
        showType: 'link',
        fbType: 'fbGroup',
        forGroup: [],
        forPage: [],
        finalString: '',
      },
      // 加入 line 推播列表
      toLinePushData: {
        lineBotData: {},
        serverToken: '',
        propsSelectInfos: {},
        openModal: false
      },
      // 發佈到貨通知
      postArrivalToNotify: {
        allocatedStatus: '未配單',
        checkoutStatus: '未結單',
        postTo: [],
        sellerMessage: ''
      },
      // 發佈到貨通知的篩選選項
      selectPostStatus: [],
      // 同步 FB 社團/粉專方式
      syncStype: 'syncOwnSelect',
      // live 直播
      livePushData: {
        jump: false,
        groupLinkIds: [],
        queues: [],
      },
      // 紀錄有幾個未處理留言商品
      unhandleFbMessages: [],
      // 進階搜尋
      advancedSearchData: {
        releaseTimeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        timeRange: {
          switch: false,
          startTime: null,
          endTime: null
        },
        deadLineTimeRange: {
          switch: false,
          startTime: null,
          endTime: null
        },
        arrivalTimeRange: {
          switch: false,
          startTime: null,
          endTime: null
        },
        merchandiseName: '',
        merchandiseLabel: '',
        vip: null,
        supplier: null,
        startSaleQuantity: 0,
        merchClassifications: [],
        singleSelect: {
          deletedStatus: '未刪除',
          sellStateStatus: '不選擇',
          orderGoodStatus: '不選擇',
          fbLinkStatus: '不選擇'
        }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  mixins: [MerchandiseStylesMap, HandleImages, SplitGraph, Permission],
  created() {
    this.initialization()
    console.log('取得偏好開始')
    this.getPrefer()
    console.log('取得偏好結束')
    this.getStoreInfo()
  },
  mounted() {
    this.createModals([
      'advancedSearchModal', 'setVipModal', 'setLabelModal', 'setNumberModal',
      'setStartSaleQuantityModal', 'setStorageLocationModal', 'setDeadLineTimeModal',
      'setArrivalTimeModal', 'setAllAttributeModal', 'createMerchandiseModal', 'setMerchClassificationModal',
      'cloneToStoreModal', 'updateMerchStatusModal', 'setD4SupplierModal',
      'batchAddDiscountsModal', 'uploadPicturesModal', 'setAutoCreateOrderFromFbModal', 'exportExcelModal',
      'postToFbModal', 'createFbPostLinksModal', 'postTheArrivalToNotifyModal','syncFbMerchIntoStoreModal',
      'setUnHandleMessageModal', 'syncFbMessagesModal','introductionModal', 'toLivePushModal', 'batchHandleFbMessagesModal',
      'downloadExtentionModal'
      ])
    this.createOffcanvas(['batchAddStylesCanvas'])
    this.createTabs(['sizeTab', 'colorTab', 'numberTab', 'commonStylesTab'])
    this.changeTableHeigth()
    // 監聽視窗異動
    window.addEventListener("resize", this.changeTableHeigth)
  },
  unmounted() {
    // 移除監聽視窗異動
    window.removeEventListener("resize", this.changeTableHeigth)
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-merchandise-local')
    this.$methods.breakOff()
  },
  computed: {
    ...mapState('StoreList', {
      ownerStores: state => state.ownerStores,
    }),
    // 偏好 > loading
    p_loading() {
      return this.$store.state.Prefer.p_loading
    },
    // 偏好 > 表格預設顯示筆數
    D4Row() {
      let D4Row = 20
      this.$store.state.Prefer.p_merchandise.rowCount.forEach(item => {
        if (item.selected) D4Row = item.value
      })
      return parseInt(D4Row)
    },
    // 偏好 > 進階搜尋預設時間區間
    timeRange() {
      console.log('取得預設時間區間')
      return parseInt(this.$store.state.Prefer.p_merchandise.searchTimeRange[0].value)
    },
    // 偏好 > line 推播預設文字
    linePushD4Text() {
      return this.$store.state.Prefer.p_merchandise.linePushD4Text[0].value
    },
    // 偏好 > 常用款式
    commonStyles() {
      return this.$store.state.Prefer.p_commonStyles.commonStyles
    },
    // 偏好 > 表格欄位
    fieldFilter() {
      return this.$store.state.Prefer.p_merchandise.fieldFilter
    },
    // 偏好 > 發布 FB 下單連結預設位置
    placeOnOrderUrlPosition() {
      let placeOnOrderUrlPosition = null
      this.$store.state.Prefer.p_fb.placeOnOrderUrlPosition.forEach(item => {
        if (item.selected) placeOnOrderUrlPosition = item.value
      })
      return placeOnOrderUrlPosition
    },
  },
  watch: {
    // 發布貼文連結，選擇發佈到哪裡
    'filterFbLink.fbType'() {
      this.combineFbPostLink()
    },
    // 發布貼文連結，選擇內容呈現方式
    'filterFbLink.showType'(val) {
      this.combineFbPostLink()
    },
    // 監聽批次新增 > 依照數字
    batchCreateByNumber: {
      handler(val) {
        this.batchCreateByNumberResult = [];
        let array = [];
        let startNumber = val.startNumber;
        let endNumber = val.endNumber;
        let betweenNumber = val.betweenNumber ? val.betweenNumber : 1;
        let insertZero = "00000";
        // 先將起始數字加入陣列
        array.push(startNumber);
        // 再來加入數字間距將後續數字加入陣列內
        let index = 0
        while (startNumber < endNumber) {
          index ++
          startNumber += betweenNumber;
          array.push(this.$methods.numberToFixed(startNumber));
          console.log(`index: ${index}`)
          if (index > 30) break
        }
        console.log(array);
        array.forEach((item) => {
          // 判斷陣列內數字有沒有大於結束數字
          if (item <= endNumber) {
            let currentData = item.toString();
            // 補齊位數
            if (currentData.length < val.paddingDigits) {
              currentData = (
                currentData.slice(0, 1) +
                insertZero.slice(0, val.paddingDigits - currentData.length) +
                currentData
              ).slice("1");
            }
            // 插入字首
            currentData = (
              currentData.slice(0, 1) +
              val.insertFirstText +
              currentData
            ).slice(1);
            // 插入字尾
            currentData += val.insertEndText;
            this.batchCreateByNumberResult.push(currentData);
          }
        });
        // 判斷陣列長度，最大 20 筆
        if (this.batchCreateByNumberResult.length > 20) {
          this.batchCreateByNumberResult = [];
          this.batchCreateByNumberResult.push("筆數不得超過 20 筆!");
        }
      },
      deep: true,
    },
    // 監聽切換供應商時，值都清空
    'createMerchandiseData.supplierStatus'(val) {
      if (val) {
        this.createMerchandiseData.D4Supllier = {
          name: '',
          cost: 0,
        }
        this.createMerchandiseData.setOwnSupplier = {
          name: '',
          cost: 0,
        }
      }
    },
    // 監聽供應商成本值，若有修改就帶入款式成本
    'createMerchandiseData.D4Supllier.cost' (val) {
      const supplierCost = val ? val : ''
      this.createMerchandiseData.mainStyles.forEach(item => {
        item.cost = supplierCost
      })
      this.createMerchandiseData.setMainStylesCost = supplierCost
    },
    'createMerchandiseData.setOwnSupplier.cost'(val) {
      const supplierCost = val ? val : ''
      this.createMerchandiseData.mainStyles.forEach(item => {
        item.cost = supplierCost
      })
      this.createMerchandiseData.setMainStylesCost = supplierCost
    },
    // 監聽批次設定商品細節的上下架跟收單時間的關係
    'setAttributeData.checkSetReady'(val) {
      this.checkReady()
    },
    'setAttributeData.setReady'(val) {
      this.checkReady()
    },
  },
  methods: {
    // 初始化
    initialization() {
      console.log(this.$route)
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 介紹表格可點選區寬度
      this.scrollWidth = `max-width: ${window.innerWidth - 20}px`
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-merchandise-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'deletedStatus',
          key: '刪除狀態',
          value: [
            {
              id: 'A1',
              value: '未刪除'
            },
            {
              id: 'A2',
              value: '已刪除'
            },
            {
              id: 'A3',
              value: '全部'
            },
          ]
        },
        {
          id: 'sellStateStatus',
          key: '販售狀態',
          value: [
            {
              id: 'B1',
              value: '不選擇'
            },
            {
              id: 'B2',
              value: '可預購'
            },
            {
              id: 'B3',
              value: '僅供現貨'
            },
            {
              id: 'B4',
              value: '限量'
            },
          ]
        },
        {
          id: 'orderGoodStatus',
          key: '叫貨狀態',
          value: [
            {
              id: 'C1',
              value: '不選擇'
            },
            {
              id: 'C2',
              value: '已處理'
            },
            {
              id: 'C3',
              value: '已處理(!)'
            },
            {
              id: 'C4',
              value: '待入庫'
            },
            {
              id: 'C5',
              value: '需訂'
            },
            {
              id: 'C6',
              value: '-'
            },
          ]
        },
        {
          id: 'fbLinkStatus',
          key: 'FB關聯',
          value: [
            {
              id: 'D1',
              value: '不選擇'
            },
            {
              id: 'D2',
              value: '有社團'
            },
            {
              id: 'D3',
              value: '有粉專'
            },
            {
              id: 'D4',
              value: '兩者皆有'
            }
          ]
        },
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // dataTable 內容高度計算
    changeTableHeigth() {
      // 紀錄 dataTabel 高度 (40px: 最上面 marginTop, 170px: 表格中除了內容其餘多的)
      this.scrollHeight = window.innerHeight - document.getElementById('MerchandiseTopArea').clientHeight - 160
    },
    // 開啟 modal
    showModal(status, item) {
      this.setAttributeData = {
        checkSetReady: false,
        setReady: false,
        checkCanCheckOut: false,
        setCheckOut: false,
        checkSellState: false,
        setSellState: 'CANPREORDER',
        checkSetVipId: false,
        setVipId: '',
        checkSetLabel: false,
        setLabel: '',
        checkSetNumber: false,
        setNumber: '',
        checkSetStartSaleQuantity: false,
        setStartSaleQuantity: 0,
        checkSetStorageLocation: false,
        setStorageLocation: '',
        checkDeadLineTime: false,
        switchDeadLineTime: false,
        setDeadLineTime: '',
        checkArrivalTime: false,
        switchArrivalTime: false,
        setArrivalTime: '',
      }
      if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      } else if (status === 'createMerchandise') {
        // 新增商品
        this.createMerchandiseData = {
          storeId: 0,
          name: '',
          description: '',
          storeVipId: '',
          ready: true,
          sellState: 'CANPREORDER',
          number: '',
          label: '',
          startSaleQuantity: 0,
          checkDeadLineTime: false,
          deadLineTime: '',
          storageLocation: '',
          checkOut: true,
          note: '',
          checkArrivalTime: false,
          arrivalTime: '',
          merchClassificationIds: [],
          supplierStatus: '不選擇供應商',
          D4Supllier: {
            name: '',
            cost: 0,
          },
          setOwnSupplier: {
            name: '',
            cost: 0,
          },
          setmainStyls: '',
          setMainStylsPrice: '',
          setMainStylesCost: '',
          setoneStyles: '',
          settwoStyles: '',
          setthreeStyles: '',
          setfourStyles: '',
          mainStyles: [],
          oneStyles: [],
          twoStyles: [],
          threeStyles: [],
          fourStyles: []
        }
        this.createMerchandiseData.mainStyles.push({
          id: new Date() * 1,
          hierarchy: 0,
          name: '一個',
          cost: 0,
          price: 0
        })
        this.createMerchandiseModal.show()
      } else if (status === 'uploadPictures') {
        // 上傳圖片
        this.getSelectInfo(item)
        this.upLoadPicturesData = []
        this.sellectAllPicturesSwitch = false
        // item.merchandisePictureLinks 若是在新增商品時，回傳的資料沒有，就不用做了
        if (item.merchandisePictureLinks) {
          item.merchandisePictureLinks.forEach(img => {
            this.upLoadPicturesData.push(img)
          })
        }
        this.selectPictures = []
        this.uploadPicturesModal.show()
      } else if (status === 'syncFbMerchIntoStore') {
        // 同步 FB 商品進系統並建立
        // 檢查是否綁定社團/粉專
        if (this.storeFb.loading) return this.SweetAlert('other', '請稍後~正在為您檢查是否綁定FB社團或粉專')
        if (!this.storeFb.group && !this.storeFb.page) return this.SweetAlert('other', '您尚未在此賣場綁定FB社團或粉專')
        this.timeScope.endTime = this.$methods.moment().format('YYYY-MM-DD')
        this.timeScope.startTime = this.$methods.moment().subtract(7, 'days').format('YYYY-MM-DD')
        this.filterOwner = true
        this.whitchFbTypeToSync = 'syncFbGroupMerchIntoStore'
        this.syncFbMerchIntoStoreModal.show()
      } else if (status === 'setVip') {
        // 設定商品 VIP
        this.setAttributeData.setVipId = item.storeVipId ? item.storeVipId : ''
        // 準備 modal 內選擇幾筆資訊
        this.getSelectInfo(item)
        this.setVipModal.show()
      } else if (status === 'setLabel') {
        // 設定商品標籤
        this.setAttributeData.setLabel = item.label
        // 準備 modal 內選擇幾筆資訊
        this.getSelectInfo(item)
        this.setLabelModal.show()
      } else if (status === 'setNumber') {
        // 設定商品編號
        this.setAttributeData.setNumber = item.number
        // 準備 modal 內選擇幾筆資訊
        this.getSelectInfo(item)
        this.setNumberModal.show()
      } else if (status === 'setStartSaleQuantity') {
        // 設定成團數
        this.setAttributeData.setStartSaleQuantity = item.startSaleQuantity
        // 準備 modal 內選擇幾筆資訊
        this.getSelectInfo(item)
        this.setStartSaleQuantityModal.show()
      } else if (status === 'setStorageLocation') {
        // 設定倉儲位置
        this.setAttributeData.setStorageLocation = item.storageLocation
        this.getSelectInfo(item)
        this.setStorageLocationModal.show()
      } else if (status === 'setDeadLineTime') {
        // 更新收單時間
        if (item.deadLineTime) {
          this.setAttributeData.switchDeadLineTime = true
          this.setAttributeData.setDeadLineTime = this.$methods.moment(item.deadLineTime).format('YYYY-MM-DDTHH:mm')
        }
        this.getSelectInfo(item)
        this.setDeadLineTimeModal.show()
      } else if (status === 'setArrivalTime') {
        // 更新到貨時間
        if (item.arrivalTime) {
          this.setAttributeData.switchArrivalTime = true
          this.setAttributeData.setArrivalTime = this.$methods.moment(item.arrivalTime).format('YYYY-MM-DDTHH:mm')
        }
        this.getSelectInfo(item)
        this.setArrivalTimeModal.show()
      } else if (status === 'setMerchClassification') {
        // 更新商品分類
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        this.propsSelectInfos.data = this.selectItems
        this.createMerchandiseData.merchClassificationIds = []
        this.setMerchClassificationModal.show()
      } else if (status === 'setAllAtribute') {
        // 設定商品細節
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        this.propsSelectInfos.data = this.selectItems
        this.setAllAttributeModal.show()
      } else if (status === 'cloneToStore') {
        // 複製商品
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        if (this.ownerStores.loading) return this.SweetAlert('other', '請稍後~正在為您準備賣場列表')
        let array = []
        this.selectItems.forEach((item, index) => {
          if (index <= 4) array.push(item)
        })
        this.selectItems = array
        this.propsSelectInfos.data = this.selectItems
        this.ownerStores.data.forEach(item => {
          if (this.storeId == item.storeId) this.cloneToStore = item.storeId
        })
        this.cloneToStoreModal.show()
      } else if (status === 'updateMerchStatus') {
        // 刪除商品
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        this.merchStatus = item
        this.propsSelectInfos.data = this.selectItems
        this.updateMerchStatusModal.show()
      } else if (status === 'batchCheckOut')  {
        // 批次結單
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        this.propsSelectInfos.data = this.selectItems
        // 傳遞到子層開啟 modal
        this.showBatchCheckOutModal = true
      } else if (status === 'setD4Supplier') {
        // 設定預設供應商
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        this.propsSelectInfos.data = this.selectItems
        this.setD4SupplierModal.show()
      } else if (status === 'batchAddDiscounts') {
        // 批次新增商品優惠
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        this.propsSelectInfos.data = this.selectItems
        this.batchDiscountsData = {
          storeVipId: '',
          minQuantity: 0,
          maxQuantity: 0,
          selectDiscountType: 'setOriginPrice',
          setOwnPrice: 0,
          percent: 0,
          reducePrice: 0
        }
        this.batchAddDiscountsModal.show()
      } else if (status === 'setUnHandleMessage') {
        // 未處理留言設定
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        this.propsSelectInfos.data = this.selectItems
        this.setUnHandleMessageModal.show()
      } else if (status === 'setAutoCreateOrderFromFb') {
        // 設定商品的貼文是否從fb留言自動建立訂單
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        this.propsSelectInfos.data = this.selectItems
        this.FBAutoCreateOrder = {
          group: false,
          page: false
        }
        this.setAutoCreateOrderFromFbModal.show()
      } else if (status === 'exportExcel') {
        // 匯出 excel
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        this.propsSelectInfos.data = this.selectItems
        this.exportExcelModal.show()
      } else if (status === 'postToFb') {
        // 發布至 FB 社團/粉專
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        // 檢查是否綁定社團/粉專
        if (this.storeFb.loading) return this.SweetAlert('other', '請稍後~正在為您檢查是否綁定FB社團或粉專')
        if (item === 'group' && !this.storeFb.group) return this.SweetAlert('other', '您尚未在此賣場綁定FB社團')
        if (item === 'page' && !this.storeFb.page) return this.SweetAlert('other', '您尚未在此賣場綁定FB粉專')
        let array = [this.selectItems[0]]
        this.selectItems = array
        this.propsSelectInfos.data = this.selectItems
        this.postToFbData = {
          img: '',
          description: '',
          rules: '',
          checkRules: true,
          checkAutoCreateOrder: true,
        }
        // 組商品描述與款式規則
        this.combineMerchInfo()
        this.postToFbForWhere = item
        this.postToFbModal.show()
      } else if (status === 'createFbPostLinks') {
        // 產生 FB 貼文連結
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        // 檢查是否綁定社團/粉專
        if (this.storeFb.loading) return this.SweetAlert('other', '請稍後~正在為您檢查是否綁定FB社團或粉專')
        if (!this.storeFb.group && !this.storeFb.page) return this.SweetAlert('other', '您尚未在此賣場綁定FB社團或粉專')
        this.filterFbLink = {
          showType: 'link',
          fbType: 'fbGroup',
          forGroup: [],
          forPage: [],
          finalString: ''
        }
        let array = []
        // 過濾有 FB 貼文連結的商品(社團，粉專分開放)
        this.selectItems.forEach(item => {
          if (item.merchFbFeedObject.FbGroupLink.id) {
            this.filterFbLink.forGroup.push(item)
            array.push(item)
          }
          if (item.merchFbFeedObject.FbPageLink.id) {
            this.filterFbLink.forPage.push(item)
            array.push(item)
          }
        })
        if (array.length === 0) return this.SweetAlert('other', '您所選擇的商品皆未發布過FB社團或粉專')
        this.propsSelectInfos.data = this.selectItems
        this.combineFbPostLink()
        this.createFbPostLinksModal.show()
      } else if (status === 'toLinePush') {
        // 至 Line 待推播列表
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        if (this.lineBotData.loading) return this.SweetAlert('other', '請稍後~正在為您檢查賣場是否綁定Line Bot')
        if (!this.lineBotData.loading && this.lineBotData.data.length === 0) return this.SweetAlert('other', '您的賣場尚未綁定任何Line Bot')
        this.propsSelectInfos.data = this.selectItems
        // 傳遞到子層開啟 modal
        this.showLinePushModal = true
      } else if (status === 'postToLineNotify') {
        // 發布至 line notify
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        if (this.storeLineNotify.loading) return this.SweetAlert('other', '請稍後~正在為您檢查是否綁定Line Notify')
        if (!this.storeLineNotify.loading && this.storeLineNotify.bindStore.length === 0 && !this.storeLineNotify.bindMyself) return this.SweetAlert('other', '您尚未綁定任何Line Notify')
        this.propsSelectInfos.data = this.selectItems
        this.showLineNotifyModal = true
      } else if (status === 'postTheArrivalToNotify') {
        // 發佈到或通知
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        this.propsSelectInfos.data = this.selectItems
        this.selectPostStatus = [
          {
            id: 'allocatedStatus',
            key: '配單狀態',
            value: [
              {
                id: 'A1',
                value: '未配單'
              },
              {
                id: 'A2',
                value: '部分配單'
              },
              {
                id: 'A3',
                value: '完全配單'
              },
            ]
          },
          {
          id: 'checkoutStatus',
          key: '結單狀態',
          value: [
            {
              id: 'A1',
              value: '未結單'
            },
            {
              id: 'A2',
              value: '部分結單'
            },
            {
              id: 'A3',
              value: '完全結單'
            },
          ]
        },
        ]
        this.postArrivalToNotify = {
          allocatedStatus: '未配單',
          checkoutStatus: '未結單',
          postTo: [],
          sellerMessage: ''
        }
        this.postTheArrivalToNotifyModal.show()
      } else if (status === 'syncFbMessages') {
        // 同步 FB 社團/粉專留言
        if (this.storeFb.loading) return this.SweetAlert('other', '請稍後~正在為您檢查是否綁定FB社團或粉專')
        if (item === 'group' && !this.storeFb.group) return this.SweetAlert('other', '您尚未在此賣場綁定FB社團')
        if (item === 'page' && !this.storeFb.page) return this.SweetAlert('other', '您尚未在此賣場綁定FB粉專')
        this.postToFbForWhere = item  // 借用一下變數
        this.syncStype = 'syncOwnSelect'
        this.selectItems = this.selectItems.filter((item, index) => { if (index < 20) return item })
        this.propsSelectInfos.data = this.selectItems
        this.syncFbMessagesModal.show()
      } else if (status === 'addOrderBySeller') {
        // 代客加單
        this.getSelectInfo(item)
        this.showAddOrderBySeller = true
      } else if (status === 'toLivePush') {
        // 加入直播列表
        this.livePushData = {
          jump: false,
          groupLinkIds: [],
          queues: [],
        }
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆商品')
        if (this.lineBotData.loading) return this.SweetAlert('other', '請稍後~正在為您檢查賣場是否綁定Line Bot')
        if (!this.lineBotData.loading && this.lineBotData.data.length === 0) return this.SweetAlert('other', '您的賣場尚未綁定任何Line Bot')
        this.propsSelectInfos.data = this.selectItems
        this.sortoutBatchStyles()
        this.toLivePushModal.show()
      } else if (status === 'batchHandleFbMessages') {
        // 批次處理 FB 未處理留言
        if (this.progressData.percent > 0 && this.progressData.percent < 100) return this.SweetAlert('other', '請等待商品表格進度條完成')
        // 過濾出未處理留言商品
        this.$methods.switchLoading('show')
        this.unhandleFbMessages = []
        this.unhandleFbMessages = this.merchandiseList.filter(item => { return item.unhandledFbCommentQuantity > 0 })
        if (this.unhandleFbMessages.length === 0) {
          this.$methods.switchLoading('hide')
          return this.SweetAlert('other', '目前表格上的商品皆未有未處理留言')
        }
        this.propsSelectInfos.data = this.unhandleFbMessages
        setTimeout(() => {
          this.$methods.switchLoading('hide')
          this.batchHandleFbMessagesModal.show()
        }, 200)
      } else if (status === 'downloadExtention') {
        // 下載擴充
        this.downloadExtentionModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreVips,getMerchClassifications,getSuppliers,getStoreFbGroupLinks,getStoreFbPageLinks,getStoreLineNotifyLinks,getOwner{getUser{getUserLineLink}},getStoreLineGroupLinks{getSummary}}'
        }
      ]
      this.storeInfo = {}
      this.storeVips = {
        loading: true,
        data: []
      }
      this.merchClassifications = {
        loading: true,
        data: []
      }
      this.suppliers = {
        loading: true,
        data: []
      }
      this.storeFb = {
        loading: true,
        group: null,
        page: null,
      }
      this.storeLineNotify = {
        loading: true,
        bindStore: [],
        bindMyself: null
      }
      this.lineBotData = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            // storeVip
            vm.storeVip = storeInfo.storeVipId ? storeInfo.storeVipId : 0
            vm.storeVips = {
              loading: false,
              data: storeInfo.storeVips
            }
            // 商品分類
            storeInfo.merchClassifications.push({
              id: 0,
              name: '未分類'
            })
            vm.merchClassifications = {
              loading: false,
              data: storeInfo.merchClassifications
            }
            // 供應商
            vm.suppliers = {
              loading: false,
              data: storeInfo.suppliers
            }
            // 賣場綁定社團
            if (storeInfo.storeFbGroupLinks.length > 0) {
              storeInfo.storeFbGroupLinks.some(item => {
                if (!item.deleted) {
                  vm.storeFb.group = item
                  return true
                }
              })
            }
            // 賣場綁定群組
            if (storeInfo.storeFbPageLinks.length > 0) {
              storeInfo.storeFbPageLinks.some(item => {
                if (!item.deleted) {
                  vm.storeFb.page = item
                  return true
                }
              })
            }
            vm.storeFb.loading = false
            // 賣場綁定 line Notify 與 自己是否綁定
            vm.storeLineNotify = {
              loading: false,
              bindStore: storeInfo.storeLineNotifyLinks,
              bindMyself: storeInfo.owner.user.userLineLink.notifyAccessToken ? storeInfo.owner : null
            }
            // 賣場綁定 line bot
            storeInfo.storeLineGroupLinks.forEach(item => {
              item.summary.linkId = item.id
              if (!item.deleted) vm.lineBotData.data.push(item.summary)
            })
            vm.lineBotData.loading = false
            // 先取得預設時間區間
            vm.computedD4TimeRange()
            vm.advancedSearch()
            vm.getAllParticipants()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得賣場所有顧客
    getAllParticipants() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getParticipants{getUser}}'
        }
      ]
      this.participants = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.participants = {
              loading: false,
              data: JSON.parse(JSON.stringify(storeInfo.participants))
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // * 更新資料 (start)
    /**
     * 更新資料
     * @updateItem 要更新的物件
     * @modal 需要關閉的 modal
     */
    updateData(updateItem, modal) {
      this.$methods.switchLoading('show')
      let data = []
      let ids = []
      this.selectItems.forEach(merchandise => { ids.push(merchandise.id) })
      switch (updateItem) {
        case 'merchandise':
          data = [{
            ids: ids,
            type: 16,
            methods: '{getMerchFbFeedLinks,getMerchandisePictureLinks,getMerchClassificationLinks{getMerchClassification},getStoreVip,getMerchOrders{getShippingQuantity,getCheckOutQuantity,getBuyer{getUser},getMerchandiseStyles},getMerchandiseSupplierLinks{getSupplier},getStocks{getOutOfStockQuantity,getPurchasedQuantity},getUnhandledFbCommentQuantity,getMerchandiseStyles{getMerchandiseDiscounts{getStoreVip}}}'
          }]
          break;
        case 'merchandisePictureLinks':
          data = [{
            ids: ids,
            type: 16,
            methods: '{getMerchandisePictureLinks}'
          }]
          break;
        case 'merchClassifications':
          data = [{
            ids: ids,
            type: 16,
            methods: '{getMerchClassificationLinks{getMerchClassification}}'
          }]
          break;
        case 'setAllAttribute':
          data = [{
            ids: ids,
            type: 16,
            methods: '{getStoreVip}'
          }]
          break;
        case 'merchandiseSupplierLinks':
          data = [{
            ids: ids,
            type: 16,
            methods: '{getMerchandiseSupplierLinks{getSupplier}}'
          }]
          break;
        case 'merchandiseStyles':
          data = [{
            ids: ids,
            type: 16,
            methods: '{getMerchandiseStyles{getMerchandiseDiscounts{getStoreVip}}}'
          }]
          break;
        case 'unhandledFbCommentQuantity':
          data = [{
            ids: ids,
            type: 16,
            methods: '{getUnhandledFbCommentQuantity}'
          }]
          break;
        case 'merchFbFeedLinks':
          data = [{
            ids: ids,
            type: 16,
            methods: '{getMerchFbFeedLinks}'
          }]
          break;
        case 'merchOrdersAndStocks':
          data = [{
            ids: ids,
            type: 16,
            methods: '{getMerchOrders{getShippingQuantity,getCheckOutQuantity,getBuyer{getUser},getMerchandiseStyles},getStocks{getOutOfStockQuantity,getPurchasedQuantity}}'
          }]
          break;
        default:
          throw new Error('merchandise.vue > updateData > updateItem 未處理')
      }
      // 取要更新的資料
      const updateData = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      };
      const vm = this
      $.ajax({
        type: "POST",
        async: true,
        url: updateData,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            const merchandises = res.data[0].objects
            vm.sortoutUpdateData(merchandises, updateItem, modal)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 整理更新資料
    sortoutUpdateData(merchandises, updateItem, modal) {
      if (modal === 'createMerchandiseModal') {
        const object = this.handleMerchandise(merchandises[0])
        this.merchandiseList.push(object)
        this.createMerchandiseModal.hide()
        this.showModal('uploadPictures', merchandises[0])
      } else {
        this.merchandiseList.forEach(origin => {
          merchandises.forEach(update => {
            if (origin.id === update.id) {
              switch (updateItem) {
                case 'merchandisePictureLinks':
                  origin.merchandisePictureLinks = update.merchandisePictureLinks
                  break;
                case 'merchClassifications':
                  origin.merchClassifications = []
                  origin.merchClassifications = this.handleMerchClassifications(update.merchClassificationLinks)
                  break;
                case 'setAllAttribute':
                  origin.ready = update.ready
                  origin.checkOut = update.checkOut
                  origin.sellState = update.sellState
                  origin.storeVipId = update.storeVipId
                  origin.storeVipName = update.storeVipId ? update.storeVip.name : null
                  origin.label = update.label
                  origin.number = update.number
                  origin.startSaleQuantity = update.startSaleQuantity
                  origin.storageLocation = update.storageLocation
                  origin.deadLineTime = update.deadLineTime ? this.$methods.moment(update.deadLineTime).format("YYYY-MM-DD HH:mm:ss") : null,
                  origin.arrivalTime = update.arrivalTime ? this.$methods.moment(update.arrivalTime).format("YYYY-MM-DD HH:mm:ss") : null
                  break;
                case 'merchandiseSupplierLinks':
                  const handleSupplierResult = this.handleSupplier(update.merchandiseSupplierLinks)
                  origin.supplierD4Link = handleSupplierResult.supplierD4Link
                  origin.merchandiseSupplierLinks = handleSupplierResult.supplierArray
                  break;
                case 'merchandiseStyles':
                  origin.merchandiseStyles = update.merchandiseStyles
                  break;
                case 'unhandledFbCommentQuantity':
                  origin.unhandledFbCommentQuantity = update.unhandledFbCommentQuantity
                  break;
                case 'merchFbFeedLinks':
                  const handleFbFeedsResult = this.handleMerchFbFeedLinks(update.merchFbFeedLinks)
                  origin.merchFbFeedObject = handleFbFeedsResult.fbInfo
                  origin.hasFbFeedLink = handleFbFeedsResult.hasFbFeedLink
                  break;
                case 'merchOrdersAndStocks':
                  const handleMerchOrdersInfoResult = this.handleMerchOrdersInfo(update.merchOrders)
                  origin.merchOrderQuantity = handleMerchOrdersInfoResult.merchOrderQuantity
                  origin.unAllocatedQuantity = handleMerchOrdersInfoResult.unAllocatedQuantity
                  const handleStocksInfoResult = this.handleStocksInfo(update.stocks, update.merchOrders)
                  origin.purchaseOrderState = handleStocksInfoResult.purchaseOrderState
                  origin.stockQuantity = handleStocksInfoResult.stockQuantity
                  break;
                default:
                  throw new Error('merchandise.vue > sortoutUpdateData > updateItem 未處理')
              }
            }
          })
        })
        this.SweetAlert('200')
      }
      if (modal && modal !== 'createMerchandiseModal') {
        this[modal].hide()
        this.selectItems = []
      }
      this.$methods.switchLoading('hide')
    },
    // * 更新資料 (end)
    // * 取商品 (start)
    // 計算預設時間區間
    computedD4TimeRange() {
      console.log('計算預設時間區間')
      this.advancedSearchData.releaseTimeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.releaseTimeRange.startTime = this.$methods.moment().subtract(this.timeRange, 'days').format('YYYY-MM-DD')
    },
    // 常用搜尋
    frequentlySearched(searchStatus) {
      // 紀錄要變更的單選項目
      let singleSelectOption = null
      // 還原所有單選選項預設值
      this.advancedSearchData.singleSelect.sellStateStatus = '不選擇'
      this.advancedSearchData.singleSelect.deletedStatus = '未刪除'
      this.advancedSearchData.singleSelect.orderGoodStatus = '不選擇'
      // 判斷是哪個單選項目要變更
      if (searchStatus === '可預購' || searchStatus === '僅供現貨' || searchStatus === '限量') singleSelectOption = 'sellStateStatus'
      else if (searchStatus === '已刪除') singleSelectOption = 'deletedStatus'
      else if (searchStatus === '待入庫' || searchStatus === '需訂') singleSelectOption = 'orderGoodStatus'
      // 判斷是否有未處理到的項目，提醒工程師用
      if (!singleSelectOption) return alert('沒有此搜尋選項')
      this.advancedSearchData.singleSelect[singleSelectOption] = searchStatus
      this.advancedSearch()
    },
    // 進階搜尋
    advancedSearch(closeModal) {
      // 先中止 call API 跟同步動作
      this.$methods.breakOff([this._abortSortOutMerchandises])
      // 執行進階搜尋步驟
      this.selectItems = []
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      let query = {"columns":[{"columnName":"id","siteObjectType":16},{"columnName":"createTime","siteObjectType":16}],"distinct":true,"group":{"groups":[{"column":{"columnName":"StoreId","siteObjectType":16},"operator":{"operator":"=","type":1,"value":this.storeId}}],"operator":1},"joins":[],"orders":[{"asc":false,"column":{"columnName":"createTime","siteObjectType":16}}],"siteObjectType":16,"sqlCommandType":1}
      // 上架區間
      if (this.advancedSearchData.releaseTimeRange.switch) {
        if (this.advancedSearchData.releaseTimeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.releaseTimeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'releaseTime',
              siteObjectType: 16
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.releaseTimeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.releaseTimeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'releaseTime',
              siteObjectType: 16
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 16
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 16
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 收單時間
      if (this.advancedSearchData.deadLineTimeRange.switch) {
        if (this.advancedSearchData.deadLineTimeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.deadLineTimeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'deadLineTime',
              siteObjectType: 16
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.deadLineTimeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.deadLineTimeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'deadLineTime',
              siteObjectType: 16
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 到貨時間
      if (this.advancedSearchData.arrivalTimeRange.switch) {
        if (this.advancedSearchData.arrivalTimeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.arrivalTimeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'arrivalTime',
              siteObjectType: 16
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.arrivalTimeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.arrivalTimeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'arrivalTime',
              siteObjectType: 16
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 商品名稱
      if (this.advancedSearchData.merchandiseName) {
        query.group.groups.push({
          column: {
            columnName: "name",
            siteObjectType: 16,
          },
          operator: {
            operator: "like",
            type: 1,
            value: `%${this.advancedSearchData.merchandiseName}%`,
          },
        })
      }
      // 商品標籤
      if (this.advancedSearchData.merchandiseLabel) {
        query.group.groups.push({
          column: {
            columnName: "label",
            siteObjectType: 16,
          },
          operator: {
            operator: "like",
            type: 1,
            value: `%${this.advancedSearchData.merchandiseLabel}%`,
          },
        })
      }
      // VIP
      if (this.advancedSearchData.vip) {
        query.group.groups.push({
          column: {
            columnName: "storeVipId",
            siteObjectType: 16,
          },
          operator: {
            operator: "=",
            type: 1,
            value: this.advancedSearchData.vip.id,
          },
        })
      }
      // 成團數
      if (this.advancedSearchData.startSaleQuantity > 0) {
        query.group.groups.push({
          column: {
            columnName: "startSaleQuantity",
            siteObjectType: 16,
          },
          operator: {
            operator: "=",
            type: 1,
            value: this.advancedSearchData.startSaleQuantity,
          },
        })
      }
      // 刪除狀態
      switch(this.advancedSearchData.singleSelect.deletedStatus) {
        case '未刪除':
          query.group.groups.push({
            column: {
              columnName: "deleted",
              siteObjectType: 16,
            },
            operator: {
              operator: "=",
              type: 1,
              value: false,
            },
          })
          break;
        case '已刪除':
          query.group.groups.push({
            column: {
              columnName: "deleted",
              siteObjectType: 16,
            },
            operator: {
              operator: "=",
              type: 1,
              value: true,
            },
          })
          break;
      }
      // 販售狀態
      switch(this.advancedSearchData.singleSelect.sellStateStatus) {
        case '可預購':
          query.group.groups.push({
            column: {
              columnName: "sellState",
              siteObjectType: 16,
            },
            operator: {
              operator: "=",
              type: 1,
              value: '1',
            },
          })
          break;
        case '僅供現貨':
          query.group.groups.push({
            column: {
              columnName: "sellState",
              siteObjectType: 16,
            },
            operator: {
              operator: "=",
              type: 1,
              value: 2,
            },
          })
          break;
        case '限量':
          query.group.groups.push({
            column: {
              columnName: "sellState",
              siteObjectType: 16,
            },
            operator: {
              operator: "=",
              type: 1,
              value: 3,
            },
          })
          break;
      }
      if (closeModal) this.advancedSearchModal.hide()
      this.getMerchandiseList(query)
    },
    // 取得商品
    getMerchandiseList(query) {
      const vm = this
      const getMerchandiseListApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getMerchFbFeedLinks,getMerchandisePictureLinks,getMerchClassificationLinks{getMerchClassification},getStoreVip,getMerchOrders{getShippingQuantity,getCheckOutQuantity,getBuyer{getUser},getMerchandiseStyles},getMerchandiseSupplierLinks{getSupplier},getStocks{getOutOfStockQuantity,getPurchasedQuantity},getUnhandledFbCommentQuantity,getMerchandiseStyles{getMerchandiseDiscounts{getStoreVip}}}',
          pagination: {
            limit: 100
          }
        }
      ]
      this.merchandiseList = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandiseListApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseList = res.data[0].objects
            const paging = res.data[0].paging
            vm.merchandiseMap(merchandiseList, paging)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理商品列表
    merchandiseMap(merchandiseList, paging) {
      this._abortSortOutMerchandises = setTimeout(() => {
        merchandiseList.forEach((item) => {
          const object = this.handleMerchandise(item)

          // * 進階搜尋
          // 進階搜尋 > 供應商
          let matchSupplier = false
          if ((this.advancedSearchData.supplier && object.D4SupplierId === this.advancedSearchData.supplier.id) || !this.advancedSearchData.supplier) matchSupplier = true
          else matchSupplier = false

          // 進階搜尋 > 分類
          let matchCategory = false
          if (this.advancedSearchData.merchClassifications.length > 0) {
            // 先檢查是否為未分類
            let checkIsNoneCategory = false
            checkIsNoneCategory = this.advancedSearchData.merchClassifications.some(categoryId => {
              return categoryId === 0
            })
            if (!checkIsNoneCategory) {
              matchCategory = this.advancedSearchData.merchClassifications.some(categoryId => {
                if (item.merchClassificationLinks.length > 0) {
                  return item.merchClassificationLinks.some(originCagory => {
                    return originCagory.merchClassificationId === categoryId
                  })
                }
              })
            } else {
              matchCategory = item.merchClassificationLinks.length === 0
            }
          } else {
            matchCategory = true
          }

          // 進階搜尋 > 叫貨狀態
          let matchPurchaseOrderState = false
          if ((this.advancedSearchData.singleSelect.orderGoodStatus === object.purchaseOrderState) || this.advancedSearchData.singleSelect.orderGoodStatus === '不選擇') matchPurchaseOrderState = true
          else matchPurchaseOrderState = false 
          
          // 進階搜尋 > FB關聯狀態 
          let matchFbLinkStatus = false
          switch (this.advancedSearchData.singleSelect.fbLinkStatus) {
            case '不選擇':
              matchFbLinkStatus = true
              break;
            case '有社團':
              if (object.merchFbFeedObject.FbGroupLink.id) matchFbLinkStatus = true
              break;
            case '有粉專':
              if (object.merchFbFeedObject.FbPageLink.id) matchFbLinkStatus = true
              break;
            case '兩者皆有':
              if (object.merchFbFeedObject.FbGroupLink.id && object.merchFbFeedObject.FbPageLink.id) matchFbLinkStatus = true
              break;
          }
          
          if (matchSupplier && matchCategory && matchPurchaseOrderState && matchFbLinkStatus) this.merchandiseList.push(JSON.parse(JSON.stringify(object)))
        })
        // 處理分頁
        if (paging.current !== paging.total) {
          this.$api.getNextSiteObjects(paging.next).then(res => {
            const result = res.data[0].objects
            const paging = res.data[0].paging
            this.merchandiseMap(result, paging)
          }).catch(err => {
            console.log(err)
            this.SweetAlert('other', err.responseJSON.message)
          })
        }
        this.progressData.percent = this.$methods.handleProgressBar(paging.current, paging.total)
        this.dataTabelLoading = false
      }, 500)
      this.$methods.switchLoading('hide')
    },
    // 特別處理商品物件
    handleMerchandise(merchandise) {
      // 原資料
      const object = {
        arrivalTime: merchandise.arrivalTime ? this.$methods.moment(merchandise.arrivalTime).format("YYYY-MM-DD HH:mm:ss") : null,
        checkOut: merchandise.checkOut,
        createTime: this.$methods.moment(merchandise.createTime).format("YYYY-MM-DD HH:mm:ss"),
        deadLineTime: merchandise.deadLineTime ? this.$methods.moment(merchandise.deadLineTime).format("YYYY-MM-DD HH:mm:ss") : null,
        deleted: merchandise.deleted,
        description: merchandise.description,
        id: merchandise.id,
        label: merchandise.label,
        merchClassifications: [],
        merchFbFeedLinks: merchandise.merchFbFeedLinks,
        merchFbFeedObject: {},
        hasFbFeedLink: false,
        merchOrders: merchandise.merchOrders,
        merchOrderQuantity: 0,
        merchandisePictureLinks: merchandise.merchandisePictureLinks,
        merchandiseStyles: merchandise.merchandiseStyles,
        merchandiseSupplierLinks: merchandise.merchandiseSupplierLinks,
        name: merchandise.name,
        note: merchandise.note,
        number: merchandise.number,
        purchaseOrderState: '-',
        ready: merchandise.ready,
        releaseTime: merchandise.releaseTime ? this.$methods.moment(merchandise.releaseTime).format("YYYY-MM-DD HH:mm:ss") : null,
        sellState: merchandise.sellState,
        startSaleQuantity: merchandise.startSaleQuantity,
        stocks: merchandise.stocks,
        stockQuantity: 0,
        storageLocation: merchandise.storageLocation,
        storeId: merchandise.storeId,
        storeVipName: merchandise.storeVipId ? merchandise.storeVip.name : null,
        storeVipId: merchandise.storeVipId,
        supplierD4Link: '-',
        D4SupplierId: 0,
        unAllocatedQuantity: 0,
        unhandledFbCommentQuantity: merchandise.unhandledFbCommentQuantity,
      }
      // * 需特別處理

      // 商品分類
      object.merchClassifications = this.handleMerchClassifications(merchandise.merchClassificationLinks)
      
      // 供應商
      const handleSupplierResult = this.handleSupplier(merchandise.merchandiseSupplierLinks)
      object.supplierD4Link = handleSupplierResult.supplierD4Link
      object.D4SupplierId = handleSupplierResult.D4SupplierId
      object.merchandiseSupplierLinks = handleSupplierResult.supplierArray
      
      // 訂單數量，未配數量
      const handleMerchOrdersInfoResult = this.handleMerchOrdersInfo(merchandise.merchOrders)
      object.merchOrderQuantity = handleMerchOrdersInfoResult.merchOrderQuantity
      object.unAllocatedQuantity = handleMerchOrdersInfoResult.unAllocatedQuantity

      // FB 貼文連結
      const handleFbFeedsResult = this.handleMerchFbFeedLinks(merchandise.merchFbFeedLinks)
      object.merchFbFeedObject = handleFbFeedsResult.fbInfo
      object.hasFbFeedLink = handleFbFeedsResult.hasFbFeedLink

      // 叫貨狀態，庫存數量
      const handleStocksInfoResult = this.handleStocksInfo(merchandise.stocks, merchandise.merchOrders)
      object.purchaseOrderState = handleStocksInfoResult.purchaseOrderState
      object.stockQuantity = handleStocksInfoResult.stockQuantity

      return object
    },
    // 特別處理商品分類
    handleMerchClassifications(merchClassificationLinks) {
      let result = []
      merchClassificationLinks.forEach(item => {
        result.push({
          id: item.merchClassificationId,
          linkId: item.id,
          name: item.merchClassification.name
        })
      })
      return result
    },
    // 特別處理供應商
    handleSupplier(merchandiseSupplierLinks) {
      let result = {
        supplierD4Link: null,
        D4SupplierId: 0,
        supplierArray: []
      }
      merchandiseSupplierLinks.forEach(supplierLink => {
        if (supplierLink.preset) {
          result.supplierD4Link = supplierLink.supplier.name
          result.D4SupplierId = supplierLink.supplier.id
        }
        const supplierObject = {
          cost: supplierLink.cost,
          id: supplierLink.id,
          merchandiseId: supplierLink.merchandiseId,
          preset: supplierLink.preset,
          name: supplierLink.supplier.name,
          supplierId: supplierLink.supplierId
        }
        result.supplierArray.push(supplierObject)
      })
      return result
    },
    // 特別處理訂單資訊
    handleMerchOrdersInfo(merchOrders) {
      let result = {
        merchOrderQuantity: 0,
        unAllocatedQuantity: 0
      }
      merchOrders.forEach(merchOrder => {
        result.merchOrderQuantity += merchOrder.quantity - merchOrder.shippingQuantity
        result.unAllocatedQuantity += merchOrder.quantity - merchOrder.allocatedQuantity
      })
      return result
    },
    // 特別處理 FB 貼文連結
    handleMerchFbFeedLinks(merchFbFeedLinks) {
      let result = {
        fbInfo: {
          FbGroupLink: {},
          FbPageLink: {},
        },
        hasFbFeedLink: false,
      }
      const now = new Date() * 1
      merchFbFeedLinks.forEach(merchFbFeedLink => {
        if (merchFbFeedLink.latest && merchFbFeedLink.linkType === 'STOREFBGROUPLINK') {
          // 社團
          merchFbFeedLink.days = Math.floor(Math.abs(parseInt(merchFbFeedLink.createTime - now) / 1000 / 3600 / 24))
          result.fbInfo.FbGroupLink = merchFbFeedLink
          result.hasFbFeedLink = true
        } else if (merchFbFeedLink.latest && merchFbFeedLink.linkType === 'STOREFBPAGELINK') {
          // 粉專
          merchFbFeedLink.days = Math.floor(Math.abs(parseInt(merchFbFeedLink.createTime - now) / 1000 / 3600 / 24))
          result.fbInfo.FbPageLink = merchFbFeedLink
          result.hasFbFeedLink = true
        }
      })
      return result
    },
    // 特別處理庫存資訊
    handleStocksInfo(stocks, merchOrders) {
      let result = {
        outOfStockQuantity: 0,  // 缺貨總數
        purchasedQuantity: 0,   // 已叫數量
        stockQuantity: 0,       // 庫存數量
        purchaseOrderState: '-',// 叫貨狀態
      }
      stocks.forEach(stock => {
        result.outOfStockQuantity += stock.outOfStockQuantity
        result.purchasedQuantity += stock.purchasedQuantity
        result.stockQuantity += stock.quantity
      })
      console.log(result)
      if (merchOrders.length > 0) {
        if (result.outOfStockQuantity > 0) {
          result.purchaseOrderState = '需訂'
          if (result.purchasedQuantity >= result.outOfStockQuantity) result.purchaseOrderState = '待入庫'
        } else {
          if (result.purchasedQuantity > 0) result.purchaseOrderState = '已處理(!)'
          else result.purchaseOrderState = '已處理'
        }
      } else {
        result.purchaseOrderState = '-'
      }
      return result
    },
    // * 取商品 (end)
    // 取得選取到的資訊
    getSelectInfo(item) {
      // 準備 modal 內選擇幾筆資訊
      this.selectItems = []
      this.propsSelectInfos.data = []
      this.selectItems.push(item)
      this.propsSelectInfos.data = this.selectItems
    },
    // 新增商品 > 款式細項欄位"新增"
    createStyleInput(status) {
      // 每次點擊都讓按鈕消失 500ms，這樣我款式內的 id 才不會有重複的可能
      const vm = this
      this.showAddStyleBtn = false
      setTimeout(() => {
        vm.showAddStyleBtn = true
      }, 500)
      // 新增款式細項
      if (!this.createMerchandiseData[`set${status}`]) return this.SweetAlert('other', '您尚未輸入任何東西')
      let styles = this.createMerchandiseData[`set${status}`].replace(/\s*/g, "").split("，")
      const error = styles.some(item => { return item.length > 20})
      if (error) return this.SweetAlert('other', '您所輸入的款式中，其中有超過20字')
      styles.forEach((item, index) => {
        if (status === 'mainStyls' && item) {
          this.createMerchandiseData.mainStyles.push({
            id: new Date() * 1 * index,
            hierarchy: 0,
            name: item,
            cost: this.createMerchandiseData.setMainStylesCost ? this.createMerchandiseData.setMainStylesCost : 0,
            price: this.createMerchandiseData.setMainStylsPrice ? this.createMerchandiseData.setMainStylsPrice : 0
          })
        } else if (status === 'oneStyles' && item) {
          this.createMerchandiseData.oneStyles.push({
            id: new Date() * 1 * index,
            hierarchy: 1,
            name: item
          })
        } else if (status === 'twoStyles' && item) {
          this.createMerchandiseData.twoStyles.push({
            id: new Date() * 1 * index,
            hierarchy: 2,
            name: item
          })
        } else if (status === 'threeStyles' && item) {
          this.createMerchandiseData.threeStyles.push({
            id: new Date() * 1 * index,
            hierarchy: 3,
            name: item
          })
        } else if (status === 'fourStyles' && item) {
          this.createMerchandiseData.fourStyles.push({
            id: new Date() * 1 * index,
            hierarchy: 4,
            name: item
          })
        }
      })
      if (status === 'mainStyls') {
        let cost = 0
        if (this.createMerchandiseData.D4Supllier.cost) cost = this.createMerchandiseData.D4Supllier.cost
        if (this.createMerchandiseData.setOwnSupplier.cost) cost = this.createMerchandiseData.setOwnSupplier.cost
        this.createMerchandiseData.setMainStylesCost = cost ? cost : 0
        this.createMerchandiseData.setMainStylsPrice = ''
      }
      this.createMerchandiseData[`set${status}`] = ''
    },
    // 新增商品 > 款式細項欄位"刪除"
    delStyleInput(status, id) {
      // 刪除款式細項
      if (status === 'main') {
        if (this.createMerchandiseData.mainStyles.length === 1) return this.SweetAlert('other', '至少要有一筆主要款式')
        let index = 0
        this.createMerchandiseData.mainStyles.forEach((item, i) => {
          if (item.id === id) index = i
        })
        this.createMerchandiseData.mainStyles.splice(index, 1)
      } else if (status === 'one') {
        if (this.createMerchandiseData.twoStyles.length > 0 && this.createMerchandiseData.oneStyles.length === 1) return this.SweetAlert('other', '細項二有東西，細項一不能完全刪除')
        let index = 0
        this.createMerchandiseData.oneStyles.forEach((item, i) => {
          if (item.id === id) index = i
        })
        this.createMerchandiseData.oneStyles.splice(index, 1)
      } else if (status === 'two') {
        if (this.createMerchandiseData.threeStyles.length > 0 && this.createMerchandiseData.twoStyles.length === 1) return this.SweetAlert('other', '細項三有東西，細項二不能完全刪除')
        let index = 0
        this.createMerchandiseData.twoStyles.forEach((item, i) => {
          if (item.id === id) index = i
        })
        this.createMerchandiseData.twoStyles.splice(index, 1)
      } else if (status === 'three') {
        if (this.createMerchandiseData.fourStyles.length > 0 && this.createMerchandiseData.threeStyles.length === 1) return this.SweetAlert('other', '細項四有東西，細項三不能完全刪除')
        let index = 0
        this.createMerchandiseData.threeStyles.forEach((item, i) => {
          if (item.id === id) index = i
        })
        this.createMerchandiseData.threeStyles.splice(index, 1)
      } else if (status === 'four') {
        let index = 0
        this.createMerchandiseData.fourStyles.forEach((item, i) => {
          if (item.id === id) index = i
        })
        this.createMerchandiseData.fourStyles.splice(index, 1)
      }
    },
    // 新增商品 > 開啟批次新增系統預設款式
    showBatchStyle(status) {
      this.batchStyleCurrentStatus = 'color'
      this.colorTab.show()
      this.D4SystemStyles = {
        selectColors: [],
        selectSizes: [],
      }
      // 判斷點開批次新增按鈕之後，結果要存在細項幾
      this.batchStyleForWhitchStyle = status
    },
    // 新增商品 > 檢查目前批次新增系統預設款式的狀態
    setBatchStyleStatus(status) {
      this.batchStyleCurrentStatus = status
      this.D4SystemStyles = {
        selectColors: [],
        selectSizes: [],
      }
      this.batchCreateByNumberResult = []
      this.batchCreateByNumber = {
        startNumber: 1,
        endNumber: 10,
        betweenNumber: 1,
        insertFirstText: "",
        insertEndText: "",
        paddingDigits: 0,
      }
      this.commonStyle = ''
    },
    // 新增商品 > 批次新增細項
    createBatchStyles() {
      console.log(this.batchStyleForWhitchStyle, this.batchStyleCurrentStatus)
      let hierarchy = 1
      switch (this.batchStyleForWhitchStyle) {
        case 'oneStyles':
          hierarchy = 1
          break;
        case 'twoStyles':
          hierarchy = 2
          break;
        case 'threeStyles':
          hierarchy = 3
          break;
        case 'fourStyles':
          hierarchy = 4
          break;
      }
      this.createMerchandiseData.setmainStyls = ''
      if (this.batchStyleCurrentStatus === 'color') {
        if (this.D4SystemStyles.selectColors.length === 0) return this.SweetAlert('other', '請至少選擇一筆預設款式')
        this.D4SystemStyles.selectColors.sort((a, b) => {
          return a.index - b.index
        })
        this.D4SystemStyles.selectColors.forEach((item, index, arr) => {
          let object = {
            id: new Date() * 1 * index,
            hierarchy: hierarchy,
            name: item.value
          }
          // 如果是主要款式的話，不直接新增到欄位內，而是新增到輸入框內
          if (this.batchStyleForWhitchStyle === 'mainStyles') {
            this.createMerchandiseData.setmainStyls += item.value
            if (index !== arr.length - 1) this.createMerchandiseData.setmainStyls += '，'
          } else this.createMerchandiseData[this.batchStyleForWhitchStyle].push(object)
        })
      } else if (this.batchStyleCurrentStatus === 'size') {
        if (this.D4SystemStyles.selectSizes.length === 0) return this.SweetAlert('other', '請至少選擇一筆預設款式')
        this.D4SystemStyles.selectSizes.sort((a, b) => {
          return a.index - b.index
        })
        this.D4SystemStyles.selectSizes.forEach((item, index, arr) => {
          let object = {
            id: new Date() * 1 * index,
            hierarchy: hierarchy,
            name: item.value
          }
          // 如果是主要款式的話，不直接新增到欄位內，而是新增到輸入框內
          if (this.batchStyleForWhitchStyle === 'mainStyles') {
            this.createMerchandiseData.setmainStyls += item.value
            if (index !== arr.length - 1) this.createMerchandiseData.setmainStyls += '，'
          } else this.createMerchandiseData[this.batchStyleForWhitchStyle].push(object)
        })
      } else if (this.batchStyleCurrentStatus === 'number') {
        const error = this.batchCreateByNumberResult.some(item => {
          return item.length > 20
        })
        if (error) return this.SweetAlert('other', '您所組的款式字數超過20字')
        this.batchCreateByNumberResult.forEach((item, index, arr) => {
          let object = {
            id: new Date() * 1 * index,
            hierarchy: hierarchy,
            name: item
          }
          // 如果是主要款式的話，不直接新增到欄位內，而是新增到輸入框內
          if (this.batchStyleForWhitchStyle === 'mainStyles') {
            this.createMerchandiseData.setmainStyls += item
            if (index !== arr.length - 1) this.createMerchandiseData.setmainStyls += '，'
          } else this.createMerchandiseData[this.batchStyleForWhitchStyle].push(object)
        })
      } else if (this.batchStyleCurrentStatus === 'commonStyles') {
        if (!this.commonStyle) return this.SweetAlert('other', '請至少選擇一筆常用款式')
        const array = this.commonStyle.replace(/\s*/g, "").split('，')
        array.forEach((item, index, arr) => {
          let object = {
            id: new Date() * 1 * index,
            hierarchy: hierarchy,
            name: item
          }
          // 如果是主要款式的話，不直接新增到欄位內，而是新增到輸入框內
          if (this.batchStyleForWhitchStyle === 'mainStyles') {
            this.createMerchandiseData.setmainStyls += item
            if (index !== arr.length - 1) this.createMerchandiseData.setmainStyls += '，'
          } else this.createMerchandiseData[this.batchStyleForWhitchStyle].push(object)
        })
      }
      this.batchAddStylesCanvas.hide()
    },
    // 加逗號
    addComma(where, recordString) {
      try {
        const el = document.querySelector(`#${where}`)
        const startCaret  = el.selectionStart // 起始游標位置
        const endCaret = el.selectionEnd // 結束游標位置
        console.log(!this.createMerchandiseData[recordString])
        if (!this.createMerchandiseData[recordString]) return
        this.createMerchandiseData[recordString] = this.createMerchandiseData[recordString].substring(0, endCaret) + '，' + this.createMerchandiseData[recordString].substring(endCaret)
      } catch (error) {
        console.log(error)
        alert('您所在的瀏覽器或您的裝置不支援此功能')
      }
    },
    // 新增商品
    createMerchandise:_.debounce(function() {
      this.$methods.switchLoading('show')
      console.log('開始建立商品')
      const vm = this;
      const createMerchandiseApi = `${process.env.VUE_APP_API}/merchandise/create`;
      const header = {
        authorization: this.serverToken
      };
      // 商品
      if (this.createMerchandiseData.checkDeadLineTime && !this.createMerchandiseData.deadLineTime) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '收單時間尚未點選時間')
      }
      if (this.createMerchandiseData.checkArrivalTime && !this.createMerchandiseData.arrivalTime) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '到貨時間尚未點選時間')
      }
      let merchandise = {
        storeId: this.storeId,
        name: this.createMerchandiseData.name,
        description: this.createMerchandiseData.description ? this.createMerchandiseData.description : null,
        storeVipId: this.createMerchandiseData.storeVipId ? this.createMerchandiseData.storeVipId : null,
        ready: this.createMerchandiseData.ready,
        sellState: this.createMerchandiseData.sellState,
        number: this.createMerchandiseData.number ? this.createMerchandiseData.number : null,
        label: this.createMerchandiseData.label ? this.createMerchandiseData.label : null,
        startSaleQuantity: this.createMerchandiseData.startSaleQuantity ? this.createMerchandiseData.startSaleQuantity : 0,
        deadLineTime: this.createMerchandiseData.checkDeadLineTime ? new Date(this.createMerchandiseData.deadLineTime) * 1 : null,
        storageLocation: this.createMerchandiseData.storageLocation ? this.createMerchandiseData.storageLocation : null,
        checkOut: this.createMerchandiseData.checkOut,
        note: this.createMerchandiseData.note,
        arrivalTime: this.createMerchandiseData.checkArrivalTime ? new Date(this.createMerchandiseData.arrivalTime) * 1 : null
      }
      // 款式/細項
      let styles = []
      // 主要款式
      if (this.createMerchandiseData.mainStyles.length > 0) {
        this.createMerchandiseData.mainStyles.forEach(item => {
          if (item.name) {
            styles.push({
              hierarchy: 0,
              name: item.name.replace(/\s*/g, ""),
              price: item.price ? item.price : 0,
              cost: item.cost ? item.cost : 0
            })
          }
        })
      }
      // 細項一
      if (this.createMerchandiseData.oneStyles.length > 0) {
        this.createMerchandiseData.oneStyles.forEach(item => {
          if (item.name) {
            styles.push({
              hierarchy: 1,
              name: item.name.replace(/\s*/g, "")
            })
          }
        })
      }
      // 細項二
      if (this.createMerchandiseData.twoStyles.length > 0) {
        const checkHasOne = styles.some(item => {
          return item.hierarchy === 1
        })
        if (checkHasOne) {
          this.createMerchandiseData.twoStyles.forEach(item => {
            if (item.name) {
              styles.push({
                hierarchy: 2,
                name: item.name.replace(/\s*/g, "")
              })
            }
          })
        } else {
          this.$methods.switchLoading('hide')
          return this.SweetAlert('other', '細項一尚未填寫')
        }
      }
      // 細項三
      if (this.createMerchandiseData.threeStyles.length > 0) {
        const checkHasTwo = styles.some(item => {
          return item.hierarchy === 2
        })
        if (checkHasTwo) {
          this.createMerchandiseData.threeStyles.forEach(item => {
            if (item.name) {
              styles.push({
                hierarchy: 3,
                name: item.name.replace(/\s*/g, "")
              })
            }
          })
        } else {
          this.$methods.switchLoading('hide')
          return this.SweetAlert('other', '細項二尚未填寫')
        }
      }
      // 細項四
      if (this.createMerchandiseData.fourStyles.length > 0) {
        const checkHasThree = styles.some(item => {
          return item.hierarchy === 3
        })
        if (checkHasThree) {
          this.createMerchandiseData.fourStyles.forEach(item => {
            if (item.name) {
              styles.push({
                hierarchy: 4,
                name: item.name.replace(/\s*/g, "")
              })
            }
          })
        } else {
          this.$methods.switchLoading('hide')
          return this.SweetAlert('other', '細項三尚未填寫')
        }
      }
      // 商品分類
      let merchClassificationIds = this.createMerchandiseData.merchClassificationIds
      // 供應商
      let supplierLinks = []
      if (this.createMerchandiseData.supplierStatus === '選擇現有供應商') {
        if (!this.createMerchandiseData.D4Supllier.name) {
          this.$methods.switchLoading('hide')
          return this.SweetAlert('other', '供應商名稱不能為空')
        }
        supplierLinks.push({
          name: this.createMerchandiseData.D4Supllier.name,
          cost: this.createMerchandiseData.D4Supllier.cost ? this.createMerchandiseData.D4Supllier.cost : 0,
          preset: true
        })
      } else if (this.createMerchandiseData.supplierStatus === '自行輸入供應商') {
        if (!this.createMerchandiseData.setOwnSupplier.name) {
          this.$methods.switchLoading('hide')
          return this.SweetAlert('other', '供應商名稱不能為空')
        }
        supplierLinks.push({
          name: this.createMerchandiseData.setOwnSupplier.name,
          cost: this.createMerchandiseData.setOwnSupplier.cost ? this.createMerchandiseData.setOwnSupplier.cost : 0,
          preset: true
        })
      }
      const merchInfo = [{
        merchandise: merchandise,
        styles: styles,
        merchClassificationIds: merchClassificationIds,
        supplierLinks: supplierLinks
      }]
      const data = new FormData()
      data.append('jsonString', JSON.stringify(merchInfo))
      $.ajax({
        type: "POST",
        async: true,
        url: createMerchandiseApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            const merchandise = res.data[0]
            vm.selectItems = []
            vm.selectItems.push(merchandise)
            vm.updateData('merchandise', 'createMerchandiseModal')
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    }, 2000, {
      leading: true, // 在延遲開始前，立即調用
      trailing: false, // 在延遲結束前，保證不再調用
    }),
    // * 編輯圖片 (start)
    // 上傳圖片
    uploadPictures(event) {
      console.log(event.files)
      this.$methods.switchLoading('show')
      const vm = this;
      const uploadPicturesApi = `${process.env.VUE_APP_API}/merchandise/createPictureLinks`;
      const header = {
        authorization: this.serverToken
      };
      const data = new FormData()
      if (this.handleImages(event.files) === '圖片副檔名有誤') {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '圖片副檔名有誤')
      }
      data.append('merchandiseId', this.selectItems[0].id)
      this.handleImages(event.files).forEach(item => {
        data.append('files', item.files)
      })
      $.ajax({
        type: 'POST',
        async: true,
        url: uploadPicturesApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const results = res.data
            vm.SweetAlert(res.code, res.message)
            results.forEach(item => {
              vm.upLoadPicturesData.push(item.link)
            })
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 照片設首圖
    setFrontPicture(picture, status) {
      this.$methods.switchLoading('show')
      const vm = this;
      const setFrontPictureApi = `${process.env.VUE_APP_API}/merchandise/setFrontPicture`;
      const header = {
        authorization: this.serverToken
      };
      const data = new FormData()
      data.append('linkId', picture.id)
      $.ajax({
        type: "PUT",
        async: true,
        url: setFrontPictureApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            if (status === 'closeMode') {
              // 上傳圖片的 "關閉" 按鈕
              vm.updateData('merchandisePictureLinks', 'uploadPicturesModal')
            } else if (status === 'finishMode') {
              // 上傳圖片 "完成" 按鈕
              vm.updateData('merchandisePictureLinks', 'uploadPicturesModal')
            } else {
              // 點 "首圖" 按鈕
              vm.upLoadPicturesData.forEach(img => {
                if (img.id === picture.id) {
                  img.front = true
                } else {
                  img.front = false
                }
              })
            }
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 上傳圖片 "關閉" 按鈕
    closeUploadPicture() {
      let hasFront = false
      this.upLoadPicturesData.forEach(item => {
        if (item.front) {
          hasFront = true
        }
      })
      if (!hasFront && this.upLoadPicturesData.length > 0) this.setFrontPicture(this.upLoadPicturesData[0], 'closeMode')
      else this.updateData('merchandisePictureLinks', 'uploadPicturesModal')
    },
    // 上傳圖片 "確認" 按鈕
    finishUploadPicture() {
      this.$methods.switchLoading('show')
      const vm = this;
      const saveSequenceApi = `${process.env.VUE_APP_API}/merchandise/updatePictureLinksSequence`;
      const header = {
        authorization: this.serverToken
      };
      // 若沒有圖片就直接關閉 modal
      if (this.upLoadPicturesData.length === 0) {
        this.updateData('merchandisePictureLinks', 'uploadPicturesModal')
        this.$methods.switchLoading('hide')
        return
      }
      // 若有圖片就檢查是否已經設首圖，並紀錄圖片順序
      let array = []
      let hasFront = false
      this.upLoadPicturesData.forEach(item => {
        array.push(item.id)
        if (item.front) {
          hasFront = true
        }
      })
      const data = new FormData()
      data.append('linkIds', array.toString())
      $.ajax({
        type: "PUT",
        async: true,
        url: saveSequenceApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            if (!hasFront) {
              vm.setFrontPicture(vm.upLoadPicturesData[0], 'finishMode')
            } else {
              vm.updateData('merchandisePictureLinks', 'uploadPicturesModal')
            }
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 刪除圖片
    delPictures(status, picture) {
      const vm = this;
      const delPicturesApi = `${process.env.VUE_APP_API}/merchandise/deletePictureLinks`;
      const header = {
        authorization: this.serverToken
      };
      if (status === 'single') {
        this.selectPictures = []
        this.selectPictures.push(picture) 
      }
      if (this.selectPictures.length === 0) return this.SweetAlert('other', '您尚未選擇要刪除的圖片')
      let ids = []
      this.selectPictures.forEach(item => ids.push(item.id))
      this.$methods.switchLoading('show')
      const data = new FormData()
      data.append('linkIds', ids.toString())
      $.ajax({
        type: "DELETE",
        async: true,
        url: delPicturesApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            let array = []
            vm.upLoadPicturesData.forEach(item => {
              const hasDeletePicture = vm.selectPictures.some(selectItem => {
                return item.id === selectItem.id 
              })
              if (!hasDeletePicture) array.push(item)
            })
            vm.upLoadPicturesData = array
            vm.selectPictures = []
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 全選圖片
    sellectAllPictures() {
      this.selectPictures = []
      this.sellectAllPicturesSwitch = !this.sellectAllPicturesSwitch
      if (this.sellectAllPicturesSwitch) {
        this.upLoadPicturesData.forEach(item => {
          if (!item.front) this.selectPictures.push(item)
        })
      }
    },
    // * 編輯圖片 (end)
    // 同步 FB 社團/粉專文章進賣場
    syncFbMerchIntoStore() {
      if (!this.timeScope.startTime) return this.SweetAlert('other', '您的起始時間尚未選擇')
      if (!this.timeScope.endTime) return this.SweetAlert('other', '您的結束時間尚未選擇')
      const data = [{
        storeId: this.storeId,
        communityType: this.whitchFbTypeToSync === 'syncFbGroupMerchIntoStore' ? 35 : 36,
        // 轉換時間格式 (兼容 IOS)
        timeScope: {
          since: this.$methods.convertTimeZone(`${this.timeScope.startTime} 00:00:00`),
          until: this.$methods.convertTimeZone(`${this.timeScope.endTime} 00:00:00`)
        },
        filterOwner: this.filterOwner
      }]
      console.log(data)
      window.open(`${process.env.VUE_APP_ORIGINURL}/seller/store/${this.storeId}/synchronizeFBArticle?data=${JSON.stringify(data)}`, "_blank");
      this.syncFbMerchIntoStoreModal.hide()
    },
    // 更新販售狀態
    updateSellState(item) {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateSellStateApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
      const header = {
        authorization: this.serverToken
      };
      let sellState = item.sellState
      if (sellState === 'CANPREORDER') {
        sellState = 'STOCKONLY'
      } else if (sellState === 'STOCKONLY') {
        sellState = 'LIMITED'
      } else if (sellState === 'LIMITED') {
        sellState = 'CANPREORDER'
      }
      const data = [
        {
          id: item.id,
          attributes: {
            setSellState: sellState
          }
        }
      ]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateSellStateApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.merchandiseList.some(merchandise => {
              if (merchandise.id === item.id) {
                merchandise.sellState = sellState
                return true
              }
            })
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 更新上下架
    updateReady(item) {
      if (item.deadLineTime) {
        const now = new Date() * 1
        // 轉換時間格式 (兼容 IOS)
        const deadLineTime = this.$methods.convertTimeZone(item.deadLineTime)
        if (deadLineTime <= now) return this.SweetAlert('other', '您的收單時間已經到期')
      }
      this.$methods.switchLoading('show')
      const vm = this;
      const updateReadyApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
      const header = {
        authorization: this.serverToken
      };
      console.log(item)
      const data = [
        {
          id: item.id,
          attributes: {
            setReady: !item.ready
          }
        }
      ]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateReadyApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.merchandiseList.some(merchandise => {
              if (merchandise.id === item.id) {
                merchandise.ready = !item.ready
                return true
              }
            })
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 更新顧客自行結單
    updateCanCheckOut(item) {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateCanCheckOutApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
      const header = {
        authorization: this.serverToken
      };
      const data = [
        {
          id: item.id,
          attributes: {
            setCheckOut: !item.checkOut
          }
        }
      ]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateCanCheckOutApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.merchandiseList.some(merchandise => {
              if (merchandise.id === item.id) {
                merchandise.checkOut = !item.checkOut
                return true
              }
            })
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 更新商品 VIP
    updateMerchandiseVip() {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateMerchandiseVipApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
      const header = {
        authorization: this.serverToken
      };
      const data = [
        {
          id: this.selectItems[0].id,
          attributes: {
            setStoreVipId: this.setAttributeData.setVipId ? this.setAttributeData.setVipId : null
          }
        }
      ]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateMerchandiseVipApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.merchandiseList.some(merchandise => {
              if (merchandise.id === vm.selectItems[0].id) {
                merchandise.storeVipId = res.data[0].storeVipId
                merchandise.storeVipName = res.data[0].storeVipId ? res.data[0].storeVip.name : null
                return true
              }
            })
            vm.setVipModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 更新標籤
    updateLabel() {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateLabelApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
      const header = {
        authorization: this.serverToken
      };
      const data = [
        {
          id: this.selectItems[0].id,
          attributes: {
            setLabel: this.setAttributeData.setLabel
          }
        }
      ]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateLabelApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.merchandiseList.some(merchandise => {
              if (merchandise.id === vm.selectItems[0].id) {
                merchandise.label = vm.setAttributeData.setLabel
                return true
              }
            })
            vm.setLabelModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 更新編號
    updateNumber() {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateNumberApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
      const header = {
        authorization: this.serverToken
      };
      const data = [
        {
          id: this.selectItems[0].id,
          attributes: {
            setNumber: this.setAttributeData.setNumber
          }
        }
      ]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateNumberApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.merchandiseList.some(merchandise => {
              if (merchandise.id === vm.selectItems[0].id) {
                merchandise.number = vm.setAttributeData.setNumber
                return true
              }
            })
            vm.setNumberModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 更新成團數
    updateStartSaleQuantity() {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateStartSaleQuantityApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
      const header = {
        authorization: this.serverToken
      };
      const data = [
        {
          id: this.selectItems[0].id,
          attributes: {
            setStartSaleQuantity: this.setAttributeData.setStartSaleQuantity
          }
        }
      ]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateStartSaleQuantityApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.merchandiseList.some(merchandise => {
              if (merchandise.id === vm.selectItems[0].id) {
                merchandise.startSaleQuantity = vm.setAttributeData.setStartSaleQuantity
                return true
              }
            })
            vm.setStartSaleQuantityModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 更新倉儲位置
    updateStorageLocation() {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateStorageLocationApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
      const header = {
        authorization: this.serverToken
      };
      const data = [
        {
          id: this.selectItems[0].id,
          attributes: {
            setStorageLocation: this.setAttributeData.setStorageLocation
          }
        }
      ]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateStorageLocationApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.merchandiseList.some(merchandise => {
              if (merchandise.id === vm.selectItems[0].id) {
                merchandise.storageLocation = vm.setAttributeData.setStorageLocation
                return true
              }
            })
            vm.setStorageLocationModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 更新收單時間
    updateDeadLineTime() {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateDeadLineTimeApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
      const header = {
        authorization: this.serverToken
      };
      let setDeadLineTime = ''
      if (this.setAttributeData.switchDeadLineTime) {
        setDeadLineTime = new Date(this.setAttributeData.setDeadLineTime) * 1
      } else {
        setDeadLineTime = null
      }
      const data = [
        {
          id: this.selectItems[0].id,
          attributes: {
            setDeadLineTime: setDeadLineTime
          }
        }
      ]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateDeadLineTimeApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.merchandiseList.some(merchandise => {
              if (merchandise.id === vm.selectItems[0].id) {
                if (vm.setAttributeData.switchDeadLineTime) {
                  merchandise.deadLineTime = vm.$methods.moment(new Date(vm.setAttributeData.setDeadLineTime) * 1).format('YYYY-MM-DD HH:mm:ss')
                } else {
                  merchandise.deadLineTime = null
                }
                return true
              }
            })
            vm.setDeadLineTimeModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 更新到貨時間
    updateArrivalTimeTime() {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateArrivalTimeTimeApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
      const header = {
        authorization: this.serverToken
      };
      let setArrivalTime = ''
      if (this.setAttributeData.switchArrivalTime) {
        setArrivalTime = new Date(this.setAttributeData.setArrivalTime) * 1
      } else {
        setArrivalTime = null
      }
      const data = [
        {
          id: this.selectItems[0].id,
          attributes: {
            setArrivalTime: setArrivalTime
          }
        }
      ]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateArrivalTimeTimeApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.merchandiseList.some(merchandise => {
              if (merchandise.id === vm.selectItems[0].id) {
                if (vm.setAttributeData.switchArrivalTime) {
                  merchandise.arrivalTime = vm.$methods.moment(new Date(vm.setAttributeData.setArrivalTime) * 1 * 1).format('YYYY-MM-DD HH:mm:ss')
                } else {
                  merchandise.arrivalTime = null
                }
                merchandise.storageLocation = vm.setAttributeData.setStorageLocation
                return true
              }
            })
            vm.setArrivalTimeModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 更新商品分類
    updateMerchClassifications() {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateMerchClassificationsApi = `${process.env.VUE_APP_API}/merchClassificationLink/updateMultipuleSelection`;
      const header = {
        authorization: this.serverToken
      };
      let data = []
      this.selectItems.forEach(item => {
        let merchClassificationsMap = new Map()
        // 原本就有選到的
        item.merchClassifications.forEach(originSelect => {
          merchClassificationsMap.set(originSelect.id, [{
            id: originSelect.id,
            linkId: originSelect.linkId,
          }])
        })
        // 後面選到的
        this.createMerchandiseData.merchClassificationIds.forEach(afterSelect => {
          if (!merchClassificationsMap.has(afterSelect)) {
            merchClassificationsMap.set(afterSelect, [{
              id: afterSelect,
              linkId: 0,
            }])
          } else {
            merchClassificationsMap.get(afterSelect).push({
              id: afterSelect,
              linkId: 0,
            })
          }
        })
        // 先處理單個商品
        for (const [key, value] of merchClassificationsMap) {
          // 如果列長度大於 1 的話，代表原先就有的分類但沒有要被刪除
          if (value.length === 1) {
            if (value[0].linkId === 0) {
              // 後來選的分類
              data.push({
                selected: true,
                merchandiseId: item.id,
                merchClassificationId: key
              })
            } else {
              // 原先就有的分類，但是後來要刪除的
              data.push({
                selected: false,
                linkId: value[0].linkId
              })
            }
          }
        }
      })
      console.log(data)
      if (data.length === 0) {
        this.$methods.switchLoading('hide')
        this.selectItems = []
        this.setMerchClassificationModal.hide()
        return 
      }
      $.ajax({
        type: "POST",
        async: true,
        url: updateMerchClassificationsApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.updateData('merchClassifications', 'setMerchClassificationModal')
            vm.selectItems = []
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 更新全部設定
    updateAttributes() {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateAttributesApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
      const header = {
        authorization: this.serverToken
      };
      let data = []
      let attributes = {}
      // 上下架
      if (this.setAttributeData.checkSetReady) {
        attributes.setReady = this.setAttributeData.setReady
      }
      // 顧客自行結單
      if (this.setAttributeData.checkCanCheckOut) {
        attributes.setCheckOut = this.setAttributeData.setCheckOut
      }
      // 販售狀態
      if (this.setAttributeData.checkSellState) {
        attributes.setSellState = this.setAttributeData.setSellState
      }
      // VIP
      if (this.setAttributeData.checkSetVipId) {
        attributes.setStoreVipId = this.setAttributeData.setVipId ? this.setAttributeData.setVipId : null
      }
      // 標籤
      if (this.setAttributeData.checkSetLabel) {
        attributes.setLabel = this.setAttributeData.setLabel 
      }
      // 編號
      if (this.setAttributeData.checkSetNumber) {
        attributes.setNumber = this.setAttributeData.setNumber
      }
      // 成團數
      if (this.setAttributeData.checkSetStartSaleQuantity) {
        attributes.setStartSaleQuantity = this.setAttributeData.setStartSaleQuantity ? this.setAttributeData.setStartSaleQuantity : 0
      }
      // 倉儲位置
      if (this.setAttributeData.checkSetStorageLocation) {
        attributes.setStorageLocation = this.setAttributeData.setStorageLocation
      }
      // 收單時間
      if (this.setAttributeData.checkDeadLineTime && this.setAttributeData.switchDeadLineTime) {
        attributes.setDeadLineTime = new Date(this.setAttributeData.setDeadLineTime) * 1
      } else if (this.setAttributeData.checkDeadLineTime && !this.setAttributeData.switchDeadLineTime) (
        attributes.setDeadLineTime = null
      )
      // 到貨時間
      if (this.setAttributeData.checkArrivalTime && this.setAttributeData.switchArrivalTime) {
        attributes.setArrivalTime = new Date(this.setAttributeData.setArrivalTime) * 1
      } else if (this.setAttributeData.checkArrivalTime && !this.setAttributeData.switchArrivalTime) {
        attributes.setArrivalTime = null
      }
      // 判斷是否有任何要設定的屬性
      console.log(attributes)
      if (JSON.stringify(attributes) === '{}') {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '請至少勾選一個要變更的商品細節')
      }
      this.selectItems.forEach(item => {
        const object = {
          id: item.id,
          attributes: attributes
        }
        data.push(object)
      })
      console.log(data)
      $.ajax({
        type: "PUT",
        async: true,
        url: updateAttributesApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.updateData('setAllAttribute', 'setAllAttributeModal')
            vm.selectItems = []
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 複製商品
    cloneMerchandiseToStore() {
      let merchandiseIds = []
      let storeId = this.cloneToStore
      const data = new FormData()
      this.selectItems.forEach(item => {
        merchandiseIds.push(item.id)
      })
      let ownerStoreIds = []
      this.ownerStores.data.forEach(item => {
        ownerStoreIds.push(item.storeId)
      })
      const copyMerchandiseUrl = `${process.env.VUE_APP_ORIGINURL}/seller/store/${storeId}/copyMerchandise?merchandiseIds=${merchandiseIds.toString()}&ownerStoreIds=${ownerStoreIds.toString()}`
      window.open(copyMerchandiseUrl, '_blank')
      this.cloneMerchToStoreModal.hide()
    },
    // 刪除/恢復商品
    updateMerchStatus() {
      this.$methods.switchLoading('show')
      const vm = this;
      const updateMerchStatusApi = `${process.env.VUE_APP_API}/merchandise/setDeleted`;
      const header = {
        authorization: this.serverToken
      };
      const data = new FormData()
      let merchandiseIdsArray = []
      this.selectItems.forEach(item => {
        merchandiseIdsArray.push(item.id)
      })
      data.append('merchandiseIds', merchandiseIdsArray.toString())
      data.append('deleted', this.merchStatus === 'delete' ? true : false)
      $.ajax({
        type: "PUT",
        async: true,
        url: updateMerchStatusApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            if (vm.merchStatus === 'delete') {
              vm.merchandiseList = vm.merchandiseList.filter(origin => {
                let deletedItemId = null
                vm.selectItems.some(select => {
                  if (origin.id === select.id) {
                    deletedItemId = select.id
                    return true
                  }
                })
                return origin.id !== deletedItemId
              })
            } else {
              vm.merchandiseList.forEach(origin => {
                let recoverId = null
                vm.selectItems.some(select => {
                  if (origin.id === select.id) {
                    recoverId = select.id
                    return true
                  }
                })
                if (recoverId === origin.id) origin.deleted = false
              })
            }
            vm.SweetAlert(res.code, res.message)
            vm.updateMerchStatusModal.hide()
            vm.selectItems = []
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 設定預設供應商
    updateD4Supplier() {
      if (!this.setD4SupplierId) return this.SweetAlert('other', '請選擇要預設的供應商')
      this.$methods.switchLoading('show')
      const vm = this;
      const updateD4SupplierApi = `${process.env.VUE_APP_API}/merchandise/setSupplier`;
      const header = {
        authorization: this.serverToken
      };
      const data = new FormData()
      let merchandiseIdsArray = []
      this.selectItems.forEach(item => {
        merchandiseIdsArray.push(item.id)
      })
      data.append('merchandiseIds', merchandiseIdsArray.toString())
      data.append('supplierId', this.setD4SupplierId)
      $.ajax({
        type: "PUT",
        async: true,
        url: updateD4SupplierApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.updateData('merchandiseSupplierLinks', 'setD4SupplierModal')
            vm.selectItems = []
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 批次新增商品優惠
    batchAddDiscounts(continueStatus) {
      this.$methods.switchLoading('show')
      const vm = this;
      const batchAddDiscountsApi = `${process.env.VUE_APP_API}/merchandiseDiscount/create`;
      const header = {
        authorization: this.serverToken
      };
      let data = []
      this.selectItems.forEach(item => {
        item.merchandiseStyles.some(style => {
          if (style.hierarchy > 0) return true
          let object = {
            merchandiseStyleId: style.id,
            price: 0,
            minQuantity: this.batchDiscountsData.minQuantity ? this.batchDiscountsData.minQuantity : null,
            maxQuantity: this.batchDiscountsData.maxQuantity ? this.batchDiscountsData.maxQuantity : null,
            storeVipId: this.batchDiscountsData.storeVipId ? this.batchDiscountsData.storeVipId : null 
          }
          console.log(style)
          switch (this.batchDiscountsData.selectDiscountType) {
            case 'setOriginPrice':
              object.price = style.price
              break;
            case 'setOwnPrice':
              object.price = this.batchDiscountsData.setOwnPrice ? this.batchDiscountsData.setOwnPrice : 0
              break;
            case 'reducePercent':
              const percent = this.batchDiscountsData.percent / 100
              object.price = Math.max(Math.round(style.price - (style.price * percent)), 0)
              break;
            case 'reducePrice':
              object.price = Math.max(style.price - this.batchDiscountsData.reducePrice, 0)
              break;
            case 'bothGetHigher':
              const percentA = this.batchDiscountsData.percent / 100
              const reducePercentA = Math.max(Math.round(style.price - (style.price * percentA)), 0)
              const reducePriceA = Math.max(style.price - this.batchDiscountsData.reducePrice, 0)
              reducePercentA >= reducePriceA ? object.price = reducePercentA : object.price = reducePriceA
              break;
            case 'bothGetLower':
              const percentB = this.batchDiscountsData.percent / 100
              const reducePercentB = Math.max(Math.round(style.price - (style.price * percentB)), 0)
              const reducePriceB = Math.max(style.price - this.batchDiscountsData.reducePrice, 0)
              reducePercentB >= reducePriceB ? object.price = reducePriceB : object.price = reducePercentB
              break;
          }
          data.push(object)
        })
      })
      console.log(data)
      $.ajax({
        type: "POST",
        async: true,
        url: batchAddDiscountsApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            if (continueStatus) {
              // 持續新增
              vm.batchDiscountsData = {
                storeVipId: '',
                minQuantity: 0,
                maxQuantity: 0,
                selectDiscountType: 'setOriginPrice',
                setOwnPrice: 0,
                percent: 0,
                reducePrice: 0
              }
              vm.updateData('merchandiseStyles')
            } else {
              vm.updateData('merchandiseStyles', 'batchAddDiscountsModal')
              vm.selectItems = []
            }
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 未處理留言設定
    setUnHandleMessage() {
      this.$methods.switchLoading('show')
      const vm = this;
      const setUnHandleMessageApi = `${process.env.VUE_APP_API}/apps/fb/setHandled`;
      const header = {
        authorization: this.serverToken
      };
      const data = new FormData()
      let ids = []
      this.selectItems.forEach(item => {
        ids.push(item.id)
      })
      data.append('merchandiseIds', ids.toString())
      data.append('handled', true)
      data.append('auto', false)
      $.ajax({
        type: "PUT",
        async: true,
        url: setUnHandleMessageApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.updateData('unhandledFbCommentQuantity', 'setUnHandleMessageModal')
            vm.selectItems = []
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 設定 FB 留言自動建立訂單
    setAutoCreateOrderFromFb() {
      this.$methods.switchLoading('show')
      const vm = this;
      const setAutoCreateOrderFromFbApi = `${process.env.VUE_APP_API}/merchandise/setAutoCreateMerchOrderFromFb`;
      const header = {
        authorization: this.serverToken
      };
      const data = new FormData()
      let feedLinkIds = []
      let autos = []
      this.selectItems.forEach(item => {
        if (item.merchFbFeedObject.FbGroupLink.id) {
          feedLinkIds.push(item.merchFbFeedObject.FbGroupLink.id)
          autos.push(this.FBAutoCreateOrder.group)
        }
        if (item.merchFbFeedObject.FbPageLink.id) {
          feedLinkIds.push(item.merchFbFeedObject.FbPageLink.id)
          autos.push(this.FBAutoCreateOrder.page)
        }
      })
      if (feedLinkIds.length === 0) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '您所選擇的商品皆尚未發過文章至FB社團或粉專')
      }
      data.append('feedLinkIds', feedLinkIds.toString())
      data.append('auto', autos.toString())
      $.ajax({
        type: "PUT",
        async: true,
        url: setAutoCreateOrderFromFbApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.updateData('merchFbFeedLinks', 'setAutoCreateOrderFromFbModal')
            vm.selectItems = []
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 匯出 excel
    exportExcel() {
      let ids = []
      this.selectItems.forEach(item => {
        ids.push(item.id)
      })
      const exportExcelUrl = `${process.env.VUE_APP_API}/download/exportDataTableExcel?authorization=${this.serverToken}&siteObjectTypeIndex=16&ids=${ids.toString()}`;
      window.open(exportExcelUrl, "_blank");
      this.exportExcelModal.hide()
    },
    // 組合商品描述與款式規則
    combineMerchInfo() {
      let array = []
      // 組資訊
      this.selectItems.forEach(item => {
        let object = {
          description: '',
          rules: '',
          imgUrl: ''
        }
        object.description += `📣${item.name}\n`
        // 下單連結
        const placeOnOrderUrl = `${process.env.VUE_APP_ORIGINURL}/customer/store/${this.storeId}/orderPage?merchandiseId=${item.id}`
        if (this.placeOnOrderUrlPosition === 'd4') object.description += `下單連結: ${placeOnOrderUrl}\n`
        // 收單時間
        if (item.deadLineTime) object.description += `收單時間: ${this.$methods.moment(item.deadLineTime).format("YYYY-MM-DD HH:mm:ss")}\n`
        // 到貨時間
        if (item.arrivalTime) object.description += `到貨時間: ${this.$methods.moment(item.arrivalTime).format("YYYY-MM-DD HH:mm:ss")}\n`
        // 成團數
        if (item.startSaleQuantity) object.description += `成團數: ${item.startSaleQuantity}人\n`
        // 描述
        let description = ''
        if (this.placeOnOrderUrlPosition === 'bottom') item.description += `\n下單連結: ${placeOnOrderUrl}\n`
        if (item.description) description += `\n${item.description}\n\n`

        // 發佈到 FB
        object.description += description
        
        // 組款式規則
        console.log(item.merchandiseStyles)
        let mainStyles = '👉主要款式:\n'
        let oneStyles = '👉細項一:\n\t'
        let twoStyles = '👉細項二:\n\t'
        let threeStyles = '👉細項三:\n\t'
        let fourStyles = '👉細項四:\n\t'
        let currentHierarchy = 0
        let orderRule = '✅喊單規則:'
        let combineStyles = ''
        item.merchandiseStyles.forEach((item, index, arr) => {
          if (item.hierarchy === 0 && !item.deleted) {
            mainStyles += `\t${item.name}(單價NT$${item.price}元)\n`
            if (item.merchandiseDiscounts.length > 0) {
              item.merchandiseDiscounts.forEach(discount => {
                // 判斷數量
                // if (discount.minQuantity && discount.maxQuantity) {
                //   mainStyles += `\t\t買${discount.minQuantity}~${discount.maxQuantity}個，單價NT$${discount.price}`
                // } else if (discount.minQuantity && !discount.maxQuantity) {
                //   mainStyles += `\t\t買${discount.minQuantity}個以上，單價NT$${discount.price}`
                // }
                // 判斷 VIP
                // if (discount.storeVipId) mainStyles += `(限制VIP: ${discount.storeVip.name}含以上)\n`
                // else mainStyles += '\n'
              })
            }
            currentHierarchy = item.hierarchy
            if (index === 0) combineStyles += item.name
          } else if (item.hierarchy === 1 && !item.deleted) {
            currentHierarchy = item.hierarchy
            oneStyles += `${item.name} `
            if (arr[index - 1].hierarchy === 0) combineStyles += `${item.name}`
          } else if (item.hierarchy === 2 && !item.deleted) {
            currentHierarchy = item.hierarchy
            twoStyles += `${item.name} `
            if (arr[index - 1].hierarchy === 1) combineStyles += `${item.name}`
          } else if (item.hierarchy === 3 && !item.deleted) {
            currentHierarchy = item.hierarchy
            threeStyles += `${item.name} `
            if (arr[index - 1].hierarchy === 2) combineStyles += `${item.name}`
          } else if (item.hierarchy === 4 && !item.deleted) {
            currentHierarchy = item.hierarchy
            fourStyles += `${item.name} `
            if (arr[index - 1].hierarchy === 3) combineStyles += `${item.name}`
          }
        })
        switch (currentHierarchy) {
          case 0:
            object.rules = mainStyles
            break;
          case 1:
            object.rules = mainStyles + oneStyles
            break;
          case 2:
            object.rules = mainStyles + oneStyles + '\n' + twoStyles
            break;
          case 3:
            object.rules = mainStyles + oneStyles + '\n' + twoStyles + '\n' + threeStyles
            break;
          case 4:
            object.rules = mainStyles + oneStyles + '\n' + twoStyles + '\n' + threeStyles + '\n' + fourStyles
            break;
        }

        object.rules += `\n\n${orderRule}\n1、${combineStyles} +1\n\xa0\xa0\xa0\xa0\xa0➥單筆訂單\n2、${combineStyles} +5\n\xa0\xa0\xa0\xa0\xa0${combineStyles} +10\n\xa0\xa0\xa0\xa0\xa0➥多筆訂單請用換行或逗號做分隔`

        // 組首圖
        item.merchandisePictureLinks.forEach(img => {
          if (img.front) object.imgUrl = `https://i.imgur.com/${img.imageHash}.jpg`
        })

        array.push(object)
      })
      console.log(array)
      // 發布 FB 所需的資訊
      this.postToFbData = {
        img: null,
        description: array[0].description,
        rules: array[0].rules,
        checkRules: true,
        checkAutoCreateOrder: true,
      }
      if (this.selectItems[0].merchandisePictureLinks.length > 0) {
        this.selectItems[0].merchandisePictureLinks.some(item => {
          if (item.front) {
            this.postToFbData.img = `https://i.imgur.com/${item.imageHash}.jpg`
            return true
          }
        })
      }
    },
    // 發布至 FB 社團/粉專
    postToFb() {
      this.$methods.switchLoading('show')
      const vm = this;
      const postToFbApi = `${process.env.VUE_APP_API}/apps/fb/publishToCommunity`;
      const header = {
        authorization: this.serverToken
      };
      let caption = ''
      if (this.postToFbData.description) {
        caption += `${this.postToFbData.description}\n`
      }
      if (this.postToFbData.rules && this.postToFbData.checkRules) {
        caption += this.postToFbData.rules
      }
      const data = [
        {
          merchandiseId: this.selectItems[0].id,
          groupLinkIds: this.postToFbForWhere === 'group' ? [this.storeFb.group.id] : [],
          pageLinkIds: this.postToFbForWhere === 'page' ? [this.storeFb.page.id] : [],
          action: this.postToFbData.img ? 'photos' : 'feed',
          autoCreateMerchOrderFromFb: this.postToFbData.checkAutoCreateOrder,
          feed: {}
        }
      ]
      if (this.postToFbData.img) {
        data[0].feed.url = this.postToFbData.img
        data[0].feed.caption = caption
      } else {
        data[0].feed.message = caption
      }
      $.ajax({
        type: "POST",
        async: true,
        url: postToFbApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.updateData('merchFbFeedLinks', 'postToFbModal')
            vm.selectItems = []
          } else {
            if (res.errorData[0].code === '400-14') vm.SweetAlert(res.code, '請檢查您的 FB 帳號是否已授權我們系統')
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 產生貼文連結
    combineFbPostLink() {
      let combineLink = ''
      if (this.filterFbLink.fbType === 'fbGroup') {
        this.filterFbLink.forGroup.forEach(item => {
          combineLink += `${item.name}\n${item.merchFbFeedObject.FbGroupLink.url}\n`
          if (this.filterFbLink.showType === 'hot') {
            combineLink += `🔥熱賣${item.merchOrders.length}單\n\n`
          } else if (this.filterFbLink.showType === 'deadLine') {
            combineLink += `收單時間: ${item.deadLineTime ? this.$methods.moment(item.deadLineTime).format("YYYY-MM-DD HH:mm:ss") : ''}\n\n`
          }
        })
      } else if (this.filterFbLink.fbType === 'fbPage') {
        this.filterFbLink.forPage.forEach(item => {
          combineLink += `${item.name}\n${item.merchFbFeedObject.FbPageLink.url}\n`
          if (this.filterFbLink.showType === 'hot') {
            combineLink += `🔥熱賣${item.merchOrders.length}單\n\n`
          } else if (this.filterFbLink.showType === 'deadLine') {
            combineLink += `收單時間: ${item.deadLineTime ? this.$methods.moment(item.deadLineTime).format("YYYY-MM-DD HH:mm:ss") : ''}\n\n`
          }
        })
      }
      if (!combineLink) return this.SweetAlert('other', `目前商品尚未有發布到${this.filterFbLink.fbType === 'fbGroup' ? '社團' : '粉專'}過`)
      if (this.filterFbLink.showType === 'link') {
        this.filterFbLink.finalString = `快來瞧瞧喔~\n\n` + combineLink
      } else if (this.filterFbLink.showType === 'hot') {
        this.filterFbLink.finalString = '我們挑選幾件熱賣商品來分享給大家喔 ^^\n\n' + combineLink
      } else if (this.filterFbLink.showType === 'deadLine') {
        this.filterFbLink.finalString = '這些商品即將收單嘍^O^ 趕快來搶購吧!\n\n' + combineLink
      }
      console.log(this.filterFbLink.finalString)
    },
    // 貼文連結發布至 FB 社團/粉專
    postLinkToFb() {
      const vm = this;
      const postLinkToFbApi = `${process.env.VUE_APP_API}/apps/fb/publishToCommunity`;
      const header = {
        authorization: this.serverToken
      };
      // 檢查內容是否有填寫
      if (!this.filterFbLink.finalString) return this.SweetAlert('other', '您尚未填寫內容')
      // 檢查是否綁定 FB 社團/粉專
      if (this.storeFb.loading) return this.SweetAlert('other', '請稍後~正在為您檢查是否綁定FB社團或粉專')
      if (this.filterFbLink.fbType === 'fbGroup' && !this.storeFb.group) return this.SweetAlert('other', '您尚未在此賣場綁定FB社團')
      if (this.filterFbLink.fbType === 'forPage' && !this.storeFb.page) return this.SweetAlert('other', '您尚未在此賣場綁定FB粉專')
      const data = [
        {
          groupLinkIds: this.filterFbLink.fbType === 'fbGroup' ? [this.storeFb.group.id] : [],
          pageLinkIds: this.filterFbLink.fbType === 'fbPage' ? [this.storeFb.page.id] : [],
          action: 'feed',
          feed: {
            message: this.filterFbLink.finalString
          }
        }
      ]
      this.$methods.switchLoading('show')
      $.ajax({
        type: "POST",
        async: true,
        url: postLinkToFbApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.createFbPostLinksModal.hide()
            vm.selectItems = []
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 加入 line 推播列表完成後，子層傳遞過來的訊息
    toLinePushSuccess() {
      this.selectItems = [],
      this.toLinePushData.openModal = false
    },
    // 發佈到貨通知
    postTheArrivalToNotify() {
      this.$methods.switchLoading('show')
      let needToPostOneByOne = false
      let groupIds = []
      this.postArrivalToNotify.postTo.forEach(to => {
        if (to === 'oneByOne') needToPostOneByOne = true
        else groupIds.push(to)
      })
      if (!needToPostOneByOne && groupIds.length === 0) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '您尚未選擇發布對象')
      }
      let jsonArray = []
      // 商品陣列
      this.selectItems.forEach(item => {
        // 1 對 1
        if (needToPostOneByOne) {
          let postStyleMap = new Map()
          // 訂單陣列
          item.merchOrders.forEach(order => {
            if (!postStyleMap.has(order.buyerId)) {
              const object = {
                forPersonal: `\n${order.buyer.user.name}您好，您在 ${this.storeInfo.name} 賣場購買<${item.name}>\n\n`,
                totalPrice: 0,
                status: null,
              }
              postStyleMap.set(order.buyerId, object)
            }
            let forPersonal = ''
            let styleString = ''
            order.merchandiseStyles.forEach(style => {
              styleString += `${style.name} `
            })
            // 檢查訂單是否符合條件
            let matchAllocatedStatus = false
            // 先篩選配單狀態
            switch (this.postArrivalToNotify.allocatedStatus) {
              case '未配單':
                if (order.allocatedQuantity === 0) {
                  forPersonal += `${styleString}，單價: NT$${order.price}，數量: ${order.quantity}`
                  matchAllocatedStatus = true
                }
                break;
              case '部分配單':
                if (order.allocatedQuantity > 0 && order.allocatedQuantity < order.quantity) {
                  forPersonal += `${styleString}，單價: NT$${order.price}，數量: ${order.quantity}，到貨: ${order.allocatedQuantity}`
                  matchAllocatedStatus = true
                }
                break;
              case '完全配單':
                if (order.allocatedQuantity === order.quantity) {
                  forPersonal += `${styleString}，單價: NT$${order.price}，數量: ${order.quantity}，到貨: ${order.allocatedQuantity}`
                  matchAllocatedStatus = true
                }
                break;
            }
            // 在篩選結單狀態
            let matchCheckoutStatus = false
            switch (this.postArrivalToNotify.checkoutStatus) {
              case '未結單':
                if (order.checkOutQuantity === 0) {
                  forPersonal += ''
                  matchCheckoutStatus = true
                }
                break;
              case '部分結單':
                if (order.checkOutQuantity > 0 && order.checkOutQuantity < order.quantity) {
                  forPersonal += `，結單: ${order.checkOutQuantity}`
                  matchCheckoutStatus = true
                }
                break;
              case '完全結單':
                if (order.checkOutQuantity === order.quantity) {
                  forPersonal += `，結單: ${order.checkOutQuantity}`
                  matchCheckoutStatus = true
                }
                break;
            }
            console.log(matchAllocatedStatus, matchCheckoutStatus)
            if (matchAllocatedStatus && matchCheckoutStatus) {
              postStyleMap.get(order.buyerId).totalPrice += order.quantity * order.price
              postStyleMap.get(order.buyerId).forPersonal += `${forPersonal}\n`
              postStyleMap.get(order.buyerId).status = `${this.postArrivalToNotify.allocatedStatus} 且 ${this.postArrivalToNotify.checkoutStatus}`
            }
          })
          postStyleMap.forEach(result => {
            result.forPersonal += `\n最終結單金額: NT$${result.totalPrice}元(此金額為參考用)\n目前狀態: ${result.status}\n${this.postArrivalToNotify.sellerMessage ? '賣家的話:\n' + this.postArrivalToNotify.sellerMessage : ''}`
          })
          console.log(postStyleMap)
          // 組 API 資料
          if (postStyleMap.size > 0) {
            console.log(postStyleMap)
            for (const [key, value] of postStyleMap) {
              // 檢查訊息是否超過800字，若超過就分開發
              if (value.status) {
                console.log(value)
                const splitResults = this.splitGraph(value.forPersonal, 800)
                splitResults.forEach(paragraph => {
                  jsonArray.push({
                    type: 13,
                    id: key,
                    message: paragraph,
                  })
                })
              }
            }
          }
        }
        // 群組
        if (groupIds.length > 0) {
          let message = `親愛的顧客您好，若有在 ${this.storeInfo.name} 購買<${item.name}>的顧客\n`
          message += `目前狀態為: ${this.postArrivalToNotify.allocatedStatus} 且 ${this.postArrivalToNotify.checkoutStatus}\n`
          if (this.postArrivalToNotify.sellerMessage) message += `賣家的話:\n${this.postArrivalToNotify.sellerMessage}`
          // 組 API 資料
          groupIds.forEach(groupId => {
            // 檢查訊息是否超過800字，若超過就分開發
            const splitResults = this.splitGraph(message, 800)
            splitResults.forEach(paragraph => {
              jsonArray.push({
                type: 41,
                id: groupId,
                message: paragraph,
              })
            })
          })
        }
      })
      console.log(jsonArray)
      const vm = this;
      const postToNotifyApi = `${process.env.VUE_APP_API}/apps/line/notify`;
      const header = {
        authorization: this.serverToken
      };
      const data = new FormData()
      data.append('jsonString', JSON.stringify(jsonArray))
      $.ajax({
        type: "POST",
        async: true,
        url: postToNotifyApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.postTheArrivalToNotifyModal.hide()
            vm.selectItems = []
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 同步 FB 社團/粉專留言
    syncFbMessages() {
      if (this.syncStype === 'syncOwnSelect' && this.selectItems.length === 0) return this.SweetAlert('other', '您尚未選擇任何商品')
      let merchandiseIds = []
      if (this.syncStype === 'syncOwnSelect') {
        this.selectItems.forEach(item => {
          merchandiseIds.push(item.id)
        })
      }
      // merchandiseIds 若為空則會同步全部
      const data = [{
        storeId: this.storeId,
        communityType: this.postToFbForWhere === 'group' ? 35 : 36,
        merchandiseIds: merchandiseIds,
        check: true
      }]
      this.$store.dispatch('SyncFbMessages/syncFbMessages', {
        serverToken: this.serverToken,
        data: data,
        currentPage: 'merchandise',
        checkIndex: 0,
      })
      this.syncFbMessagesModal.hide()
    },
    // 來自<發布至 line notify>子層的通知
    notifyParentFromPostToLineNotify(object) {
      if (object.clearSelectItems) this.selectItems = []
      this.showLineNotifyModal = object.showLineNotifyModal
    },
    // 來自<line 推播列表>子層的通知
    notifyParentFromLinePush(object) {
      if (object.clearSelectItems) this.selectItems = []
      this.showLinePushModal = object.showLinePushModal
    },
    // 來自<代客加單>子層的通知
    notifyParentFromAddOrderBySeller(object) {
      console.log(object)
      this.showAddOrderBySeller = object.showAddOrderBySeller
      if (object.clearSelectItems) this.selectItems = []
      if (object.reloadDataTable) this.updateData('merchOrdersAndStocks')
    },
    // 來自<批次結單>子層的通知
    notifyParentFromBatchCheckOut(object) {
      this.showBatchCheckOutModal = object.showBatchCheckOutModal
      if (object.clearSelectItems) this.selectItems = []
      if (object.reloadDataTable) this.updateData('merchOrdersAndStocks')
    },
    // 開新分頁
    openNewWindow (url) {
      window.open(url, "_blank");
    },
    // 檢查批次設定商品細節，上架時收單時間是否到期
    checkReady() {
      const now = new Date() * 1
      this.errorData = []
      if (this.setAttributeData.checkSetReady && this.setAttributeData.setReady) {
        this.selectItems.forEach(item => {
          if (item.deadLineTime) {
            // 轉換時間格式 (兼容 IOS)
            const deadLineTime = this.$methods.convertTimeZone(item.deadLineTime)
            console.log(deadLineTime)
            if (deadLineTime <= now) this.errorData.push(item)
          }
        })
      }
    },
    // * line 直播
    // 全選 
    allCheck() {
      if (this.livePushData.groupLinkIds.length !== this.lineBotData.data.length) this.toLinePushLineGroupAllCheck = false
      else this.toLinePushLineGroupAllCheck = true
      if (this.toLinePushLineGroupAllCheck) {
        this.toLinePushLineGroupAllCheck = false
        this.livePushData.groupLinkIds = []
      } else {
        this.toLinePushLineGroupAllCheck = true
        const groupLinkIds = []
        this.lineBotData.data.forEach((item) => groupLinkIds.push(item.linkId))
        this.livePushData.groupLinkIds = groupLinkIds
      }
    },
    // 平整化款式
    sortoutBatchStyles() {
      this.livePushData.queues = []
      // 選到的商品跑回圈
      this.selectItems.forEach(item => {
        let queues = {
          merchandiseName: item.name,
          merchandiseId: item.id,
          styleKeywords: [],
        }
        // 將各個商品的款式平整化
        const twoD = convertStyleTo2D(item.merchandiseStyles)
        const finalStyles = serialArray(twoD)
        console.log(finalStyles)
        // 平整化完的款式跑回圈
        finalStyles.forEach(styles => {
          let object = {
            styleIds: [],
            keyword: '',
            styles: ''
          }
          let keywordArray = []
          // 檢查有沒有其中的款式被刪除
          const dontPush = styles.some(style => {
            if (style.deleted) return true
            else {
              object.styleIds.push(style.id)
              keywordArray.push(style.name)
            }
          })
          // 若沒有款式被刪除就加到陣列內
          if (!dontPush) {
            object.keyword = keywordArray.toString()
            object.styles = keywordArray.toString()
            queues.styleKeywords.push(object)
          }
        })
        this.livePushData.queues.push(queues)
      })
      console.log(this.livePushData)
    },
    // 加入商品進直播列表
    addMerchToLiveQueue() {
      const vm = this;
      const addMerchToLiveQueueApi = `${process.env.VUE_APP_API}/apps/line/addMerchandiseToLineLiveQueue`;
      const header = {
        authorization: this.serverToken
      };
      if (this.livePushData.groupLinkIds.length === 0) return this.SweetAlert('other', '您尚未選擇發送的群組')
      this.$methods.switchLoading('show')
      const data = {
        jump: this.livePushData.jump,
        groupLinkIds: this.livePushData.groupLinkIds,
        queues: []
      }
      this.livePushData.queues.forEach(item => {
        const object = {
          merchandiseId: item.merchandiseId,
          styleKeywords: []
        }
        item.styleKeywords.forEach(keyword => {
          object.styleKeywords.push({
            styleIds: keyword.styleIds,
            keyword: keyword.keyword
          })
        })
        data.queues.push(object)
      })
      $.ajax({
        type: "POST",
        async: true,
        url: addMerchToLiveQueueApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.toLivePushModal.hide()
            vm.selectItems = []
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 前往批次處理 FB 未處理留言
    openBatchHandleFbMessages() {
      let url = ''
      let array = []
      this.unhandleFbMessages.forEach(item => { array.push(item.id) })
      url = `/seller/store/${this.storeId}/batchHandleFbMessages?ids=${array.toString()}`
      this.batchHandleFbMessagesModal.hide()
      window.open(url, '_blank')
    },
  },
}
</script>